import CloseIcon from '@mui/icons-material/Close';
import { CssBaseline } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';

import 'moment/locale/hu';
import 'moment/locale/ro';

import useTitle from 'react-use/lib/useTitle';
import { ProvideAuth } from 'src/auth/useAuth';
import RootRoutes from 'src/RootRoutes';
import { LanguageKeys } from './common/constants/languages';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007849',
    },
    secondary: {
      main: '#05668D',
    },
    error: {
      main: '#C84630',
    },
    warning: {
      main: '#E09F3E',
    },
    info: {
      main: '#B9E6FF',
    },
    success: {
      main: '#74C69D',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    h2: {
      fontSize: '2.5rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    h3: {
      fontSize: '2.2rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    h4: {
      fontSize: '2rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    h5: {
      fontSize: '1.2rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    h6: {
      fontSize: '1.1rem',
      textShadow: '1px 1px rgba(255, 255, 255, .5)',
      fontFamily: ['Londrina Solid', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    button: {
      fontSize: '0.7rem',
    },
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontFamily: ['Barlow', 'sans-serif'].join(','),
  },
});

function App() {
  const {
    t,
    i18n: { language, resolvedLanguage },
  } = useTranslation();
  useTitle(t('base.pageTitle'));
  document.documentElement.lang = language;
  const currentLang = resolvedLanguage as LanguageKeys;

  moment.locale(resolvedLanguage);

  return (
    <ThemeProvider theme={theme}>
      <ProvideAuth>
        <ConfirmProvider
          defaultOptions={{
            title: t('confirmProvider.title'),
            confirmationText: t('confirmProvider.confirmationText'),
            cancellationText: t('confirmProvider.cancellationText'),
            confirmationButtonProps: {
              autoFocus: true,
              color: 'success',
              variant: 'contained',
            },
            cancellationButtonProps: {
              color: 'error',
              variant: 'outlined',
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              action={snackbarId => (
                <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
                  <CloseIcon />
                </IconButton>
              )}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <RootRoutes />
            </SnackbarProvider>
          </LocalizationProvider>
        </ConfirmProvider>
      </ProvideAuth>
    </ThemeProvider>
  );
}

export default App;
