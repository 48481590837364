import { ReactNode, useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { disableAutofillBg } from './constants/disableAutofillBg';

interface Props {
  AddonIcon?: ReactNode;
  clearTooltip?: string;
  label?: string;
  onChange: (value: string) => void;
  openWidth?: number;
  placeholder?: string;
  query: string;
  width?: number;
}

const Searcher = (props: Props) => {
  const { t } = useTranslation();
  const {
    AddonIcon,
    clearTooltip = t('searcher.clearTooltip'),
    label = t('searcher.search'),
    onChange,
    openWidth = "100%",
    placeholder = t('searcher.namePhoneEmail'),
    query = '',
    width = 80,
  } = props;
  const [q, sq] = useState(query);
  const [isSearchFocused, setSearchFocus] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onChange(q);
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [onChange, q, query]);

  return (
    <TextField
      margin="none"
      size="small"
      label={label}
      onChange={e => sq(e.target.value)}
      placeholder={isSearchFocused ? placeholder : undefined}
      type="text"
      fullWidth
      onFocus={() => setSearchFocus(true)}
      onBlur={() => setSearchFocus(false)}
      value={q}
      autoComplete='new-search'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" disablePointerEvents>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {!!q && (
              <Tooltip title={clearTooltip}>
                <IconButton aria-label="clear field" onClick={() => sq('')}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
            {!!AddonIcon ? AddonIcon : null}
          </InputAdornment>
        ),
        sx: {
          borderRadius: 20,
        },
      }}
      sx={{
        ...disableAutofillBg,
        borderRadius: 20,
        width,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        ...((isSearchFocused || !!q) && {
          width: openWidth,
          transition: theme =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }),
      }}
    />
  );
};

export default Searcher;
