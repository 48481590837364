import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ClockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M16.5,6.7c-0.2-0.1-0.9-0.2-1.4,0.5c-0.2,0.2-0.1,0.5,0.1,0.7c0.2,0.1,0.5,0.1,0.7-0.1
		c0.2-0.2,0.2-0.2,0.3-0.1C16.8,8,17.1,9.1,17,9.3c-0.3,0.1-0.4,0.3-0.4,0.6c0,0.2,0.2,0.4,0.5,0.4c0,0,0.1,0,0.1,0
		c0.4-0.1,0.7-0.5,0.7-1C18,8.4,17.4,7,16.5,6.7z"
        fill="currentColor"
      />
      <path
        d="M10.8,6.5c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5-0.3,0.4-0.5
		c0-0.3-0.3-0.5-0.5-0.4c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0.2-0.5,0.5S10.6,6.5,10.8,6.5z"
      />
      <path
        d="M12.7,10.4c0.2-0.1,0.3-0.4,0.2-0.6l-0.1-0.2c-0.2-0.4-0.3-0.6-0.5-0.8c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.4,0.2-0.4,0.3c-0.1,0.2-0.2,0.4-0.3,0.7L11,10c-0.1,0.2,0,0.5,0.3,0.6c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0.9,0,1.5,0,1.9
		c0,0.6,0,1,0.3,1.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.2,0.6-0.4c0.2-0.2,0.5-0.5,0.9-0.8c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c0.2,0,0.4-0.1,0.5-0.4l0-0.1c0.1-0.5,0.2-0.8-0.1-1c-0.3-0.2-0.6-0.1-1,0.1c-0.2,0.1-0.3,0.3-0.3,0.6c-0.2,0.1-0.4,0.3-0.5,0.4
		c0-0.4,0-1,0-1.7C12.6,10.5,12.6,10.5,12.7,10.4z"
        fill="currentColor"
      />
      <path
        d="M17.4,12.5c-0.1-2.2-1.6-4.4-3.6-5.4C12.2,6.4,10.5,6.6,9,7.6C9.2,7.4,9.2,7.2,9.1,7C8.7,6.4,8.2,6.4,8,6.4
		c-0.8,0.1-1.6,1.1-1.9,2C5.9,9.2,6,9.7,6.3,10.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.3-0.2c0.2-0.2,0.2-0.5,0-0.7
		c0,0-0.1-0.3,0.1-0.7c0.2-0.7,0.8-1.3,1-1.3c0,0,0.1,0,0.2,0.1c0.1,0.2,0.4,0.3,0.6,0.2c0,0,0,0,0,0C8.7,7.9,8.6,8.2,8.8,8.4
		c0.2,0.2,0.5,0.3,0.7,0.1c1.2-0.9,2.6-1,3.9-0.4c1.7,0.8,3,2.7,3.1,4.6c0.1,1.1-0.3,2.6-2.1,3.8c-2,1.2-3.7,1.2-5,0
		c-1.4-1.4-2-4.5-0.5-7.1C9,9,8.9,8.7,8.7,8.5C8.5,8.4,8.2,8.5,8,8.7c-1.4,2.5-1.4,5.5,0,7.5c0,0,0,0,0,0c-0.4,0.3-0.7,0.8-0.9,1.3
		L7,17.8c-0.1,0.2,0,0.5,0.2,0.7c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.2C7.8,18.2,7.9,18.1,8,18c0.2-0.4,0.4-0.8,0.7-1v0
		c0,0,0,0,0.1,0.1c0.8,0.8,1.8,1.2,2.9,1.2c1,0,2.1-0.3,3.2-1c0.1-0.1,0.2-0.1,0.3-0.2c0.3,0.3,0.5,0.7,0.7,1.2
		c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.4,0.3-0.6c-0.2-0.6-0.5-1.1-0.8-1.5C17,15.4,17.5,14,17.4,12.5z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default ClockIcon;
