import axios, { InternalAxiosRequestConfig } from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const SECURE_API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers,
});

let isAlreadyFetchingAccessToken = false;

let subscribers: ((at: string) => void)[] = [];

const resetTokenAndReattemptRequest = async (error: any) => {
  try {
    const { response: errorResponse } = error;
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber((accessToken: string) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + accessToken;
        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      await SECURE_API.post('/users/token', {
        'refresh-token': localStorage.getItem('refreshToken'),
      })
        .then(response => {
          localStorage.setItem('accessToken', response.data.accessToken);
        })
        .catch(err => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          return Promise.reject(err);
        });
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(localStorage.getItem('accessToken'));
    }
    return retryOriginalRequest;
  } catch (error) {
    console.log({ error });
    return Promise.reject(error);
  }
};

function onAccessTokenFetched(at: string | null) {
  at && subscribers.forEach(callback => callback(at));
  subscribers = [];
}

function addSubscriber(callback: (at: string) => void) {
  subscribers.push(callback);
}

const requestInterceptor = (
  config: InternalAxiosRequestConfig<any>,
): InternalAxiosRequestConfig<any> => {
  const accessToken = localStorage.getItem('accessToken');

  if (config.headers) {
    if (process.env.REACT_APP_API_KEY) {
      config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    }

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return config;
};

const responseErrorInterceptor = (error: any) => {
  if (error.response.status === 400 && localStorage.getItem('accessToken')) {
    return resetTokenAndReattemptRequest(error);
  } else if (error.response.status === 401) {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } else {
    console.error(error);
  }
  return Promise.reject(error);
};

SECURE_API.interceptors.request.use(requestInterceptor);
SECURE_API.interceptors.response.use(response => response, responseErrorInterceptor);
