import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import Grid from '@mui/material/Grid';
import PageBox from 'src/common/components/PageBox';
import AppBar from 'src/common/components/styled/AppBar';
import { LanguageKeys } from 'src/common/constants/languages';
import { ICompetition as CompetitionType } from 'src/interfaces/Competition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import StatisticsComponent from './Parts/StatisticsComponent';
import { useParams } from 'react-router-dom';
import { CompetitionRouteParams } from '../Competitions/CompetitionResolver';

export interface Props {
  competition: CompetitionType;
  distances: string[];
}

const Statistics = ({ competition, distances }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { year } = useParams<keyof CompetitionRouteParams>();

  useTitle(`${t('statistics.pageTitle')} - ${t('base.defaultTitle')}`);

  const { activeEditionId, distancesById, editionsById, editionsByYear } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  if (!currentEdition || !year || !distancesById || !editionsByYear) return null;

  const selectedEdition = editionsByYear[year];

  return (
    <PageBox>
      {distances.map(dist => {
        const distance = distancesById[dist];
        const statistic =
          selectedEdition.statistics && selectedEdition.statistics[`${competition.id}:${dist}`];

        return (
          <Fragment key={dist}>
            <AppBar position="static" color="transparent">
              <Toolbar
                component={Paper}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  backdropFilter: 'blur(3px)',
                  mb: 2,
                  mt: 2,
                  p: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                    {t('statistics.distanceTitle', {
                      competition: competition.i18n[currentLang],
                      distance: distance.i18n[currentLang],
                    })}
                  </Typography>
                </Box>
              </Toolbar>
            </AppBar>
            <Paper
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                backdropFilter: 'blur(3px)',
                width: '100%',
                mb: 2,
                mt: 2,
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                {!!statistic ? (
                  <StatisticsComponent statistic={statistic} />
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" component="p" my={2}>
                      {t('statistics.notEnoughData')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Fragment>
        );
      })}
    </PageBox>
  );
};

export default Statistics;
