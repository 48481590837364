import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WinnerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M15.8,7.9c-0.2-0.6-0.8-1.1-1.5-1.2c-1.1-0.2-2.1,0.5-2.5,1.5c0,0.1,0,0.3,0,0.4c0,1.5,0.6,2,1,2.2
		c0.2,0.1,0.5,0.1,0.7,0.1c0.6,0,1.2-0.3,1.7-0.8C15.8,9.6,16.1,8.7,15.8,7.9z M14.6,9.5c-0.4,0.4-0.9,0.6-1.3,0.4
		c-0.4-0.2-0.5-0.8-0.4-1.6c0,0,0,0,0,0c0.3-0.4,0.7-0.7,1.3-0.6c0.4,0.1,0.6,0.2,0.7,0.6C15,8.6,14.9,9.2,14.6,9.5z"
        fill="currentColor"
      />
      <path
        d="M12.2,10.9l0.1-0.1c-1.3-1-2.6-2-2.8-3.6c0,0,0,0,0,0c0.4-0.1,0.7-0.3,1-0.5c0,0,0.1,0,0.1,0c0.8,0,2-0.5,2.3-1.3
		C13,5.1,13,4.7,12.4,4.2c0.1-0.2,0-0.4-0.2-0.5c-0.1,0-0.2-0.1-0.3,0c-0.5-0.5-1.2-0.9-1.9-1.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1,0-0.2,0C8.8,2.1,8.4,2.3,8.1,2.5c-0.9,0.7,0,2.9,0,2.9c0,0,0,0,0,0c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0.1,0.4,0.3,0.5
		C8.2,6.8,8.1,7,8,7.2C8,7.1,7.9,7.1,7.8,7.1C7.6,7,7.3,7.1,7.2,7.4C7.1,7.6,7.3,7.9,7.5,8C7.7,8.1,7.9,8.1,8,8.2
		c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.6,1.3,1.8,2.3,2.9,3.1c0.3,2.7,0.2,5.4,0.2,8c0,1.2-0.1,2.3-0.1,3.5
		c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-1.2,0-2.3,0.1-3.5c0.1-2.7,0.1-5.5-0.2-8.3C12.7,11.1,12.5,10.9,12.2,10.9z M11.8,5.3
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0C12,5.2,11.9,5.3,11.8,5.3z M11.3,4.3c-0.6,1-1.3,1.7-2.1,2C9.1,6.3,9,6.2,9,6.2c0,0,0,0,0,0
		c0.1-0.9,0.4-1.8,0.9-2.7C10.4,3.6,10.9,3.9,11.3,4.3z M8.7,3.2c0,0,0.1,0,0.2,0.1C8.8,3.4,8.8,3.6,8.7,3.7
		C8.7,3.5,8.7,3.3,8.7,3.2z"
        fill="currentColor"
      />
      <path
        d="M15.6,11.2C15.6,11.2,15.5,11.2,15.6,11.2c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1
		c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.6,3.7-0.6,7.8-0.1,11.9c0,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0.1,0c0.3,0,0.4-0.3,0.4-0.5
		c-0.4-3.2-0.5-6.5-0.2-9.5c0.3,1.2,0.5,2.4,0.5,3.8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5C16.8,15.1,16.4,13,15.6,11.2z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        opacity="0.5"
        d="M12.2,2.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.5,0.2-0.7c0,0,0.1-0.1,0.2-0.1c0.3-0.2,0.3-0.2,0.3-0.3
	c0-0.3,0.1-0.5,0.4-0.5c0.3,0,0.5,0.1,0.5,0.4c0.1,0.8-0.6,1.2-0.8,1.3l-0.1,0.1C12.4,2.2,12.3,2.2,12.2,2.2z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M13.4,1.9c-0.1,0-0.1,0-0.2,0c-0.6-0.1-0.8-0.5-0.8-1.1c0-0.3,0.3-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
	c0,0.1,0,0.2,0,0.2c0.1,0,0.1,0,0.2,0c0.2,0.1,0.4,0.4,0.2,0.6C13.8,1.8,13.6,1.9,13.4,1.9z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M13,2.8c-0.2,0-0.4-0.2-0.5-0.4l0-0.2c0,0,0-0.1,0-0.1C12.2,2.1,12,2,12,1.8c-0.1-0.3,0.1-0.5,0.3-0.6
	c0.1,0,0.5-0.1,0.8,0.1c0.3,0.2,0.3,0.5,0.4,0.8l0,0.2C13.5,2.5,13.3,2.8,13,2.8C13,2.8,13,2.8,13,2.8z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M13,2.8c0,0-0.1,0-0.1,0c-0.3-0.1-0.4-0.3-0.4-0.6l0-0.1c0-0.3,0.1-0.7,0.5-0.9C13.2,1,13.5,1,13.7,1.3
	c0.2,0.2,0.1,0.5-0.1,0.7c0,0-0.1,0.2-0.1,0.3l0,0.2C13.4,2.6,13.2,2.8,13,2.8z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default WinnerIcon;
