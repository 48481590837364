import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TTTIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 256 256">
    <g opacity="0.5">
      <path
        d="M170.2,158.6L170.2,158.6c-3.8,0.2-7.7,0.6-11.7,1.3l0,0l0,0c-4,0.7-7.9,1.3-11.7,1.9l0,0l0,0c0,0,0,0.1,0,0.1
		l0,0l0,0c0,0,0,0,0,0.1l0,0l0,0c-0.1,0.9-0.1,1.8-0.1,2.8l0,0l0,0c0,1,0,1.9,0,2.7l0,0l0,0c0,0.8,0.1,1.5,0.1,2.1l0,0l0,0
		c0.1,0.6,0.1,0.9,0.2,1.1l0,0l0,0c0.2,0,0.6,0,1.2-0.1l0,0l0,0c0.6-0.1,1.2-0.2,1.8-0.3l0,0l0,0c0.7-0.1,1.3-0.2,2-0.2l0,0l0,0
		c0.7-0.1,1.2,0,1.7,0.2l0,0l0.1,0l0,0c0.2,5.1,0.4,9.5,0.4,13.2l0,0l0,0c0.1,3.7,0.1,6.7,0.1,9l0,0l0,0c0,2.4,0,4.1-0.1,5.3l0,0
		l0,0c0,1.2,0,1.9,0,2l0,0l0,0c0.7-0.2,1.4-0.4,2.2-0.5l0,0l0,0c0.8-0.1,1.6-0.2,2.4-0.4l0,0l0,0c0.8-0.1,1.6-0.3,2.4-0.4l0,0l0,0
		c0.8-0.1,1.5-0.3,2.2-0.5l0,0v-0.1v-0.1l0,0c-0.1-4-0.1-7.5-0.2-10.5l0,0l0,0c0-3-0.1-5.5-0.1-7.6l0,0l0,0c0-2.1-0.1-3.9-0.1-5.2
		l0,0l0,0c0-1.3,0-2.4,0-3.3l0,0l0,0c0-0.8,0-1.4,0-1.8l0,0l0,0c0-0.4,0-0.7,0.1-0.9l0,0l0,0c0,0,0-0.1,0-0.1l0,0l0,0
		c0,0,0-0.1,0-0.1l0,0l0.1,0l0.1,0l0,0c1.7-0.3,3.1-0.6,4.2-0.8l0,0l0,0c1-0.2,1.9-0.3,2.6-0.2l0,0l0,0c0.2-1.4,0.3-2.8,0.3-4.4l0,0
		l0,0C170.3,161.2,170.3,159.8,170.2,158.6L170.2,158.6L170.2,158.6z M180.4,169.7L180.4,169.7c0-0.3,0-0.4,0-0.5l0,0l0,0
		c0-0.1,0-0.2,0-0.3l0,0l0,0c0-0.1,0-0.2,0-0.4l0,0l0,0c0-0.1,0-0.4,0-0.7l0,0l0,0c0-0.1-0.2-0.1-0.5-0.1l0,0l0,0
		c-0.3,0-0.7,0.1-1.1,0.2l0,0l0,0c-0.4,0.1-0.8,0.2-1.2,0.3l0,0l0,0c-0.4,0.1-0.7,0.2-0.8,0.2l0,0l0,0c-0.4,0.1-0.8,0.2-1.1,0.3l0,0
		l0,0c-0.3,0.1-0.6,0.1-0.8,0.2l0,0l0,0c-0.3,0-0.5,0.1-0.8,0.1l0,0l0,0c-0.3,0-0.7,0.1-1.1,0.1l0,0l0,0c-0.1,4.9-0.2,9.6-0.1,14.2
		l0,0l0,0c0.1,4.5,0.1,9.1,0.1,13.6l0,0l0,0c0.9-0.2,1.7-0.4,2.3-0.4l0,0l0,0c0.6-0.1,1.2-0.1,1.8-0.2l0,0l0,0
		c0.6-0.1,1.1-0.2,1.6-0.3l0,0l0,0c0.5-0.1,1.1-0.3,1.7-0.6l0,0v-0.2l0,0c0-1.4,0-2.7,0-3.8l0,0l0,0c0-1.2,0-2.4,0-3.5l0,0l0,0
		c0-1.2,0-2.3-0.1-3.5l0,0l0,0c0-1.1-0.1-2.4-0.1-3.6l0,0l0,0c0-1,0.1-1.8,0.4-2.5l0,0l0,0c0.3-0.6,0.8-1.1,1.4-1.4l0,0l0,0
		c0.6-0.4,1.3-0.6,2.2-0.7l0,0l0,0c0.8-0.1,1.7-0.2,2.7-0.3l0,0l0,0c0,0,0.3,0,0.7,0l0,0l0,0c0.5,0,0.9,0,1.4,0.1l0,0l0,0
		c0.2,0,0.4,0.1,0.6,0.1l0,0l0,0c0.2,0,0.4,0.1,0.6,0.1l0,0l0.5,0.1l0,0c0-0.5-0.1-1.1-0.1-1.8l0,0l0,0c0-0.6,0-1.3,0-2.1l0,0l0,0
		c0-0.8,0-1.6,0-2.6l0,0l0,0c0-1,0-2.1,0-3.3l0,0l0,0c0,0-0.3,0-0.8,0l0,0l0,0c-0.5,0.1-1.2,0.2-2,0.3l0,0l0,0
		c-0.8,0.2-1.6,0.4-2.5,0.6l0,0l0,0c-0.9,0.3-1.7,0.6-2.4,0.9l0,0l0,0c-0.5,0.3-1,0.6-1.6,0.9l0,0l0,0
		C180.9,169.4,180.6,169.6,180.4,169.7L180.4,169.7L180.4,169.7z M215.6,162.3L215.6,162.3c-1.2,0.1-2.2,0.2-3.1,0.3l0,0l0,0
		c-0.9,0.1-1.7,0.2-2.3,0.3l0,0l0,0c-0.1,0-0.3,0-0.6,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.9,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.8,0.1l0,0l0,0
		c-0.2,0.1-0.4,0.1-0.4,0.1l0,0l0,0c0,0.4,0,0.9-0.1,1.3l0,0l0,0c-0.1,0.4-0.1,1-0.1,1.5l0,0l0,0c-0.3-0.6-0.6-1-0.9-1.3l0,0l0,0
		c-0.4-0.3-0.8-0.5-1.3-0.6l0,0l0,0c-0.5-0.1-1-0.1-1.6,0l0,0l0,0c-0.6,0.1-1.2,0.2-1.9,0.3l0,0l0,0c-1.3,0.2-2.5,0.8-3.7,1.6l0,0
		l0,0c-1.1,0.8-2.1,1.9-3,3.1l0,0l0,0c-0.8,1.3-1.5,2.7-2,4.3l0,0l0,0c-0.5,1.6-0.7,3.4-0.7,5.2l0,0l0,0c0,1.5,0.2,3.1,0.5,4.7l0,0
		l0,0c0.3,1.7,0.8,3.2,1.5,4.6l0,0l0,0c0.7,1.4,1.5,2.5,2.5,3.3l0,0l0,0c1,0.8,2.2,1.1,3.7,0.9l0,0l0,0c1.3-0.2,2.6-0.7,4-1.6l0,0
		l0,0c1.3-0.8,2.5-1.8,3.4-2.8l0,0l0,0c0,0.4,0,0.7-0.1,1l0,0l0,0c-0.1,0.3-0.1,0.6-0.1,1l0,0l0,0c0,0.3,0,0.5,0.1,0.8l0,0l0,0
		c0.1,0.2,0.3,0.3,0.5,0.4l0,0l0,0c0.4-0.1,0.8-0.2,1.2-0.3l0,0l0,0c0.4-0.1,0.8-0.2,1.2-0.3l0,0l0,0c0.6-0.1,1.2-0.2,1.8-0.2l0,0
		l0,0c0.6,0,1.2-0.1,1.8-0.2l0,0l0.7-0.1l0,0c0.4-0.1,0.7-0.5,0.7-0.9l0,0l0,0c0-0.5,0-1,0-1.4l0,0l0,0c0.3-4,0.3-7.9,0.2-11.8l0,0
		l0,0c-0.1-3.8-0.2-7.8-0.1-11.8l0,0v-0.6l0,0c0-0.2,0-0.4,0-0.7l0,0l0,0C215.6,162.8,215.6,162.5,215.6,162.3L215.6,162.3
		L215.6,162.3z M199.4,175.9L199.4,175.9c0.2-1,0.7-1.9,1.6-2.7l0,0l0,0c0.9-0.9,2.1-1.4,3.6-1.6l0,0l0,0c0.8-0.1,1.4-0.2,1.7-0.1
		l0,0l0,0c0.4,0,0.7,0.3,0.8,0.6l0,0l0,0c0.2,0.4,0.3,0.9,0.3,1.7l0,0l0,0c0,0.8,0,1.8,0,3.1l0,0l0,0c0,1,0,2,0,2.8l0,0l0,0
		c0,0.8-0.1,1.6-0.3,2.2l0,0l0,0c-0.2,0.6-0.5,1.1-1,1.5l0,0l0,0c-0.5,0.4-1.2,0.7-2.1,0.8l0,0l0,0c-1.6,0.2-2.8-0.1-3.6-1.2l0,0
		l0,0c-0.9-1-1.3-2.4-1.3-4l0,0l0,0c0-0.5,0-1,0.1-1.5l0,0l0,0C199.2,176.9,199.3,176.4,199.4,175.9L199.4,175.9L199.4,175.9z
		 M226.4,160.3L226.4,160.3c-1.2,0.2-2.2,0.4-3,0.5l0,0l0,0c-0.9,0.1-1.5,0.2-2,0.2l0,0l0,0c-0.2,0-0.5,0.1-0.8,0.1l0,0l0,0
		c-0.4,0-0.6,0.1-0.7,0.1l0,0v0.1l0,0c-0.2,2.2-0.3,4.5-0.3,6.8l0,0l0,0c0,2.3,0,4.6,0.1,7l0,0l0,0c0.1,2.4,0.1,4.7,0.1,7.1l0,0l0,0
		c0,2.4-0.1,4.8-0.2,7.1l0,0l0,0c0.3-0.1,0.8-0.2,1.6-0.3l0,0l0,0c0.8-0.1,1.6-0.2,2.5-0.3l0,0l0,0c0.9-0.1,1.7-0.2,2.4-0.4l0,0l0,0
		c0.7-0.1,1.2-0.3,1.4-0.6l0,0l0,0c-0.3-4.4-0.3-9-0.2-13.7l0,0l0,0c0.1-4.7,0.2-9.3,0.2-13.7l0,0v-0.2L226.4,160.3L226.4,160.3z
		 M219.6,149.3L219.6,149.3c0,1.7,0,3.1,0.1,4.3l0,0l0,0c0.1,1.1,0.1,2.3,0.1,3.3l0,0l0,0c0.7-0.1,1.5-0.3,2.3-0.3l0,0l0,0
		c0.8-0.1,1.6-0.2,2.3-0.3l0,0l0,0c0.7-0.1,1.3-0.2,1.9-0.4l0,0l0,0c0.5-0.2,0.9-0.4,1-0.6l0,0l0,0c0-0.6,0-1.2,0-1.9l0,0l0,0
		c0-0.7,0-1.4-0.1-2.1l0,0l0,0c0-0.7,0-1.3-0.1-1.9l0,0l0,0c0-0.6,0-1,0-1.2l0,0L219.6,149.3L219.6,149.3z M231.3,147.1L231.3,147.1
		c-0.3,3.4-0.5,6.8-0.5,10.2l0,0l0,0c0,3.4,0.1,6.7,0.2,10.1l0,0l0,0c0.1,3.3,0.2,6.7,0.2,10.1l0,0l0,0c0.1,3.4,0,6.7-0.3,10.1l0,0
		l0,0c0.3-0.1,0.8-0.2,1.6-0.3l0,0l0,0c0.8-0.1,1.6-0.2,2.5-0.3l0,0l0,0c0.9-0.1,1.7-0.2,2.4-0.4l0,0l0,0c0.7-0.1,1.2-0.3,1.4-0.6
		l0,0l0,0c-0.1-1.5-0.1-3.3-0.2-5.4l0,0l0,0c0-2.1-0.1-4.4-0.1-6.8l0,0l0,0c0-2.4,0-5,0-7.6l0,0l0,0c0-2.6,0-5.2,0.1-7.6l0,0l0,0
		c0-2.4,0.1-4.7,0.1-6.9l0,0l0,0c0-2.1,0.1-4,0.1-5.5l0,0L231.3,147.1L231.3,147.1z M38.3,179.5L38.3,179.5
		c-3.8,0.2-7.7,0.6-11.7,1.3l0,0l0,0c-4,0.7-7.9,1.3-11.7,1.9l0,0l0,0c0,0,0,0.1,0,0.1l0,0l0,0c0,0,0,0,0,0.1l0,0l0,0
		c-0.1,0.9-0.1,1.8-0.1,2.8l0,0l0,0c0,1,0,1.9,0,2.7l0,0l0,0c0,0.8,0.1,1.5,0.1,2.1l0,0l0,0c0.1,0.6,0.1,0.9,0.2,1.1l0,0l0,0
		c0.2,0,0.6,0,1.2-0.1l0,0l0,0c0.6-0.1,1.2-0.2,1.8-0.3l0,0l0,0c0.7-0.1,1.3-0.2,2-0.2l0,0l0,0c0.7-0.1,1.2,0,1.7,0.2l0,0l0.1,0l0,0
		c0.2,5.1,0.4,9.5,0.4,13.2l0,0l0,0c0.1,3.7,0.1,6.7,0.1,9l0,0l0,0c0,2.4,0,4.1-0.1,5.3l0,0l0,0c0,1.2,0,1.9,0,2l0,0l0,0
		c0.7-0.2,1.4-0.4,2.2-0.5l0,0l0,0c0.8-0.1,1.6-0.2,2.4-0.4l0,0l0,0c0.8-0.1,1.6-0.3,2.4-0.4l0,0l0,0c0.8-0.1,1.5-0.3,2.2-0.5l0,0
		v-0.1v-0.1l0,0c-0.1-4-0.1-7.5-0.2-10.5l0,0l0,0c0-3-0.1-5.5-0.1-7.6l0,0l0,0c0-2.1-0.1-3.9-0.1-5.2l0,0l0,0c0-1.3,0-2.4,0-3.3l0,0
		l0,0c0-0.8,0-1.4,0-1.8l0,0l0,0c0-0.4,0-0.7,0.1-0.9l0,0l0,0c0,0,0-0.1,0-0.1l0,0l0,0c0,0,0-0.1,0-0.1l0,0l0.1,0l0.1,0l0,0
		c1.7-0.3,3.1-0.6,4.2-0.8l0,0l0,0c1-0.2,1.9-0.3,2.6-0.2l0,0l0,0c0.2-1.4,0.3-2.8,0.3-4.4l0,0l0,0
		C38.4,182.1,38.4,180.7,38.3,179.5L38.3,179.5L38.3,179.5z M47.5,202.6L47.5,202.6c0.4,0,1,0,1.8-0.1l0,0l0,0
		c0.8-0.1,1.7-0.2,2.7-0.4l0,0l0,0c1-0.2,2.1-0.3,3.2-0.6l0,0l0,0c1.1-0.2,2.2-0.4,3.1-0.7l0,0l0,0c1-0.2,1.8-0.4,2.6-0.6l0,0l0,0
		c0.7-0.2,1.2-0.3,1.5-0.5l0,0l0,0c0.1-1.6,0.1-3.1,0-4.6l0,0l0,0c-0.1-1.5-0.4-2.8-1-4l0,0l0,0c-0.5-1.2-1.3-2.1-2.4-2.8l0,0l0,0
		c-1-0.7-2.4-1.1-4.1-1.1l0,0l0,0c-1.3-0.1-2.7-0.1-4.1,0.2l0,0l0,0c-1.4,0.3-2.7,0.7-4,1.3l0,0l0,0c-1.3,0.6-2.4,1.3-3.4,2.3l0,0
		l0,0c-1,0.9-1.7,1.9-2.2,3.1l0,0l0,0c-0.4,1.3-0.7,2.6-0.9,3.9l0,0l0,0c-0.1,1.3-0.2,2.6-0.4,4l0,0l0,0c-0.1,1.5,0,3,0.1,4.5l0,0
		l0,0c0.1,1.5,0.4,2.9,0.8,4.2l0,0l0,0c0.4,1.3,0.9,2.4,1.6,3.5l0,0l0,0c0.7,1,1.5,1.8,2.5,2.3l0,0l0,0c0.9,0.4,1.8,0.6,2.9,0.5l0,0
		l0,0c1-0.2,2.2-0.3,3.4-0.4l0,0l0,0c1.2-0.1,2.4-0.3,3.6-0.6l0,0l0,0c1.2-0.3,2.3-0.7,3.3-1.2l0,0l0,0c1-0.5,1.8-1.3,2.4-2.3l0,0
		l0,0c0.3-0.3,0.5-0.7,0.6-1.3l0,0l0,0c0.2-0.6,0.3-1.3,0.4-2.1l0,0l0,0c0.1-0.8,0.2-1.7,0.3-2.6l0,0l0,0c0.1-0.9,0.1-1.9,0.2-2.9
		l0,0l0,0c-0.4,0-0.9,0-1.6,0.1l0,0l0,0c-0.6,0.1-1.3,0.2-2,0.3l0,0l0,0c-0.7,0.1-1.3,0.3-2,0.4l0,0l0,0c-0.6,0.1-1.1,0.2-1.5,0.2
		l0,0l0,0c0.1,1.5-0.2,2.7-0.7,3.5l0,0l0,0c-0.5,0.8-1.2,1.4-2,1.7l0,0l0,0c-0.8,0.3-1.6,0.3-2.4,0l0,0l0,0
		c-0.8-0.3-1.4-0.8-1.9-1.6l0,0l0,0c-0.4-0.6-0.6-1.2-0.7-1.9l0,0l0,0c-0.1-0.7-0.1-1.6-0.1-2.7l0,0V202.6L47.5,202.6z M54.3,193
		L54.3,193c0.7,0.2,1.2,0.6,1.6,1.2l0,0l0,0c0.4,0.6,0.6,1.3,0.6,2l0,0l0,0c0,0.3,0,0.5,0,0.6l0,0l0,0c0,0.1-0.1,0.2-0.3,0.2l0,0
		l0,0c-0.2,0.1-0.5,0.1-1,0.1l0,0l0,0c-0.5,0-1.2,0.1-2.2,0.3l0,0l0,0c-1.4,0.2-2.5,0.4-3.2,0.5l0,0l0,0c-0.7,0.1-1.2,0.2-1.6,0.2
		l0,0l0,0c-0.3,0-0.5-0.1-0.5-0.2l0,0l0,0c0-0.2,0-0.5,0.1-0.9l0,0l0,0c0.1-0.5,0.2-1,0.4-1.4l0,0l0,0c0.2-0.5,0.4-0.9,0.7-1.3l0,0
		l0,0c0.3-0.4,0.8-0.7,1.3-1l0,0l0,0c0.6-0.3,1.3-0.5,2.2-0.6l0,0l0,0c0.4-0.1,0.8-0.1,1.1,0l0,0l0,0C53.7,192.8,54,192.8,54.3,193
		L54.3,193L54.3,193z M65.7,173.4L65.7,173.4c-0.3,3.4-0.5,6.8-0.5,10.2l0,0l0,0c0,3.4,0.1,6.7,0.2,10.1l0,0l0,0
		c0.1,3.3,0.2,6.7,0.2,10.1l0,0l0,0c0.1,3.4,0,6.7-0.3,10.1l0,0l0,0c0.3-0.1,0.8-0.2,1.6-0.3l0,0l0,0c0.8-0.1,1.6-0.2,2.5-0.3l0,0
		l0,0c0.9-0.1,1.7-0.2,2.4-0.4l0,0l0,0c0.7-0.1,1.2-0.3,1.4-0.6l0,0l0,0c-0.1-1.5-0.1-3.3-0.2-5.4l0,0l0,0c0-2.1-0.1-4.4-0.1-6.8
		l0,0l0,0c0-2.4,0-5,0-7.6l0,0l0,0c0-2.6,0-5.2,0.1-7.6l0,0l0,0c0-2.4,0.1-4.7,0.1-6.9l0,0l0,0c0-2.1,0.1-4,0.1-5.5l0,0L65.7,173.4
		L65.7,173.4z M83.6,196.9L83.6,196.9c0.4,0,1,0,1.8-0.1l0,0l0,0c0.8-0.1,1.7-0.2,2.7-0.4l0,0l0,0c1-0.2,2.1-0.3,3.2-0.6l0,0l0,0
		c1.1-0.2,2.2-0.4,3.1-0.7l0,0l0,0c1-0.2,1.8-0.4,2.6-0.6l0,0l0,0c0.7-0.2,1.2-0.3,1.5-0.5l0,0l0,0c0.1-1.6,0.1-3.1,0-4.6l0,0l0,0
		c-0.1-1.5-0.4-2.8-1-4l0,0l0,0c-0.5-1.2-1.3-2.1-2.4-2.8l0,0l0,0c-1-0.7-2.4-1.1-4.1-1.1l0,0l0,0c-1.3-0.1-2.7-0.1-4.1,0.2l0,0l0,0
		c-1.4,0.3-2.7,0.7-4,1.3l0,0l0,0c-1.3,0.6-2.4,1.3-3.4,2.3l0,0l0,0c-1,0.9-1.7,1.9-2.2,3.1l0,0l0,0c-0.4,1.3-0.7,2.6-0.9,3.9l0,0
		l0,0c-0.1,1.3-0.2,2.6-0.4,4l0,0l0,0c-0.1,1.5,0,3,0.1,4.5l0,0l0,0c0.1,1.5,0.4,2.9,0.8,4.2l0,0l0,0c0.4,1.3,0.9,2.4,1.6,3.5l0,0
		l0,0c0.7,1,1.5,1.8,2.5,2.3l0,0l0,0c0.9,0.4,1.8,0.6,2.9,0.5l0,0l0,0c1-0.2,2.2-0.3,3.4-0.4l0,0l0,0c1.2-0.1,2.4-0.3,3.6-0.6l0,0
		l0,0c1.2-0.3,2.3-0.7,3.3-1.2l0,0l0,0c1-0.5,1.8-1.3,2.4-2.3l0,0l0,0c0.3-0.3,0.5-0.7,0.6-1.3l0,0l0,0c0.2-0.6,0.3-1.3,0.4-2.1l0,0
		l0,0c0.1-0.8,0.2-1.7,0.3-2.6l0,0l0,0c0.1-0.9,0.1-1.9,0.2-2.9l0,0l0,0c-0.4,0-0.9,0-1.6,0.1l0,0l0,0c-0.6,0.1-1.3,0.2-2,0.3l0,0
		l0,0c-0.7,0.1-1.3,0.3-2,0.4l0,0l0,0c-0.6,0.1-1.1,0.2-1.5,0.2l0,0l0,0c0.1,1.5-0.2,2.7-0.7,3.5l0,0l0,0c-0.5,0.8-1.2,1.4-2,1.7
		l0,0l0,0c-0.8,0.3-1.6,0.3-2.4,0l0,0l0,0c-0.8-0.3-1.4-0.8-1.9-1.6l0,0l0,0c-0.4-0.6-0.6-1.2-0.7-1.9l0,0l0,0
		c-0.1-0.7-0.1-1.6-0.1-2.7l0,0V196.9L83.6,196.9z M90.3,187.3L90.3,187.3c0.7,0.2,1.2,0.6,1.6,1.2l0,0l0,0c0.4,0.6,0.6,1.3,0.6,2
		l0,0l0,0c0,0.3,0,0.5,0,0.6l0,0l0,0c0,0.1-0.1,0.2-0.3,0.2l0,0l0,0c-0.2,0.1-0.5,0.1-1,0.1l0,0l0,0c-0.5,0-1.2,0.1-2.2,0.3l0,0l0,0
		c-1.4,0.2-2.5,0.4-3.2,0.5l0,0l0,0c-0.7,0.1-1.2,0.2-1.6,0.2l0,0l0,0c-0.3,0-0.5-0.1-0.5-0.2l0,0l0,0c0-0.2,0-0.5,0.1-0.9l0,0l0,0
		c0.1-0.5,0.2-1,0.4-1.4l0,0l0,0c0.2-0.5,0.4-0.9,0.7-1.3l0,0l0,0c0.3-0.4,0.8-0.7,1.3-1l0,0l0,0c0.6-0.3,1.3-0.5,2.2-0.6l0,0l0,0
		c0.4-0.1,0.8-0.1,1.1,0l0,0l0,0C89.7,187,90,187.1,90.3,187.3L90.3,187.3L90.3,187.3z M109.2,166.5L109.2,166.5
		c-0.5,0-1,0.1-1.7,0.2l0,0l0,0c-0.6,0.1-1.3,0.2-2,0.3l0,0l0,0c-0.7,0.1-1.4,0.3-2.1,0.4l0,0l0,0c-0.7,0.1-1.3,0.2-1.9,0.3l0,0l0,0
		c0,6.7,0,13.4-0.2,20l0,0l0,0c-0.1,6.6-0.1,13.4,0.2,20.2l0,0l0,0c0.6,0,1.2-0.1,1.9-0.2l0,0l0,0c0.7-0.1,1.4-0.2,2-0.4l0,0l0,0
		c0.7-0.1,1.3-0.3,1.9-0.4l0,0l0,0c0.6-0.1,1.1-0.2,1.5-0.2l0,0l0,0c0.1-0.8,0.2-1.7,0.3-2.7l0,0l0,0c0-1,0.1-2,0.1-2.9l0,0l0,0
		c0-1,0-1.9-0.1-2.8l0,0l0,0c0-0.9-0.1-1.7-0.1-2.3l0,0l0,0c0.6,0.7,1.2,1.5,1.7,2.3l0,0l0,0c0.5,0.8,1,1.6,1.4,2.5l0,0l0,0
		c0.4,0.8,0.9,1.7,1.4,2.5l0,0l0,0c0.5,0.9,1,1.7,1.5,2.5l0,0l0,0c0.6,0,1.2,0,2-0.1l0,0l0,0c0.8-0.1,1.6-0.2,2.5-0.4l0,0l0,0
		c0.9-0.2,1.7-0.3,2.5-0.5l0,0l0,0c0.8-0.2,1.5-0.3,2-0.4l0,0l0,0c-1.4-2.6-2.9-5.1-4.6-7.5l0,0l0,0c-1.7-2.4-3.4-4.8-5-7.2l0,0l0,0
		c1.6-2.3,3.1-4.6,4.7-6.9l0,0l0,0c1.6-2.3,3.1-4.6,4.4-6.9l0,0l0,0c-0.5,0-1.2,0.2-2,0.3l0,0l0,0c-0.8,0.2-1.6,0.4-2.5,0.5l0,0l0,0
		c-0.8,0.2-1.6,0.3-2.4,0.4l0,0l0,0c-0.8,0.1-1.4,0.1-1.9,0l0,0l0,0c-0.6,0.7-1,1.5-1.5,2.2l0,0l0,0c-0.4,0.8-0.8,1.5-1.2,2.3l0,0
		l0,0c-0.4,0.7-0.8,1.5-1.3,2.2l0,0l0,0c-0.5,0.7-1,1.4-1.6,2.1l0,0l0,0c-0.1-0.8-0.1-1.9-0.1-3.3l0,0l0,0c0.1-1.4,0.1-2.7,0.1-3.9
		l0,0V166.5L109.2,166.5z M132.4,175.2L132.4,175.2c-1.2,0.2-2.2,0.4-3,0.5l0,0l0,0c-0.9,0.1-1.5,0.2-2,0.2l0,0l0,0
		c-0.2,0-0.5,0.1-0.8,0.1l0,0l0,0c-0.4,0-0.6,0.1-0.7,0.1l0,0v0.1l0,0c-0.2,2.2-0.3,4.5-0.3,6.8l0,0l0,0c0,2.3,0,4.6,0.1,7l0,0l0,0
		c0.1,2.4,0.1,4.7,0.1,7.1l0,0l0,0c0,2.4-0.1,4.8-0.2,7.1l0,0l0,0c0.3-0.1,0.8-0.2,1.6-0.3l0,0l0,0c0.8-0.1,1.6-0.2,2.5-0.3l0,0l0,0
		c0.9-0.1,1.7-0.2,2.4-0.4l0,0l0,0c0.7-0.1,1.2-0.3,1.4-0.6l0,0l0,0c-0.3-4.4-0.3-9-0.2-13.7l0,0l0,0c0.1-4.7,0.2-9.3,0.2-13.7l0,0
		v-0.2L132.4,175.2L132.4,175.2z M125.6,164.2L125.6,164.2c0,1.7,0,3.1,0.1,4.3l0,0l0,0c0.1,1.1,0.1,2.3,0.1,3.3l0,0l0,0
		c0.7-0.1,1.5-0.3,2.3-0.3l0,0l0,0c0.8-0.1,1.6-0.2,2.3-0.3l0,0l0,0c0.7-0.1,1.3-0.2,1.9-0.4l0,0l0,0c0.5-0.2,0.9-0.4,1-0.6l0,0l0,0
		c0-0.6,0-1.2,0-1.9l0,0l0,0c0-0.7,0-1.4-0.1-2.1l0,0l0,0c0-0.7,0-1.3-0.1-1.9l0,0l0,0c0-0.6,0-1,0-1.2l0,0L125.6,164.2L125.6,164.2
		z"
        fill="currentColor"
      />
      <path
        d="M195.9,206L195.9,206c0-0.2,0-0.3,0-0.4l0,0l0,0c0-0.1,0-0.1,0-0.2l0,0l0,0c0-0.1,0-0.1,0-0.3l0,0l0,0
		c0-0.1,0-0.3,0-0.5l0,0l0,0c0-0.1-0.1-0.1-0.3,0l0,0l0,0c-0.2,0-0.5,0.1-0.8,0.1l0,0l0,0c-0.3,0.1-0.6,0.1-0.9,0.2l0,0l0,0
		c-0.3,0.1-0.5,0.1-0.6,0.1l0,0l0,0c-0.3,0.1-0.6,0.1-0.8,0.2l0,0l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0
		l0,0c-0.2,0-0.5,0-0.8,0.1l0,0l0,0c-0.1,3.4-0.1,6.7-0.1,9.9l0,0l0,0c0.1,3.2,0.1,6.3,0.1,9.5l0,0l0,0c0.6-0.1,1.2-0.2,1.6-0.3l0,0
		l0,0c0.5,0,0.9-0.1,1.3-0.1l0,0l0,0c0.4,0,0.8-0.1,1.1-0.2l0,0l0,0c0.4-0.1,0.8-0.2,1.2-0.4l0,0v-0.2l0,0c0-1,0-1.9,0-2.7l0,0l0,0
		c0-0.8,0-1.7,0-2.5l0,0l0,0c0-0.8,0-1.6,0-2.4l0,0l0,0c0-0.8-0.1-1.6-0.1-2.5l0,0l0,0c0-0.7,0.1-1.3,0.3-1.7l0,0l0,0
		c0.2-0.4,0.6-0.8,1-1l0,0l0,0c0.4-0.2,0.9-0.4,1.5-0.5l0,0l0,0c0.6-0.1,1.2-0.2,1.9-0.2l0,0l0,0c0,0,0.2,0,0.5,0l0,0l0,0
		c0.3,0,0.6,0,0.9,0l0,0l0,0c0.1,0,0.3,0.1,0.4,0.1l0,0l0,0c0.1,0,0.3,0,0.4,0.1l0,0l0.3,0.1l0,0c0-0.4,0-0.8,0-1.2l0,0l0,0
		c0-0.4,0-0.9,0-1.5l0,0l0,0c0-0.5,0-1.1,0-1.8l0,0l0,0c0-0.7,0-1.4,0-2.3l0,0l0,0c0,0-0.2,0-0.6,0l0,0l0,0c-0.4,0-0.8,0.1-1.4,0.2
		l0,0l0,0c-0.5,0.1-1.1,0.3-1.7,0.4l0,0l0,0c-0.6,0.2-1.2,0.4-1.7,0.6l0,0l0,0c-0.4,0.2-0.7,0.4-1.1,0.6l0,0l0,0
		C196.3,205.8,196,206,195.9,206L195.9,206L195.9,206z M214.3,219L214.3,219c0,0.4,0,0.7,0,1l0,0l0,0c0,0.3,0,0.6,0.1,1.1l0,0l0,0
		c0,0.1,0.1,0.1,0.3,0.1l0,0l0,0c0.2,0,0.4-0.1,0.7-0.1l0,0l0,0c0.3-0.1,0.5-0.1,0.8-0.2l0,0l0,0c0.3-0.1,0.4-0.1,0.5-0.1l0,0l0,0
		c0.3-0.1,0.6-0.1,0.8-0.2l0,0l0,0c0.2,0,0.4-0.1,0.6-0.1l0,0l0,0c0.2,0,0.4-0.1,0.6-0.1l0,0l0,0c0.2,0,0.5,0,0.8-0.1l0,0l0,0
		c0.1-3.4,0.1-6.7,0.1-9.9l0,0l0,0c-0.1-3.2-0.1-6.3-0.1-9.5l0,0l0,0c-0.6,0.1-1.1,0.2-1.5,0.3l0,0l0,0c-0.4,0-0.8,0.1-1.1,0.1l0,0
		l0,0c-0.3,0-0.7,0.1-1,0.2l0,0l0,0c-0.3,0.1-0.7,0.2-1.1,0.4l0,0v0.2l0,0c-0.1,0.9-0.2,1.7-0.2,2.6l0,0l0,0c0,0.8,0,1.7,0.1,2.6
		l0,0l0,0c0,0.9,0.1,1.7,0.1,2.6l0,0l0,0c0,0.9,0,1.8,0,2.6l0,0l0,0c-0.1,0.7-0.2,1.3-0.5,1.7l0,0l0,0c-0.3,0.4-0.7,0.7-1,0.9l0,0
		l0,0c-0.4,0.2-0.8,0.3-1.2,0.3l0,0l0,0c-0.4,0-0.7,0-0.9-0.1l0,0l0,0c0,0-0.1,0-0.1,0l0,0l0,0c0,0-0.1,0-0.1,0l0,0l0,0
		c-0.2-0.1-0.4-0.3-0.5-0.6l0,0l0,0c-0.1-0.3-0.2-0.6-0.3-0.9l0,0l0,0c-0.1-0.4-0.1-0.7-0.1-1.1l0,0l0,0c0-0.4-0.1-0.7-0.1-1l0,0
		l0,0c-0.1-0.7-0.1-1.4-0.1-2.2l0,0l0,0c0-0.8,0-1.6,0-2.4l0,0l0,0c0-0.8,0-1.6,0-2.3l0,0l0,0c0-0.7,0-1.4-0.1-2l0,0l0,0
		c-0.9,0.1-1.7,0.3-2.4,0.4l0,0l0,0c-0.7,0.1-1.5,0.3-2.4,0.6l0,0l0,0c0.1,0.9,0.1,1.9,0,3.1l0,0l0,0c0,1.2-0.1,2.4-0.1,3.6l0,0l0,0
		c0,1.3,0,2.5-0.1,3.7l0,0l0,0c0,1.2,0,2.4,0,3.4l0,0l0,0c0,1,0.1,1.9,0.3,2.6l0,0l0,0c0.1,0.7,0.3,1.2,0.6,1.4l0,0l0,0
		c0.5,0.4,1.1,0.6,1.8,0.7l0,0l0,0c0.7,0.1,1.4,0,2.1-0.1l0,0l0,0c0.7-0.1,1.4-0.4,2.1-0.7l0,0l0,0c0.7-0.3,1.3-0.7,1.8-1.2l0,0l0,0
		c0.1-0.1,0.3-0.3,0.5-0.4l0,0l0,0C214.1,219.2,214.2,219.1,214.3,219L214.3,219L214.3,219z M227.4,201.8L227.4,201.8
		c0-0.4,0-0.7,0-1l0,0l0,0c0-0.3,0-0.6-0.1-1.1l0,0l0,0c0-0.1-0.1-0.1-0.3-0.1l0,0l0,0c-0.2,0-0.4,0.1-0.7,0.1l0,0l0,0
		c-0.3,0.1-0.5,0.1-0.8,0.2l0,0l0,0c-0.3,0.1-0.4,0.1-0.5,0.1l0,0l0,0c-0.3,0.1-0.6,0.1-0.8,0.2l0,0l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0
		l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0l0,0c-0.2,0-0.5,0-0.8,0.1l0,0l0,0c-0.1,3.4-0.1,6.7-0.1,9.9l0,0l0,0c0.1,3.2,0.1,6.3,0.1,9.5l0,0
		l0,0c0.6-0.1,1.1-0.2,1.5-0.3l0,0l0,0c0.4,0,0.8-0.1,1.1-0.1l0,0l0,0c0.3,0,0.7-0.1,1-0.2l0,0l0,0c0.3-0.1,0.7-0.2,1.1-0.4l0,0
		v-0.2l0,0c0.1-0.9,0.2-1.7,0.2-2.6l0,0l0,0c0-0.9,0-1.7-0.1-2.6l0,0l0,0c0-0.9-0.1-1.7-0.1-2.6l0,0l0,0c0-0.9,0-1.7,0-2.7l0,0l0,0
		c0.1-0.7,0.2-1.3,0.5-1.7l0,0l0,0c0.3-0.4,0.7-0.7,1-0.9l0,0l0,0c0.4-0.2,0.8-0.3,1.2-0.3l0,0l0,0c0.4,0,0.7,0,0.9,0.1l0,0l0,0
		c0,0,0.1,0,0.1,0l0,0l0,0c0,0,0.1,0,0.1,0l0,0l0,0c0.2,0.1,0.4,0.3,0.5,0.6l0,0l0,0c0.1,0.3,0.2,0.6,0.3,0.9l0,0l0,0
		c0.1,0.4,0.1,0.7,0.1,1.1l0,0l0,0c0,0.4,0.1,0.7,0.1,1l0,0l0,0c0.1,0.7,0.1,1.4,0.1,2.2l0,0l0,0c0,0.8,0,1.6,0,2.4l0,0l0,0
		c0,0.8,0,1.6,0,2.3l0,0l0,0c0,0.7,0,1.4,0.1,2l0,0l0,0c0.9-0.1,1.7-0.3,2.4-0.4l0,0l0,0c0.7-0.1,1.5-0.3,2.4-0.6l0,0l0,0
		c-0.1-0.9-0.1-1.9,0-3.1l0,0l0,0c0-1.2,0.1-2.4,0.1-3.6l0,0l0,0c0-1.3,0-2.5,0.1-3.7l0,0l0,0c0-1.2,0-2.4,0-3.4l0,0l0,0
		c0-1-0.1-1.9-0.3-2.6l0,0l0,0c-0.1-0.7-0.3-1.2-0.6-1.4l0,0l0,0c-0.5-0.4-1.1-0.6-1.8-0.7l0,0l0,0c-0.7-0.1-1.4,0-2.1,0.1l0,0l0,0
		c-0.7,0.1-1.4,0.4-2.1,0.7l0,0l0,0c-0.7,0.3-1.3,0.7-1.8,1.2l0,0l0,0c-0.1,0.1-0.3,0.3-0.5,0.4l0,0l0,0
		C227.7,201.6,227.6,201.7,227.4,201.8L227.4,201.8L227.4,201.8z M172.7,219.9L172.7,219.9c0.2,0.3,0.5,0.4,0.8,0.6l0,0l0,0
		c0.3,0.1,0.7,0.2,1,0l0,0l0,0l0,0c0.4-0.1,0.8-0.2,1.1-0.3l0,0l0,0c0.4-0.1,0.7-0.1,1.1-0.2l0,0l0,0c0.2-0.1,0.5-0.1,0.7-0.2l0,0
		l0,0c0.2-0.1,0.5-0.2,0.7-0.3l0,0l0,0c0.4-0.2,0.6-0.5,0.8-0.8l0,0l0,0c0.2-0.3,0.3-0.7,0.4-1.1l0,0l0,0c0.1-0.6,0.1-1.2,0.2-1.7
		l0,0l0,0c0.1-0.6,0-1.1,0-1.7l0,0l0,0c-0.1-0.4-0.3-0.8-0.6-1l0,0l0,0c-0.4-0.3-0.8-0.4-1.2-0.5l0,0l0,0c-0.2,0-0.4,0-0.7,0l0,0
		l0,0c-0.2,0-0.5,0.1-0.7,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.9,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.9,0.2l0,0l0,0c-0.1,0-0.1,0-0.2,0l0,0l0,0
		c-0.1,0-0.1,0-0.2,0l0,0l0,0c-0.3,0.1-0.5,0.2-0.6,0.2l0,0l0,0c-0.2,0.1-0.4,0.2-0.5,0.4l0,0l0,0c-0.2,0.2-0.3,0.4-0.5,0.7l0,0l0,0
		c-0.1,0.1-0.1,0.1-0.1,0.2l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0l0,0c-0.1,0.3-0.1,0.7-0.1,1l0,0l0,0c0,0.4,0,0.7,0,1l0,0l0,0
		c-0.1,0.8,0,1.5,0.1,2.3l0,0l0,0C172.5,219.5,172.6,219.7,172.7,219.9L172.7,219.9L172.7,219.9z M109,210.6L109,210.6
		c0,0-0.2,0-0.5,0l0,0l0,0c-0.3,0-0.8,0.1-1.3,0.1l0,0l0,0c-0.5,0.1-1,0.2-1.6,0.3l0,0l0,0c-0.6,0.1-1.1,0.2-1.5,0.4l0,0v2.6l0,0
		c0,2.1,0,4.3-0.1,6.4l0,0l0,0c-0.1,2.1-0.1,4.3-0.1,6.3l0,0l0,0c0,2.1-0.1,4.1-0.1,6.1l0,0l0,0c0,2,0,3.9,0.1,5.8l0,0l0,0
		c0.8-0.1,1.7-0.2,2.6-0.4l0,0l0,0c1-0.1,1.8-0.3,2.6-0.5l0,0l0,0c0-0.1,0-0.1,0-0.3l0,0l0,0c0-0.3,0-0.5,0-0.6l0,0l0,0
		c0-0.2,0-0.4,0-0.7l0,0l0,0c0.1-0.1,0.1-0.2,0.2-0.3l0,0l0,0c0.1-0.1,0.1,0,0.2,0.1l0,0l0,0c0.4,0.7,0.9,1.1,1.5,1.2l0,0l0,0
		c0.6,0.1,1.3,0.1,2.1-0.2l0,0l0,0c1.5-0.3,2.6-0.9,3.5-1.7l0,0l0,0c0.9-0.8,1.5-1.7,1.9-2.8l0,0l0,0c0.4-1.1,0.7-2.3,0.8-3.6l0,0
		l0,0c0.1-1.3,0.2-2.6,0.3-3.9l0,0l0,0c0-0.9-0.1-1.8-0.2-2.6l0,0l0,0c-0.1-0.8-0.3-1.6-0.5-2.3l0,0l0,0c-0.2-0.7-0.6-1.2-1-1.7l0,0
		l0,0c-0.4-0.4-0.9-0.7-1.5-0.8l0,0l0,0c-0.7-0.1-1.5-0.2-2.3-0.1l0,0l0,0c-0.8,0.1-1.6,0.2-2.4,0.4l0,0l0,0
		c-0.7,0.2-1.4,0.4-1.9,0.7l0,0l0,0c-0.5,0.3-0.8,0.5-0.9,0.8l0,0l0,0c0-1.2,0-2.2,0-2.9l0,0l0,0c0-0.7,0-1.3,0-1.8l0,0l0,0
		c0-0.5,0-0.8,0-1l0,0l0,0c0-0.2,0-0.5,0-0.7l0,0l0,0c0-0.2,0-0.5,0-0.7l0,0l0,0c0-0.3,0-0.7-0.1-1.2l0,0V210.6L109,210.6z
		 M115,224.6L115,224.6c0.1,0.3,0.2,0.7,0.2,1.1l0,0l0,0c0,0.4,0,0.8,0,1.1l0,0l0,0c0,0.6-0.1,1.3-0.2,1.9l0,0l0,0
		c-0.2,0.6-0.4,1.2-0.7,1.7l0,0l0,0c-0.3,0.5-0.7,1-1.1,1.3l0,0l0,0c-0.4,0.4-0.9,0.6-1.5,0.7l0,0l0,0c-0.7,0.1-1.2,0-1.5-0.2l0,0
		l0,0c-0.4-0.2-0.6-0.6-0.8-1l0,0l0,0c-0.2-0.5-0.3-1-0.3-1.7l0,0l0,0c0-0.7-0.1-1.4-0.1-2.3l0,0l0,0c0-1,0-1.8,0.1-2.4l0,0l0,0
		c0-0.6,0.1-1,0.3-1.4l0,0l0,0c0.2-0.3,0.4-0.6,0.7-0.7l0,0l0,0c0.3-0.1,0.8-0.3,1.3-0.3l0,0l0,0c1.1-0.2,1.9-0.1,2.5,0.3l0,0l0,0
		C114.5,223.1,114.9,223.8,115,224.6L115,224.6L115,224.6z M126.6,215.5L126.6,215.5c-0.8,0.2-1.5,0.3-2.1,0.3l0,0l0,0
		c-0.6,0.1-1.1,0.1-1.4,0.1l0,0l0,0c-0.1,0-0.3,0-0.6,0.1l0,0l0,0c-0.2,0-0.4,0-0.5,0.1l0,0v0l0,0c-0.2,1.5-0.2,3.1-0.2,4.7l0,0l0,0
		c0,1.6,0,3.2,0.1,4.9l0,0l0,0c0.1,1.6,0.1,3.3,0.1,5l0,0l0,0c0,1.7,0,3.3-0.2,5l0,0l0,0c0.2-0.1,0.5-0.2,1.1-0.2l0,0l0,0
		c0.5-0.1,1.1-0.1,1.7-0.2l0,0l0,0c0.6-0.1,1.2-0.2,1.7-0.3l0,0l0,0c0.5-0.1,0.8-0.2,1-0.4l0,0l0,0c-0.2-3.1-0.2-6.3-0.1-9.5l0,0
		l0,0c0.1-3.3,0.1-6.5,0.2-9.6l0,0v-0.1L126.6,215.5L126.6,215.5z M121.9,207.8L121.9,207.8c0,1.2,0,2.2,0.1,3l0,0l0,0
		c0.1,0.8,0.1,1.6,0.1,2.3l0,0l0,0c0.5-0.1,1-0.2,1.6-0.2l0,0l0,0c0.6-0.1,1.1-0.1,1.6-0.2l0,0l0,0c0.5-0.1,0.9-0.2,1.3-0.3l0,0l0,0
		c0.4-0.1,0.6-0.3,0.7-0.4l0,0l0,0c0-0.4,0-0.8,0-1.3l0,0l0,0c0-0.5,0-1,0-1.5l0,0l0,0c0-0.5,0-0.9,0-1.3l0,0l0,0c0-0.4,0-0.7,0-0.8
		l0,0L121.9,207.8L121.9,207.8z M135.3,205.5L135.3,205.5c-0.3,0-0.7,0-1.2,0.1l0,0l0,0c-0.4,0.1-0.9,0.1-1.4,0.2l0,0l0,0
		c-0.5,0.1-1,0.2-1.4,0.3l0,0l0,0c-0.5,0.1-0.9,0.2-1.3,0.2l0,0l0,0c0,4.7,0,9.4-0.1,14l0,0l0,0c-0.1,4.6-0.1,9.3,0.1,14.1l0,0l0,0
		c0.4,0,0.8-0.1,1.3-0.1l0,0l0,0c0.5-0.1,0.9-0.2,1.4-0.3l0,0l0,0c0.5-0.1,0.9-0.2,1.3-0.3l0,0l0,0c0.4-0.1,0.8-0.1,1.1-0.1l0,0l0,0
		c0.1-0.6,0.2-1.2,0.2-1.9l0,0l0,0c0-0.7,0-1.4,0-2l0,0l0,0c0-0.7,0-1.3,0-1.9l0,0l0,0c0-0.6,0-1.2,0-1.6l0,0l0,0
		c0.4,0.5,0.8,1,1.2,1.6l0,0l0,0c0.3,0.6,0.7,1.1,1,1.7l0,0l0,0c0.3,0.6,0.6,1.2,0.9,1.8l0,0l0,0c0.3,0.6,0.7,1.2,1.1,1.7l0,0l0,0
		c0.4,0,0.9,0,1.4-0.1l0,0l0,0c0.6-0.1,1.1-0.2,1.7-0.3l0,0l0,0c0.6-0.1,1.2-0.2,1.7-0.4l0,0l0,0c0.6-0.1,1-0.2,1.4-0.3l0,0l0,0
		c-1-1.8-2.1-3.6-3.2-5.2l0,0l0,0c-1.2-1.7-2.3-3.3-3.5-5l0,0l0,0c1.1-1.6,2.2-3.2,3.3-4.8l0,0l0,0c1.1-1.6,2.1-3.2,3.1-4.8l0,0l0,0
		c-0.4,0-0.8,0.1-1.4,0.2l0,0l0,0c-0.6,0.1-1.1,0.2-1.7,0.4l0,0l0,0c-0.6,0.1-1.1,0.2-1.7,0.3l0,0l0,0c-0.5,0.1-1,0.1-1.3,0l0,0l0,0
		c-0.4,0.5-0.7,1-1,1.6l0,0l0,0c-0.3,0.5-0.6,1.1-0.9,1.6l0,0l0,0c-0.3,0.5-0.6,1-0.9,1.6l0,0l0,0c-0.3,0.5-0.7,1-1.1,1.5l0,0l0,0
		c-0.1-0.6-0.1-1.4-0.1-2.3l0,0l0,0c0-1,0.1-1.9,0.1-2.7l0,0V205.5L135.3,205.5z M151.4,221.4L151.4,221.4c0.3,0,0.7,0,1.2-0.1l0,0
		l0,0c0.6-0.1,1.2-0.2,1.9-0.3l0,0l0,0c0.7-0.1,1.5-0.2,2.2-0.4l0,0l0,0c0.8-0.1,1.5-0.3,2.2-0.5l0,0l0,0c0.7-0.2,1.3-0.3,1.8-0.4
		l0,0l0,0c0.5-0.1,0.8-0.2,1-0.3l0,0l0,0c0.1-1.1,0.1-2.2,0-3.2l0,0l0,0c-0.1-1-0.3-2-0.7-2.8l0,0l0,0c-0.4-0.8-0.9-1.5-1.6-2l0,0
		l0,0c-0.7-0.5-1.7-0.8-2.9-0.8l0,0l0,0c-0.9-0.1-1.9,0-2.9,0.1l0,0l0,0c-1,0.2-1.9,0.5-2.8,0.9l0,0l0,0c-0.9,0.4-1.7,0.9-2.4,1.6
		l0,0l0,0c-0.7,0.6-1.2,1.4-1.5,2.2l0,0l0,0c-0.3,0.9-0.5,1.8-0.6,2.7l0,0l0,0c-0.1,0.9-0.2,1.8-0.3,2.8l0,0l0,0
		c-0.1,1.1,0,2.1,0.1,3.2l0,0l0,0c0.1,1,0.3,2,0.6,2.9l0,0l0,0c0.3,0.9,0.7,1.7,1.1,2.4l0,0l0,0c0.5,0.7,1,1.3,1.7,1.6l0,0l0,0
		c0.6,0.3,1.3,0.4,2,0.4l0,0l0,0c0.7-0.1,1.5-0.2,2.4-0.3l0,0l0,0c0.9-0.1,1.7-0.2,2.5-0.4l0,0l0,0c0.8-0.2,1.6-0.5,2.3-0.8l0,0l0,0
		c0.7-0.4,1.3-0.9,1.7-1.6l0,0l0,0c0.2-0.2,0.3-0.5,0.4-0.9l0,0l0,0c0.1-0.4,0.2-0.9,0.3-1.5l0,0l0,0c0.1-0.6,0.1-1.2,0.2-1.8l0,0
		l0,0c0.1-0.7,0.1-1.3,0.2-2l0,0l0,0c-0.3,0-0.6,0-1.1,0l0,0l0,0c-0.4,0.1-0.9,0.1-1.4,0.2l0,0l0,0c-0.5,0.1-0.9,0.2-1.4,0.3l0,0
		l0,0c-0.4,0.1-0.8,0.1-1,0.1l0,0l0,0c0.1,1-0.1,1.9-0.5,2.5l0,0l0,0c-0.4,0.6-0.8,1-1.4,1.2l0,0l0,0c-0.5,0.2-1.1,0.2-1.7,0l0,0
		l0,0c-0.6-0.2-1-0.6-1.3-1.1l0,0l0,0c-0.3-0.4-0.4-0.9-0.5-1.4l0,0l0,0c0-0.5-0.1-1.1-0.1-1.9l0,0V221.4L151.4,221.4z M156.1,214.6
		L156.1,214.6c0.5,0.2,0.8,0.4,1.1,0.9l0,0l0,0c0.3,0.4,0.4,0.9,0.4,1.4l0,0l0,0c0,0.2,0,0.3,0,0.4l0,0l0,0c0,0.1-0.1,0.1-0.2,0.1
		l0,0l0,0c-0.1,0-0.4,0.1-0.7,0.1l0,0l0,0c-0.3,0-0.8,0.1-1.5,0.2l0,0l0,0c-1,0.2-1.7,0.3-2.2,0.4l0,0l0,0c-0.5,0.1-0.9,0.1-1.1,0.1
		l0,0l0,0c-0.2,0-0.4-0.1-0.4-0.2l0,0l0,0c0-0.1,0-0.3,0.1-0.6l0,0l0,0c0.1-0.3,0.1-0.7,0.3-1l0,0l0,0c0.1-0.3,0.3-0.6,0.5-0.9l0,0
		l0,0c0.2-0.3,0.5-0.5,0.9-0.7l0,0l0,0c0.4-0.2,0.9-0.3,1.5-0.4l0,0l0,0c0.3,0,0.5-0.1,0.7,0l0,0l0,0
		C155.7,214.5,155.9,214.5,156.1,214.6L156.1,214.6L156.1,214.6z M86.1,233.6L86.1,233.6c0.2,0.3,0.5,0.4,0.8,0.6l0,0l0,0
		c0.3,0.1,0.7,0.2,1,0l0,0l0,0l0,0c0.4-0.1,0.8-0.2,1.1-0.3l0,0l0,0c0.4-0.1,0.7-0.1,1.1-0.2l0,0l0,0c0.2-0.1,0.5-0.1,0.7-0.2l0,0
		l0,0c0.2-0.1,0.5-0.2,0.7-0.3l0,0l0,0c0.4-0.2,0.6-0.5,0.8-0.8l0,0l0,0c0.2-0.3,0.3-0.7,0.4-1.1l0,0l0,0c0.1-0.6,0.1-1.2,0.2-1.7
		l0,0l0,0c0.1-0.6,0-1.1,0-1.7l0,0l0,0c-0.1-0.4-0.3-0.8-0.6-1l0,0l0,0c-0.4-0.3-0.8-0.4-1.2-0.5l0,0l0,0c-0.2,0-0.4,0-0.7,0l0,0
		l0,0c-0.2,0-0.5,0.1-0.7,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.9,0.1l0,0l0,0c-0.3,0-0.6,0.1-0.9,0.2l0,0l0,0c-0.1,0-0.1,0-0.2,0l0,0l0,0
		c-0.1,0-0.1,0-0.2,0l0,0l0,0c-0.3,0.1-0.5,0.2-0.6,0.2l0,0l0,0c-0.2,0.1-0.4,0.2-0.5,0.4l0,0l0,0c-0.2,0.2-0.3,0.4-0.5,0.7l0,0l0,0
		c-0.1,0.1-0.1,0.1-0.1,0.2l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0l0,0c-0.1,0.3-0.1,0.7-0.1,1l0,0l0,0c0,0.4,0,0.7,0,1l0,0l0,0
		c-0.1,0.8,0,1.5,0.1,2.3l0,0l0,0C85.8,233.2,85.9,233.5,86.1,233.6L86.1,233.6L86.1,233.6z M23,234.1L23,234.1c0-0.1,0-0.4,0-1l0,0
		l0,0c0-0.5,0-1.2,0-1.9l0,0l0,0c0-0.7,0-1.6,0-2.4l0,0l0,0c0-0.9,0-1.7,0-2.4l0,0l0,0c0-0.8,0-1.4,0-2l0,0l0,0c0-0.6,0-0.9,0-1l0,0
		l0,0c0-0.1-0.1-0.1-0.3-0.1l0,0l0,0c-0.2,0-0.4,0.1-0.7,0.1l0,0l0,0c-0.3,0.1-0.5,0.1-0.8,0.2l0,0l0,0c-0.3,0.1-0.4,0.1-0.5,0.1
		l0,0l0,0c-0.3,0.1-0.6,0.1-0.8,0.2l0,0l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0l0,0c-0.2,0-0.4,0.1-0.6,0.1l0,0l0,0c-0.2,0-0.5,0-0.8,0.1
		l0,0l0,0c0,1.1,0,2.5-0.1,4l0,0l0,0c0,1.5,0,3.2,0,4.9l0,0l0,0c0,1.7,0,3.5,0,5.3l0,0l0,0c0,1.8,0,3.6,0,5.3l0,0l0,0
		c0,1.7,0,3.3,0,4.8l0,0l0,0c0,1.5,0,2.8,0,3.8l0,0l0,0c0.6-0.1,1.1-0.2,1.5-0.3l0,0l0,0c0.4,0,0.8-0.1,1.1-0.1l0,0l0,0
		c0.3,0,0.7-0.1,1-0.2l0,0l0,0c0.3-0.1,0.7-0.2,1.1-0.4l0,0v-0.2l0,0c0.1-0.9,0.2-1.7,0.2-2.6l0,0l0,0c0-0.9,0-1.7-0.1-2.6l0,0l0,0
		c0-0.9-0.1-1.7-0.1-2.6l0,0l0,0c0-0.9,0-1.7,0-2.7l0,0l0,0c0.1-0.7,0.2-1.3,0.5-1.7l0,0l0,0c0.3-0.4,0.7-0.7,1-0.9l0,0l0,0
		c0.4-0.2,0.8-0.3,1.2-0.3l0,0l0,0c0.4,0,0.7,0,0.9,0.1l0,0l0,0c0,0,0.1,0,0.1,0l0,0l0,0c0,0,0.1,0,0.1,0l0,0l0,0
		c0.2,0.1,0.4,0.3,0.5,0.6l0,0l0,0c0.1,0.3,0.2,0.6,0.3,0.9l0,0l0,0c0.1,0.4,0.1,0.7,0.1,1.1l0,0l0,0c0,0.4,0.1,0.7,0.1,1l0,0l0,0
		c0.1,0.7,0.1,1.4,0.1,2.2l0,0l0,0c0,0.8,0,1.6,0,2.4l0,0l0,0c0,0.8,0,1.6,0,2.3l0,0l0,0c0,0.7,0,1.4,0.1,2l0,0l0,0
		c0.9-0.1,1.7-0.3,2.4-0.4l0,0l0,0c0.7-0.1,1.5-0.3,2.4-0.6l0,0l0,0c-0.1-0.9-0.1-1.9,0-3.1l0,0l0,0c0-1.2,0.1-2.4,0.1-3.6l0,0l0,0
		c0-1.3,0-2.5,0.1-3.7l0,0l0,0c0-1.2,0-2.4,0-3.4l0,0l0,0c0-1-0.1-1.9-0.3-2.6l0,0l0,0c-0.1-0.7-0.3-1.2-0.6-1.4l0,0l0,0
		c-0.5-0.4-1.1-0.6-1.8-0.7l0,0l0,0c-0.7-0.1-1.4,0-2.1,0.1l0,0l0,0c-0.7,0.1-1.4,0.4-2.1,0.7l0,0l0,0c-0.7,0.3-1.3,0.7-1.8,1.2l0,0
		l0,0c-0.1,0.1-0.3,0.3-0.5,0.4l0,0l0,0C23.3,233.9,23.1,234.1,23,234.1L23,234.1L23,234.1z M40,229.3L40,229.3
		c-0.8,0.2-1.5,0.3-2.1,0.3l0,0l0,0c-0.6,0.1-1.1,0.1-1.4,0.1l0,0l0,0c-0.1,0-0.3,0-0.6,0.1l0,0l0,0c-0.2,0-0.4,0-0.5,0.1l0,0v0l0,0
		c-0.2,1.5-0.2,3.1-0.2,4.7l0,0l0,0c0,1.6,0,3.2,0.1,4.9l0,0l0,0c0.1,1.6,0.1,3.3,0.1,5l0,0l0,0c0,1.7,0,3.3-0.2,5l0,0l0,0
		c0.2-0.1,0.5-0.2,1.1-0.2l0,0l0,0c0.5-0.1,1.1-0.1,1.7-0.2l0,0l0,0c0.6-0.1,1.2-0.2,1.7-0.3l0,0l0,0c0.5-0.1,0.8-0.2,1-0.4l0,0l0,0
		c-0.2-3.1-0.2-6.3-0.1-9.5l0,0l0,0c0.1-3.3,0.1-6.5,0.2-9.6l0,0v-0.1L40,229.3L40,229.3z M35.2,221.6L35.2,221.6c0,1.2,0,2.2,0.1,3
		l0,0l0,0c0.1,0.8,0.1,1.6,0.1,2.3l0,0l0,0c0.5-0.1,1-0.2,1.6-0.2l0,0l0,0c0.6-0.1,1.1-0.1,1.6-0.2l0,0l0,0c0.5-0.1,0.9-0.2,1.3-0.3
		l0,0l0,0c0.4-0.1,0.6-0.3,0.7-0.4l0,0l0,0c0-0.4,0-0.8,0-1.3l0,0l0,0c0-0.5,0-1,0-1.5l0,0l0,0c0-0.5,0-0.9,0-1.3l0,0l0,0
		c0-0.4,0-0.7,0-0.8l0,0L35.2,221.6L35.2,221.6z M48.6,219.3L48.6,219.3c-0.3,0-0.7,0-1.2,0.1l0,0l0,0c-0.4,0.1-0.9,0.1-1.4,0.2l0,0
		l0,0c-0.5,0.1-1,0.2-1.4,0.3l0,0l0,0c-0.5,0.1-0.9,0.2-1.3,0.2l0,0l0,0c0,4.7,0,9.4-0.1,14l0,0l0,0c-0.1,4.6-0.1,9.3,0.1,14.1l0,0
		l0,0c0.4,0,0.8-0.1,1.3-0.1l0,0l0,0c0.5-0.1,0.9-0.2,1.4-0.3l0,0l0,0c0.5-0.1,0.9-0.2,1.3-0.3l0,0l0,0c0.4-0.1,0.8-0.1,1.1-0.1l0,0
		l0,0c0.1-0.6,0.2-1.2,0.2-1.9l0,0l0,0c0-0.7,0-1.4,0-2l0,0l0,0c0-0.7,0-1.3,0-1.9l0,0l0,0c0-0.6,0-1.2,0-1.6l0,0l0,0
		c0.4,0.5,0.8,1,1.2,1.6l0,0l0,0c0.3,0.6,0.7,1.1,1,1.7l0,0l0,0c0.3,0.6,0.6,1.2,0.9,1.8l0,0l0,0c0.3,0.6,0.7,1.2,1.1,1.7l0,0l0,0
		c0.4,0,0.9,0,1.4-0.1l0,0l0,0c0.6-0.1,1.1-0.2,1.7-0.3l0,0l0,0c0.6-0.1,1.2-0.2,1.7-0.4l0,0l0,0c0.6-0.1,1-0.2,1.4-0.3l0,0l0,0
		c-1-1.8-2.1-3.6-3.2-5.2l0,0l0,0c-1.2-1.7-2.3-3.3-3.5-5l0,0l0,0c1.1-1.6,2.2-3.2,3.3-4.8l0,0l0,0c1.1-1.6,2.1-3.2,3.1-4.8l0,0l0,0
		c-0.4,0-0.8,0.1-1.4,0.2l0,0l0,0c-0.6,0.1-1.1,0.2-1.7,0.4l0,0l0,0c-0.6,0.1-1.1,0.2-1.7,0.3l0,0l0,0c-0.5,0.1-1,0.1-1.3,0l0,0l0,0
		c-0.4,0.5-0.7,1-1,1.6l0,0l0,0c-0.3,0.5-0.6,1.1-0.9,1.6l0,0l0,0c-0.3,0.5-0.6,1-0.9,1.6l0,0l0,0c-0.3,0.5-0.7,1-1.1,1.5l0,0l0,0
		c-0.1-0.6-0.1-1.4-0.1-2.3l0,0l0,0c0-1,0.1-1.9,0.1-2.7l0,0V219.3L48.6,219.3z M64.8,235.1L64.8,235.1c0.3,0,0.7,0,1.2-0.1l0,0l0,0
		c0.6-0.1,1.2-0.2,1.9-0.3l0,0l0,0c0.7-0.1,1.5-0.2,2.2-0.4l0,0l0,0c0.8-0.1,1.5-0.3,2.2-0.5l0,0l0,0c0.7-0.2,1.3-0.3,1.8-0.4l0,0
		l0,0c0.5-0.1,0.8-0.2,1-0.3l0,0l0,0c0.1-1.1,0.1-2.2,0-3.2l0,0l0,0c-0.1-1-0.3-2-0.7-2.8l0,0l0,0c-0.4-0.8-0.9-1.5-1.6-2l0,0l0,0
		c-0.7-0.5-1.7-0.8-2.9-0.8l0,0l0,0c-0.9-0.1-1.9,0-2.9,0.1l0,0l0,0c-1,0.2-1.9,0.5-2.8,0.9l0,0l0,0c-0.9,0.4-1.7,0.9-2.4,1.6l0,0
		l0,0c-0.7,0.6-1.2,1.4-1.5,2.2l0,0l0,0c-0.3,0.9-0.5,1.8-0.6,2.7l0,0l0,0c-0.1,0.9-0.2,1.8-0.3,2.8l0,0l0,0c-0.1,1.1,0,2.1,0.1,3.2
		l0,0l0,0c0.1,1,0.3,2,0.6,2.9l0,0l0,0c0.3,0.9,0.7,1.7,1.1,2.4l0,0l0,0c0.5,0.7,1,1.3,1.7,1.6l0,0l0,0c0.6,0.3,1.3,0.4,2,0.4l0,0
		l0,0c0.7-0.1,1.5-0.2,2.4-0.3l0,0l0,0c0.9-0.1,1.7-0.2,2.5-0.4l0,0l0,0c0.8-0.2,1.6-0.5,2.3-0.8l0,0l0,0c0.7-0.4,1.3-0.9,1.7-1.6
		l0,0l0,0c0.2-0.2,0.3-0.5,0.4-0.9l0,0l0,0c0.1-0.4,0.2-0.9,0.3-1.5l0,0l0,0c0.1-0.6,0.1-1.2,0.2-1.8l0,0l0,0c0.1-0.7,0.1-1.3,0.2-2
		l0,0l0,0c-0.3,0-0.6,0-1.1,0l0,0l0,0c-0.4,0.1-0.9,0.1-1.4,0.2l0,0l0,0c-0.5,0.1-0.9,0.2-1.4,0.3l0,0l0,0c-0.4,0.1-0.8,0.1-1,0.1
		l0,0l0,0c0.1,1-0.1,1.9-0.5,2.5l0,0l0,0c-0.4,0.6-0.8,1-1.4,1.2l0,0l0,0c-0.5,0.2-1.1,0.2-1.7,0l0,0l0,0c-0.6-0.2-1-0.6-1.3-1.1
		l0,0l0,0c-0.3-0.4-0.4-0.9-0.5-1.4l0,0l0,0c0-0.5-0.1-1.1-0.1-1.9l0,0V235.1L64.8,235.1z M69.4,228.3L69.4,228.3
		c0.5,0.2,0.8,0.4,1.1,0.9l0,0l0,0c0.3,0.4,0.4,0.9,0.4,1.4l0,0l0,0c0,0.2,0,0.3,0,0.4l0,0l0,0c0,0.1-0.1,0.1-0.2,0.1l0,0l0,0
		c-0.1,0-0.4,0.1-0.7,0.1l0,0l0,0c-0.3,0-0.8,0.1-1.5,0.2l0,0l0,0c-1,0.2-1.7,0.3-2.2,0.4l0,0l0,0c-0.5,0.1-0.9,0.1-1.1,0.1l0,0l0,0
		c-0.2,0-0.4-0.1-0.4-0.2l0,0l0,0c0-0.1,0-0.3,0.1-0.6l0,0l0,0c0.1-0.3,0.1-0.7,0.3-1l0,0l0,0c0.1-0.3,0.3-0.6,0.5-0.9l0,0l0,0
		c0.2-0.3,0.5-0.5,0.9-0.7l0,0l0,0c0.4-0.2,0.9-0.3,1.5-0.4l0,0l0,0c0.3,0,0.5-0.1,0.7,0l0,0l0,0C69,228.2,69.2,228.2,69.4,228.3
		L69.4,228.3L69.4,228.3z"
        fill="currentColor"
      />
    </g>
    <path
      d="M43.3,138.7c0.1-0.2,0.3-0.5,0.4-0.6c0.2-0.1,0.5-0.4,1.2-1.8l0-0.1l0.6-2.9l0.6-2.2v18.3l-5.4-0.9
		C40.6,145.3,43.3,138.7,43.3,138.7z M47.2,127.9l0.6-1.9l3.8-1.1l0,5.7l0,1.4c-1.1,3.8,1.2,5.9,1.3,6c0.1,0.1,0.3,0.2,0.5,0.1
		l1.9-0.8c-0.2,0.6-0.4,1-0.4,1.1c-0.1,0.1-0.2,0.2-1.4,2.9l-1.2,2.1l-2.9,3.9l-2.3,1.7V127.9z M51.1,117.4l1.3,0.2l-1.4,0.1
		L51.1,117.4z M57.1,118.3c-0.1-0.1-0.1-0.2-0.2-0.2l0.2-0.3l2.3,0c0.1,0,0.1,0,0.1,0l0,0l0.2,0.3v13.8c-0.3,0.1-0.7,0.2-1.1,0.2
		c-0.7,0-1.3-0.2-1.9-0.5l0.9-3.2l0-0.1l0.1-7.3C57.7,120.7,57.9,119.2,57.1,118.3z M98.9,143.6c0.1-0.1,0.2-0.2,0.3-0.3
		c0.4-0.4,0.8-0.9,1.2-1.3c0.4-0.4,0.8-0.8,1.2-1.3c0.4-0.6,0.8-1,1.3-1.5c0.2-0.3,0.5-0.3,0.8-0.5c0.2-0.1,0.4-0.3,0.6-0.4
		c0.4-0.2,0.6-0.6,1-0.9c0.4-0.3,1.3-0.9,1.5-1.5c0.1-0.1,0.3-0.1,0.4-0.2c0.5-0.2,0.7-0.7,1.1-1.1c0.4-0.5,0.8-1,1.3-1.5
		c0.4-0.4,0.6-0.9,0.9-1.4c0.7-1,1.4-2,2.1-3c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.4-0.4,0.5-0.7c0.1-0.2,0.2-0.3,0.3-0.4
		c0.2,0.2,0.4,0.3,0.5,0.5c0.7,0.5,1.4,1.1,2.2,1.5c1.7,0.9,3.8,0.9,5.5,1.8c-0.2,0.2-0.4,0.4-0.6,0.5c-0.6,0.3-1.3,0.5-1.8,0.9
		c-0.5,0.3-1,0.7-1.5,1.1c-0.3,0.3-0.6,0.7-0.8,1.1c-0.8,1-1.7,2-2.5,3.1c-0.4,0.6-0.8,1.1-1.3,1.7c-0.4-0.1-0.7,0-1.2,0.2
		c-0.5,0.2-1.2,0.4-1.5,0.8c-0.4,0.5-1.4,1.6-0.7,2.3c0.4,0.4,1.1,0.5,1.6,0.7c0.6,0.2,1.3,0.8,1.8,1.3c0.4,0.4,0.9,0.8,1.4,1.1
		c0.5,0.4,0.9,0.7,1.5,1l0.1,0h-6.5l-2.5,1.6l-4.7,0.7l-2.2,0.3c0-0.1-0.1-0.2-0.2-0.2c-0.5-0.5-0.9-0.9-1.2-1.6
		c-0.2-0.7-0.3-1.4-0.3-2.1c0-0.4,0.3-0.6,0.4-0.9c0-0.1,0.1-0.2,0.1-0.2C99.3,143.9,99.1,143.7,98.9,143.6z M157.8,133.8l0.5-0.4
		c0.2,0,0.3-0.1,0.5-0.2l0.1,0c0,0.1,0,0.2,0,0.2c0,0,0.1,0,0.2-0.1c0.1,0,0.2,0.1,0.3,0.1l0,0.1l0.2,1.1l-2,4.9
		c-0.6-0.2-1.3-0.4-1.8-0.8L157.8,133.8z M153.7,137l3.7-2.9l-1.8,4.5C155,138.2,154.3,137.6,153.7,137z M152.4,135.3l4-3.1l1.1-0.2
		c0.1,0.2,0.3,0.5,0.5,0.7l-0.4,1.1l-4,3.2C153.1,136.4,152.8,135.9,152.4,135.3z M151.5,132.8l4.5-0.6l-3.7,2.9
		C151.9,134.4,151.7,133.6,151.5,132.8z M151.3,132.1c-0.1-0.5-0.1-1-0.1-1.5l4.8-0.7l1.1,0.4c0,0.1,0,0.3,0,0.4
		c0,0.1,0.1,0.3,0.1,0.4l-0.9,0.7l-4.9,0.7C151.4,132.5,151.4,132.3,151.3,132.1z M151.7,128.2l4,1.6l-4.3,0.6
		C151.3,129.6,151.4,128.9,151.7,128.2z M157.1,129.8c0,0.1,0,0.2,0,0.3l-0.6-0.2L157.1,129.8z M157.4,131.8l-0.6,0.1l0.5-0.4
		C157.3,131.6,157.4,131.7,157.4,131.8z M152.6,126.2l4.2,1.7l0.7,0.9c-0.1,0.2-0.2,0.5-0.3,0.8l-1.1,0.2l-4.3-1.7
		C151.9,127.3,152.2,126.7,152.6,126.2z M154,124.4l2.5,3.2l-3.8-1.5C153.1,125.4,153.5,124.8,154,124.4z M157.8,128.3
		c-0.1,0.1-0.1,0.2-0.2,0.3l-0.4-0.5L157.8,128.3z M155.8,123l2.7,3.4l0.2,1.1c-0.2,0.1-0.5,0.3-0.7,0.5l-1.1-0.4l-2.7-3.5
		C154.7,123.8,155.2,123.4,155.8,123z M157.9,122.2l0.5,3.8l-2.4-3.1C156.6,122.6,157.2,122.4,157.9,122.2z M159.2,127.3
		c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.6L159.2,127.3z M158.9,122c0.4-0.1,0.8-0.1,1.1-0.1l0.6,4.2l-0.4,1.1c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.3,0.1-0.4,0.1l-0.7-0.9l-0.6-4.2C158.4,122.1,158.7,122,158.9,122z M162.3,122.1l-1.4,3.5l-0.5-3.8
		C161,121.9,161.6,122,162.3,122.1z M164.2,122.8l-2.7,4.5c-0.1,0-0.3-0.1-0.4-0.1l-0.2-1.1l1.6-3.9
		C163.1,122.3,163.7,122.6,164.2,122.8z M165.2,123.4c0.3,0.2,0.6,0.5,0.9,0.7l-2.6,2.1L165.2,123.4z M167.6,125.8l-3.5,2.7
		l-1.1,0.2c-0.1-0.2-0.3-0.5-0.5-0.7l0.1-0.3l0.6-1l3.1-2.5C166.8,124.8,167.3,125.3,167.6,125.8z M167.5,118.6
		c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1,0,0.1-0.1,0.3l0-0.1c-0.6,0.2-0.7,0.6-0.8,1c-0.1,0-0.3,0-0.4,0
		C166.8,119.3,167.1,118.8,167.5,118.6z M167.8,116.3l0-0.3l0.3,0.2L167.8,116.3z M168.4,116.5c0,0,0.6,0.4,0.6,0.5
		c0,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.4-0.4-0.4-0.4L168.4,116.5z M168.8,128.1l0,0.1L164,129l0.2-0.2L168.8,128.1z M167.2,119.6
		L167.2,119.6c0-0.3,0.1-0.7,0.6-0.8C167.6,119.1,167.3,119.6,167.2,119.6z M168.7,127.9l-4,0.6l3.1-2.5
		C168.1,126.6,168.5,127.2,168.7,127.9z M163.4,129.7c0-0.1,0-0.2-0.1-0.2l0.2-0.2l5.3-0.8c0,0.1,0.1,0.2,0.1,0.4L163.4,129.7z
		 M169,132.5l-2.4-1l2.5,0.2C169.1,132,169.1,132.3,169,132.5z M162.6,136.7c0.1,0.2,0.1,0.6,0.4,0.4c0.2-0.1,0.5-0.3,0.7-0.4
		l1.4,1.8c-0.7,0.4-1.4,0.8-2.2,1l-0.6-4.3C162.4,135.8,162.6,136.6,162.6,136.7z M163.7,133l2,2.5l-1.9,0.7
		c-0.1-0.3-0.1-0.9-0.3-0.9c-0.1,0-0.4,0.1-0.5,0.1l-0.1-0.1l0,0c0-0.3-0.3-1.6-0.4-2.4c0.1-0.1,0.1-0.1,0.2-0.2L163.7,133z
		 M163.4,132.6l-0.6-0.2c0.1-0.1,0.1-0.2,0.2-0.3L163.4,132.6z M166,135.6l2.1-0.8l0.1,0c-0.4,0.7-0.8,1.4-1.3,2L166,135.6z
		 M164.1,133.2l3.8,1.5l-2,0.8L164.1,133.2z M166.8,137c-0.5,0.5-1,0.9-1.5,1.3l-1.4-1.8l0,0l2-0.7L166.8,137z M163.2,128.9l0.6-0.1
		l-0.2,0.2l-0.4,0.1L163.2,128.9z M163.4,131.3l2.5,0.2l3.1,1.3c-0.1,0.6-0.3,1.2-0.6,1.8l-0.2,0.1l-4.4-1.8l-0.7-0.9
		C163.2,131.7,163.3,131.5,163.4,131.3z M160.5,127.1l0.2-0.6l0.1,0.6C160.7,127.2,160.6,127.2,160.5,127.1z M159.9,134.6l0.3-0.7
		c0.1,0.1,0.4,0.2,0.9,0.2c0.3,0,0.5,0,0.6,0l0.1,0.2l0.7,5.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.3,0-0.6,0.1-0.8,0.1L159.9,134.6z
		 M159.8,133.7c0.1,0,0.3-0.1,0.3-0.1l-0.2,0.5L159.8,133.7z M160.4,139.8c-0.9,0-1.7-0.1-2.5-0.3l1.8-4.5L160.4,139.8z
		 M170.7,133.8l1.9-0.7c0.2,0.1,0.4,0.2,0.7,0.2c0.9,0.1,1.8,0.1,1.8,0.1s0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.4,0.1l-0.1,0.2l0.3,0.2
		l0.1,0c0,0,0,0.1,0.1,0.2c0.1,0.1,0.5,0.4,0.7,0.4c0.1,0,0.4-0.4,0.5-0.4c0.1,0,0.2,0.2,0.3,0.2c0.1-0.1,0.7-0.6,0.7-0.6l-0.3-0.3
		c0,0,0.3,0,0.5,0c0.2-0.1,0.7-0.6,0.7-0.6c0.1,0,0.4-0.1,0.4-0.2c0.1-0.2,0.2-0.9-0.8-0.9c-1,0-1.3,0.1-1.3,0c0-0.1,0-0.2,0-0.2
		l-0.8-0.2c0,0,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.3-0.2-0.4c-0.1,0-0.2-0.2-0.3-0.2c0.1-0.3,0.1-0.7,0.1-1.1
		c-0.1-0.5-0.2-0.9-0.5-1.3c0.1-0.2,0.2-0.4,0.2-0.6l8.3-14.8c0.2,0.3,0.4,0.6,0.4,0.6c0.2,0.1,0.3,0.3,0.3,0.3c0,0.1,0,0.2,0.1,0.2
		c0.6,0.3,0.6,0.8,0.9,1.2c0.3,0.4,0.5,0.2,0.7,0.4c0.1,0.1,0.5,0.7,1,1.6c-3.1,2.3-4.8,6.2-4.3,10.3c0.6,4.3,3.6,7.6,7.5,8.8
		c-4.7,0.3-10.1,0.8-10.1,0.8l-5.1,0.6l-0.5,1l-3.8,0.5l-1.9-0.4l-3.2,0.8C168.6,138.2,170,136.2,170.7,133.8z M170.8,126
		c0,0,0.4,0.8,0.5,1.1c0.1,0.2,0.1,0.4,0.2,0.8l-0.8,0.1c-0.2-0.7-0.5-1.4-0.8-2.1C170.4,126,170.8,126,170.8,126z M170.8,128.3
		l0.6-0.1c-0.1,0.1-0.2,0.3-0.3,0.4l-0.2,0C170.9,128.5,170.8,128.4,170.8,128.3z M171.1,131.5l0.2,0c0.2,0.3,0.5,0.6,0.9,0.9l0,0.1
		c0,0.2,0.1,0.4,0.3,0.6l-1.7,0.6C171,132.9,171.1,132.2,171.1,131.5z M171.1,103.6c0.2-0.3,0.6-1.4,0.6-1.7c0-0.3,0.2-1.4,0.3-1.5
		c0.2-0.1,0.5,0.7,0.9,1.2c0.4,0.5,1.1,1.5,1.4,1.8c0.3,0.3,1.1,1.1,1.7,1.6c0.6,0.5,3.8,2.7,4.1,3l0,0c-0.2,0.2-0.1,0.3-0.1,0.4
		l0,0.1c0,0.1-0.2,0.2-0.3,0.3l0.1,0.1c0,0,0.3-0.2,0.3-0.3c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2,0-0.3c0.4,0.3,0.9,0.8,1.1,1
		c0.2,0.1,0.6,0.8,0.9,0.9c0.1,0,0.3,0,0.5,0c0,0.1,0.1,0.1,0.1,0.2l-6.8,3.6c0.4-0.4,1-1.1,1.2-1.4c0.3-0.5,1-2.2,0.6-3.1
		c-0.4-0.9-0.9-1.5-1.9-1.9c-1-0.3-1.9-1.1-2.6-1.3c-0.7-0.2-2.8-0.9-3-1.1C170.2,104.7,170.8,103.9,171.1,103.6z M181.3,112.4
		l2.1-1.1l-0.5,0.9L181.3,112.4z M173.9,116.5c0-0.1,0-0.1,0-0.2l2.3-1.2L173.9,116.5z M179.4,116.8l-0.5-0.3l0.2-0.4
		c0.1-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2,0-0.3,0.1l-0.2,0.4l-0.5-0.3c-0.2-0.1-0.6-0.1-0.7,0.2l-0.5,0.9l0.1,0.1l0,0l-2.2,3.8
		c-0.1,0.2-0.1,0.5,0.2,0.7l1.2,0.7c0.2,0.1,0.5,0.1,0.7-0.2l0-0.1c0.1,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
		c0,0,0.2,0,0.3,0l-1.7,3.1c-0.3-0.2-0.8-0.3-1.2-0.3c0-0.3,0-0.8,0-1c-0.1-0.3,0.1-1.2,0.1-1.5c0-0.3-0.1-2-0.3-2.8
		c-0.1-0.9-0.1-1.8-0.1-2.4c0-0.2,0-0.6,0-1l3.6-2.3l3.4-1.8l1.9-0.3l-4,7.2c0-0.2-0.1-0.4-0.2-0.6l0.3-0.6l0,0l0.1,0.1l0.5-0.9
		C179.7,117.3,179.6,117,179.4,116.8z M174.5,98.4c0.1,0,0.9,0.8,1.3,1.3c0.5,0.4,1.4,1.2,1.9,1.6c0.5,0.4,0.8,0.9,1.2,1.2
		c0.3,0.4,2.5,1.6,3.1,2c0.6,0.4,1.2,0.7,1.3,0.8c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0,0.2
		c-0.1,0.1-0.1,0.3,0,0.4c0.1,0,0.3-0.3,0.4-0.3c0,0,0.2,0.2,0.2,0.3c0,0.1,0.5,0.3,0.5,0.4c0,0.1-0.7,0.4-0.8,0.5
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.8,0.3-1,0.3c-0.2,0.1-0.2,0.3-0.5,0.3
		c-0.3,0-0.8-0.3-0.9-0.5c-0.1-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-2.4-3.1-3.1-4.2c-0.7-1.1-1.6-1.8-2.4-2.6
		C174.6,99.1,174.5,98.4,174.5,98.4z M184.6,109.9c0,0,0.3,0.1,0.4,0.1c0.1,0,0.3-0.3,0.3-0.4c0-0.1-0.1-0.5-0.1-0.5
		c0.1,0,0.5,0.1,0.6,0c0.1-0.1,0.2-0.1,0.2-0.2c0.3,0,0.6,0,1,0.1c0.1,0,0.2,0,0.3,0.1l0,0.1c-0.5,1.2-1.9,1.8-2.2,2l-0.7-1
		L184.6,109.9z M186.5,108.3c0.1,0,0.2-0.2,0.3-0.2c0.1,0,0.6,0,0.7-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.2-0.1,0.4-0.2,0.7
		c-0.1,0-0.2,0-0.4-0.1c-0.4,0-0.8-0.1-1-0.1c-0.1-0.1-0.3-0.2-0.3-0.2C185.8,108.4,186.4,108.4,186.5,108.3z M187.1,107.3
		c0.1-0.1,0.3-0.3,0.4-0.3c0,0,0.1,0,0.2,0.1c0,0.1,0,0.3,0,0.5c-0.2,0-0.3,0.1-0.4,0.1C187.2,107.5,187.1,107.3,187.1,107.3z
		 M187.7,108c-0.1,0-0.1-0.1-0.2-0.1l-0.1-0.1c0.1,0,0.2,0,0.3-0.1C187.8,107.8,187.7,107.9,187.7,108z M185.3,111.1L185.3,111.1
		c0.3-0.2,1.7-0.9,2.2-2.1l0-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.4-0.5,0.7C186.9,110.3,185.6,110.9,185.3,111.1z M188,108.5
		c0.1,0.3,0.1,0.8-0.2,1.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.9,0.7-1.9,1-1.9,1l0,0.1l-0.1-0.1c0.3-0.2,1.6-0.9,2-1.3
		c0.4-0.4,0.6-0.6,0.5-0.8c0-0.1-0.1-0.2-0.3-0.2c0.1-0.2,0.1-0.5,0.2-0.6C187.9,108.3,188,108.4,188,108.5z M187.9,107.7l0.1,0
		c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.4,0.2,0.9c-0.1,0.3-0.3,0.6-0.5,0.9c0.2-0.5,0.2-0.8,0.1-1.1c-0.1-0.2-0.2-0.3-0.3-0.4
		C187.9,107.9,187.9,107.8,187.9,107.7z M188.3,107.3L188.3,107.3c0.1,0.1,0.2,0.3,0.3,0.6c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.1-0.3-0.2-0.5-0.2l-0.1,0c0-0.2,0-0.3,0-0.4C188.1,107.2,188.3,107.3,188.3,107.3z M185.7,111.7l-0.2-0.3
		c0.1,0,1.1-0.4,2-1l0.1-0.1C187,110.9,186,111.5,185.7,111.7z M188.4,109.7c-0.2,0.8-0.2,2,0.4,3c0.4,0.7,0.7,1.7,0.8,2.3
		c-0.3,0.1-0.7,0.2-0.8,0.3l-1.3-2.2l-0.9-0.2l-0.8-1.2c0.5-0.3,1.8-1.1,2.2-1.9c0.3-0.3,0.5-0.5,0.6-0.8
		C188.5,109.3,188.5,109.5,188.4,109.7z M191.5,122.8l-0.2-0.1l-2.7-3.5c0.2-0.1,0.3-0.3,0.5-0.4
		C189.9,120.2,190.9,121.8,191.5,122.8z M192.2,117.3l0.5,3.8l-2.2-2.8l-0.2-0.3C191,117.7,191.6,117.4,192.2,117.3z M193.3,117.1
		c0.4-0.1,0.8-0.1,1.1-0.1l0.6,4.2l-0.4,1.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1l-0.7-0.9l-0.6-4.2
		C192.7,117.1,193,117.1,193.3,117.1z M196.6,117.2l-1.4,3.5l-0.5-3.8C195.3,117,196,117.1,196.6,117.2z M198.5,117.9l-2.7,4.5
		c-0.1,0-0.3-0.1-0.4-0.1l-0.2-1.1l1.6-3.9C197.4,117.4,198,117.6,198.5,117.9z M200.5,119.2l-2.6,2.1l1.7-2.8
		C199.9,118.7,200.2,119,200.5,119.2z M202,120.9l-3.5,2.7l-1.1,0.2c-0.1-0.2-0.3-0.5-0.5-0.7l0.1-0.3l0.6-1l3.1-2.5
		C201.2,119.8,201.6,120.4,202,120.9z M203,123l-4,0.6l3.1-2.5C202.5,121.7,202.8,122.3,203,123z M203.4,124.7c0,0.2,0,0.3,0.1,0.5
		l-4.6,0.7l-1.1-0.4c0-0.1,0-0.3,0-0.4s-0.1-0.3-0.1-0.4l0.9-0.7l4.5-0.6C203.2,123.7,203.4,124.2,203.4,124.7z M203.4,127.6l-4-1.6
		l4.2-0.6C203.6,126.1,203.5,126.9,203.4,127.6z M197.8,126c0-0.1,0-0.2,0-0.3l0.6,0.2L197.8,126z M197.5,124l0.6-0.1l-0.5,0.4
		C197.6,124.2,197.6,124.1,197.5,124z M202.7,129.7l-4.5-1.8l-0.7-0.9c0.1-0.2,0.2-0.5,0.3-0.8l1.1-0.2l4.4,1.8
		C203.2,128.5,203,129.1,202.7,129.7z M194.8,134.9c-0.9,0-1.7-0.1-2.5-0.3l1.8-4.5L194.8,134.9z M194.2,129.2l-0.1-0.6
		c0.1,0,0.2,0,0.3,0L194.2,129.2z M199.4,133.5c-0.7,0.4-1.4,0.8-2.2,1l-0.7-4.7L199.4,133.5z M198.4,128.2l4.2,1.7
		c-0.4,0.7-0.8,1.4-1.3,2L198.4,128.2z M197.1,127.5c0.1-0.1,0.1-0.2,0.2-0.3l0.4,0.5L197.1,127.5z M198,128.1l3.1,4
		c-0.5,0.5-1,0.9-1.5,1.3l-3.2-4l-0.2-1.1c0.2-0.1,0.5-0.3,0.7-0.5L198,128.1z M195.7,128.4c0.1,0,0.2-0.1,0.3-0.1l0.1,0.6
		L195.7,128.4z M194.9,122.2l0.2-0.6l0.1,0.6C195.1,122.2,195,122.2,194.9,122.2z M195.1,128.6c0.1,0,0.3-0.1,0.4-0.1l0.7,0.9
		l0.7,5.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.3,0-0.6,0.1-0.8,0.1l-0.7-5.2l0.4-1.1C194.8,128.6,195,128.6,195.1,128.6z M193.1,121.9
		l0.4,0.5c-0.1,0-0.2,0.1-0.3,0.1l0,0L193.1,121.9z M192.2,128.9l0.9-0.7c0.2,0.1,0.5,0.2,0.8,0.3l0.2,1.1l-2,4.9
		c-0.6-0.2-1.3-0.4-1.8-0.8L192.2,128.9z M191.8,129.2l-1.8,4.5c-0.7-0.4-1.4-0.9-1.9-1.5L191.8,129.2z M190.8,127.2l1.1-0.2
		c0.1,0.2,0.3,0.5,0.5,0.7l-0.4,1.1l-4,3.2c-0.4-0.5-0.8-1-1.2-1.6L190.8,127.2z M190.3,127.3l-3.6,2.9c-0.4-0.7-0.6-1.4-0.8-2.2
		L190.3,127.3z M190.4,125l1.1,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.1,0.4l-0.9,0.7l-4.9,0.7c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.5-0.1-1-0.1-1.5L190.4,125z M190,124.9l-4.3,0.6c0-0.8,0.2-1.5,0.4-2.2L190,124.9z M191.5,124.9c0,0.1,0,0.2,0,0.3
		l-0.6-0.2L191.5,124.9z M191.8,126.8l-0.6,0.1l0.5-0.4C191.7,126.6,191.7,126.7,191.8,126.8z M191.7,123.2c0.1,0.2,0.2,0.3,0.3,0.4
		l0,0l-0.4-0.5L191.7,123.2z M192.9,128.1l-0.5,0.4l0.2-0.6C192.7,128,192.8,128,192.9,128.1z M191.7,120l1.2,1.5l0.1,0.5L191.7,120
		z M187,121.1c0.4-0.6,0.8-1.1,1.3-1.6l2.5,3.2L187,121.1z M191.1,122.9l0.7,0.9c-0.1,0.2-0.2,0.5-0.3,0.8l-1.1,0.2l-4.3-1.7
		c0.2-0.6,0.5-1.2,0.8-1.8L191.1,122.9z M166.9,117.3c0.1-0.1,0.2-0.3,0.4-0.4c0.1,0.3,0.2,0.6,0.3,0.8
		C167.3,117.7,167.1,117.5,166.9,117.3z M166.7,117.1c0.1-0.1,0.3-0.3,0.5-0.5l0,0.1l0,0c-0.2,0.1-0.4,0.3-0.5,0.4L166.7,117.1z
		 M166.6,117.1c-0.2-0.2-0.4-0.4-0.4-0.5c0.1-0.1,0.4-0.4,0.7-0.5c0.1,0.2,0.2,0.4,0.3,0.5C166.9,116.7,166.7,117,166.6,117.1z
		 M248,84.4c-7.5-9.3-34-22.3-34-22.3l-32.4-17.5L166.5,34l-26.5-13.4l-5.9,4.9L111.9,3.7L85.3,30.6L76.8,30L56.9,48.9l-4.3-3.5
		L30.7,69.6L2.2,93.5v5.1c2.9-2.5,12.6-10.8,13.3-11.5c1.8-1.6,20.1-17.2,21.1-17.9c1-0.6,15-19.7,15-19.7l5.3,2.6l20.5-19.2l7.2,2
		c0,0,25.5-29.4,26.9-28.9c1.4,0.5-8.2,18.5-8.6,19.3c-0.4,0.8-4.1,8-4.1,8l4.3,1.4L88.8,55.9l25.9-20.3l9.2,1.5l16-14l-1.8,9.1
		L137,46.3l-11.3,8c0,0-5.5,13.1-8.7,23l14.7-2.1c0,0,16.5-7.7,22.4-9c7-1.5,24.6,0.9,37.4,0.6l0.1,0c20.3,2.8,50.3,11.3,53.1,26.6
		c1.6,8.8-14.2,19.3-14.2,19.3s-6.8,12.5-14.2,14.7c-6.7,2.1-10.7,3.9-13.2,5.3c1.8-2.3,2.7-5.2,2.3-8.3c-0.9-6-6.4-10.1-12.3-9.2
		c-1,0.1-1.9,0.4-2.8,0.8c-0.1-0.4-0.2-0.8-0.3-0.9c0,0-0.1,0-0.1,0c-0.1-0.5-0.4-1.5-0.8-2.3c-0.6-1-0.6-2.2-0.4-3
		c0.1-0.4,0.2-0.8,0.2-1.1c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.4-0.2-0.7-0.3-0.9c0-0.1,0.1-0.1,0-0.2c0-0.1-0.6-0.4-0.6-0.5
		c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1-0.3-0.2-0.2-0.3c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.2
		c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.9-0.7-0.9-0.7l-0.2,0c0,0-4.2-4.8-4.7-5.2c-0.5-0.4-1.5-1-2.2-1.7
		c-0.6-0.6-1.9-3-2.5-3.5c-0.6-0.6-1-1-1.2-1.1c-0.2-0.1,0-0.3,0.1-0.4c0-0.1,0-0.3,0.2-0.6c0.2-0.2,0.5-0.6,1-0.6
		c0.5,0,0.9,0,1.1-0.3c0.2-0.3,0-0.6,0.1-0.8c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.1-0.4-0.1-0.6
		c-0.1-0.2,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.3,0.1-0.5c-0.2-0.2-0.5-0.7-0.5-1.1c-0.1-0.4-0.1-0.7-0.1-0.7s0.9-0.5,1-0.6
		c0.2-0.1,0.6-0.2,0.6-0.3c0-0.1-0.6-0.6-1.2-1.1c-0.6-0.5-2-1.6-3.7-1.5c-1.7,0.1-3.7,1.2-3.8,1.4c-0.1,0.2,0.1,0.6-0.1,0.8
		c-0.2,0.3-0.2,0.7-0.2,0.9c0,0.3,0.1,0.5,0,0.9c-0.2,0.4-0.1,0.8,0.1,1c0.2,0.2,0.4,0.5,0.5,0.5c0.1,0,0.4-0.1,0.5,0.2
		c0.1,0.3,0.3,0.7,0.2,0.8c-0.1,0.1-0.1,0.4-0.3,0.5c-0.2,0.1-1,0.3-1.2,0.7c-0.1,0.2-0.4,0.5-0.9,0.9c-0.5,0.2-1.6-0.3-1.9-0.5
		c-0.6,0.1-0.9,0.7-1.4,0.6c-0.1-0.4,0.1-0.6,0-1c-0.5-0.7-1.5,0.2-2.1-0.2c-0.3,0.2-0.6,0.6-1,0.8c-0.5,0.2-1.1,0.1-1.2,0.7
		c0,0.5,0.3,0.6,0.4,1c-0.3,0.3-0.5,0-0.8,0.1c-0.2,0.2-0.2,0.5-0.4,0.7c-0.2,0-0.1-0.4-0.3-0.3c-0.4,0.3-0.7,0.7-1,1.1
		c0,0.2,0.1,0.2,0.1,0.3c-0.1,0.5-0.5,0.8-0.7,1.2c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.5-0.5,0.9-0.3,1.6c-0.5,0.7,0.6,2.1,0.7,2.7
		c0,0.2-0.2,0.4-0.1,0.5c0,1.2,0.9,2.2,1.7,2.6c-0.4,1.4-0.4,2.5-0.4,2.5l0.3,0.2c0,0-0.1,1.1,0.1,1.9c0.2,0.8,1,1.6,2.1,1.9
		c1.1,0.3,2.2,0.7,2.4,0.8c0.2,0.2,1.5,1.4,1.8,1.8c0.3,0.5,1.2,2,1.2,2.1c0,0.1-0.2,0.4-0.2,0.4l0.4,0.2l0,0
		c-0.2,0.1-0.8,0.5-0.8,0.6c0,0.1,0.2,0.4,0.4,0.4c0.2,0.2,0.8,1.1,1.6,1.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0-0.3,0.1-0.4,0.2
		c-0.5,0.2-0.8,0.8-1,1.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.2-0.5,1-0.7,1.3c-1.9-1-4.2-1.4-6.6-1.1c-6,0.9-10.1,6.4-9.2,12.3
		c0.6,4.1,3.3,7.3,6.9,8.6H150l-7.5,2.3l-6.7,0.9l-0.2,0l-0.1,0l-3.9,0.6l-3.1,0.1l-4-1.1l-2.8,1.1l-2.1,1.6l-0.4,0.1
		c0.3-0.7,0-1.3-0.8-1.6c-0.7-0.3-1.1-1-1.5-1.6c-0.2-0.3-0.5-0.9-0.5-1.2c0-0.2,0.2-0.5,0.3-0.7c0.3-0.7-0.2-1.1-0.8-1.3
		c0.7-0.6,1.5-1.2,2.2-1.8c1-0.8,2-1.6,3.1-2.4c1-0.7,1.9-1.4,2.8-2.2c0.5-0.4,0.9-0.9,1.2-1.4c0.1-0.2,0.8-0.8,0.8-1.1
		c0.2-2.6-2.2-4.4-4-5.8c-0.9-0.7-2-1.4-3-2c-0.6-0.4-1.2-0.7-1.9-1c0.2-0.2-0.1-0.6-0.2-0.8c-0.4-0.9-0.7-2.1-0.4-3.1
		c0.1-0.3,0.2-0.7,0.3-1c0.7-0.4,1.4-0.8,2.1-1.1c0.6-0.3,1.2-0.5,1.8-0.8c0.7-0.3,1.5-0.7,2.2-0.9c0.4-0.1,0.8-0.3,1.2-0.2
		c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.7-0.2,0.9-0.4c0.2-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.4-0.4,0.5-0.6c0.2-0.3,0.2-0.7,0-1
		c-0.2-0.3-0.5-0.7-0.9-0.8c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0.2-0.3,0.1c-0.9-0.5-1.2,0.8-1.6,1.3c-0.5,0.5-1.3,0.7-1.9,1
		c-0.5,0.3-1.1,0.5-1.7,0.7c-0.6,0.1-1.3,0.2-1.9,0.2c-0.3,0-0.6,0-0.9,0c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0-0.1,0.1-0.2
		c0.4-0.3,0.8-0.7,1.2-1.1c0.6-0.6,0.3-1.4,0-2.1c-0.5-1.1-1.5-1.9-2-3c-0.2-0.4-0.2-1.1-0.2-1.7c0.1-0.1,0.2-0.2,0.3-0.2
		c0.4-0.2,0.8-0.2,1.2-0.3c0.7-0.2,1.4,0,2.1-0.2c0.3-0.1,0.5-0.5,0.5-0.8c0-0.3-0.1-1-0.1-1.3l0.1,0c0.2-0.1,0.3-0.2,0.4-0.3l0-0.1
		c-0.1-0.5-0.8-0.5-0.7-1.3c0.1-0.4,0.1-0.8,0.1-1.2c0-0.2,0-0.5-0.1-0.7c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.2
		c0.1-0.1,0.2-0.2,0.4-0.3l0,0c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0.1-0.2,0.1l0.1-0.1c0.1-0.1,0.1-0.4,0.3-0.5v-0.1l-0.1,0l0,0
		c0.1,0,0.1-0.1,0-0.1c-0.3,0-0.4,0.3-0.6,0.5l0,0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.4-0.4c0.1,0,0-0.1,0-0.1l0,0l-0.1,0
		l0,0c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1,0c0,0.2-0.2,0.3-0.3,0.4l-0.1,0c0.1-0.1,0.1-0.1,0.2-0.2
		c0,0,0.1-0.1,0.2-0.2c0.1,0,0-0.1-0.1-0.1l0,0l-0.1,0l-0.1,0l0-0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
		c0-0.1,0,0-0.1-0.1l0,0h0c-0.4-0.4-1.7-0.6-2.2-0.6c-0.9,0.1-1.7,0.4-2.4,0.9c-0.6,0.4-0.8,1.2-1,1.9c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.6-0.1-1.4-0.1-1.8,0.4c-0.3,0.4-0.4,0.5-0.5,1c-0.1,0.2-0.1,0.8,0,1c0.4,0.6,0.8,1.3,1.6,1.4c0.2,0,0.5,0,0.8,0
		c0,0.2,0,0.3,0,0.5c0,0.3-0.2,0.6-0.4,0.9c-0.4,0-0.8,0.2-1.2,0.4c-0.3,0.2-0.7,0.3-1,0.5c-0.4,0.2-0.7,0.4-1,0.6
		c-1.4,0.8-2.7,1.8-4,2.8c-0.4,0.3-1.2,0.7-1.2,1.4c0,0.6,0.9,1.1,1.3,1.4c1.2,0.8,2.4,1.5,3.5,2.4c0,0.2,0.1,0.5,0,0.7
		c-0.1,0.6-0.3,1.3-0.5,1.9c-0.3,0.9-0.6,1.8-0.4,2.8c0.2,0.8,0.3,1.5-0.2,2.2c-0.2,0.2-0.6,0.7-0.5,1c0.1,0.3,0.2,0.4,0.5,0.5
		c0.3,0.1,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0,0.2c-0.1,0.6-0.2,1.2-0.4,1.7c-0.3,0.4-0.7,0.7-1,1.2c-0.3,0.4-0.7,0.9-0.9,1.4
		c-0.2,0.4-0.5,0.7-0.6,1.1c-0.2,1-0.6,2.1-1,3c-0.2,0.6-0.7,0.9-1.1,1.4c-0.1,0-0.1,0-0.2,0.1c-1,0.4-2.3,1.1-2.8,2
		c-0.6,1.1-0.9,2.3-1.5,3.3c-0.6,1-1.3,2-2.2,2.7c-0.9-0.1-1.5,0.4-2.3,0.8c-0.8,0.4-1.2,1.3-1.1,2.1c0,0.4,0.5,0.7,0.8,0.9
		c0.4,0.4,0.6,0.9,1,1.3c0.7,0.8,1.4,1.6,2.3,2.3l0,0l-9.2,1.3l-5.8-2c0,0-3.4,0.6-5.1,2.3c-1.7,1.7-9.5,0.8-9.7,1c0,0,0-0.1,0-0.1
		l0-0.8c0-0.1,0-0.1,0-0.2c-0.1-0.3-2.5-6.5-3.8-7.8l-0.1-0.1l-1.3-0.8l0.1-1.1c0-0.1,0-0.3-0.1-0.3l-1.1-1.1l-0.7-1.4l-0.1-0.6
		v-2.7c0.7-0.6,1.5-1.4,1.4-2.3c0-0.1,0-0.2-0.1-0.3l-0.7-0.9c-0.1-0.2-0.4-0.2-0.6-0.1V119l0,0c0.2,0,0.3-0.1,0.4-0.2l1.3-2.3
		c0.1-0.2,0.1-0.4-0.1-0.5l-1.4-1.6c-0.1-0.2-0.4-0.2-0.6-0.1c0,0-1,0.5-2.5,0.5c-0.9,0-1.8-0.2-2.7-0.5l-0.6-0.5
		c-0.1-0.1-0.2-0.1-0.3-0.1h-0.9l-1.8-0.7l-0.7-7.5c0.4-0.5,1.4-1.8,1.7-2.8c0-0.2,0-0.4-0.2-0.5l-0.8-0.5v-0.5h0.3
		c0.3,0,0.5-0.2,0.5-0.5c0-0.1-0.1-0.2-0.1-0.3l-0.6-1.1l0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-2.2-1.3-3.6
		c-0.7-0.7-1.7-1.1-2.9-1.1c-1.1,0-2,0.4-2.7,1.1c-1,1.2-1,3.1-0.9,3.8l-1.4,0.9c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.2
		c-0.5-1.5-1.8-4.5-3.9-4.5h0c-2.1,0.1-3.6,0.6-4.5,1.6c-0.7,0.8-1.1,1.9-1,3.3l-1,1c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1-0.2,2.1,0.8,3.4c-0.5,1.1-2,5.4-1,11.1c0.6,2.8,1.7,6.6,5,6.6c0.5,0,1.1-0.1,1.7-0.3c0.1,1,0.4,2.8,1.6,3.5l-0.4,7l-0.9,1.2
		c0,0.1-0.1,0.1-0.1,0.2l-2.3,9.8l-0.7,0.9c0,0-0.1,0.1-0.1,0.2l-0.8,2.9c0,0.1,0,0.1,0,0.2l0.2,1.3l0,0.5l-0.6,0.5l-2.1,0.2
		c-0.1,0-0.2,0-0.2,0.1l-2.6,1.7c-1-0.4-2.9-1.1-3.4-1.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-1.4,0.5-2.5,0.9l-1.7-0.3l-1.6-0.6
		c-0.2-0.1-0.3,0-0.5,0.1l-3,2.4c-0.1,0.1-0.1,0.2-0.2,0.3l-1,4.3l-0.9,1.8c0,0,0,0.1,0,0.2c-4.5,1.5-7.6,3-8.1,4.6
		c0,0,9.1-1.7,13-1.1c3.6,0.5,8.7-2.8,11.1-3.4l0.6-0.1c1.9,0.1,21.7-1.1,21.7-1.1l5.1-2.7l6.7-1c3,0.7,7.2,1.5,8.6,0.8
		c1.3-0.7,4.1-2.1,6.8-3l5.1-0.7c2.9,0.9,6.2,0.3,6.8,0.3c0.6,0-0.6,2.3,11.3-0.6c11.9-2.8,22.1,1.1,23.2-1.1
		c1.1-2.3,18.1-2.8,18.1-2.8s9.1-1.1,11.3-1.7c2.3-0.6,13.6-1.7,15.3-0.6c1.7,1.1,15.9-1.1,15.9-1.1s12.7-5.7,21.1-9.8l0.4-0.1
		c0.1-0.1,0.2-0.2,0.3-0.3c3.7-1.8,6.3-3.2,6.5-3.5c0.6-1.1,15.3-2.8,15.3-4S267.8,108.7,248,84.4z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TTTIcon;
