import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { includes, remove } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { COUNTRIES, COUNTRIES_BY_CCA } from 'src/common/constants/countries';
import { ICountry } from 'src/interfaces/Country';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';

const move = (arr: ICountry[], items: string[]) => {
  const itemsToMove: ICountry[] = remove(arr, i => includes(items, i.cca2));
  return [...itemsToMove, ...arr];
};

const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
  let newOptions: string[] = [];
  options.forEach(option => {
    const country = COUNTRIES_BY_CCA[option];
    if (
      country.name.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase()) ||
      country.nativeName.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase()) ||
      country.altSpellings.join(' ').toLowerCase().includes(state.inputValue.toLowerCase())
    )
      newOptions.push(country.cca2);
  });
  return newOptions;
};

export interface CountrySelectProps
  extends Omit<
    AutocompleteProps<string, false, false, false>,
    | 'options'
    | 'filterOptions'
    | 'autoHighlight'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderInput'
  > {
  preferredCountries?: string[];
  textFieldProps: TextFieldProps;
}

export const CountrySelect = ({
  preferredCountries = [],
  textFieldProps: { inputProps, sx, ...textFieldProps },
  ...autocompleteProps
}: CountrySelectProps) => {
  const { t } = useTranslation();
  const orderedCountries = move([...COUNTRIES], preferredCountries);
  const options = orderedCountries.map(c => c.cca2);

  return (
    <Autocomplete<string>
      {...autocompleteProps}
      options={options}
      filterOptions={filterOptions}
      autoHighlight
      getOptionLabel={option => t(`base.countries.${option}`) || ''}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
            alt={option.toLowerCase()}
          />
          {t(`base.countries.${option}`)} ({option})
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
          sx={{
            ...disableAutofillBg,
            ...sx
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            ...inputProps,
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
