import { MouseEvent, useEffect, useState } from 'react';

import LayersClearIcon from '@mui/icons-material/LayersClear';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import ShutterSpeedTwoToneIcon from '@mui/icons-material/ShutterSpeedTwoTone';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import find from 'lodash-es/find';
import map from 'lodash-es/map';
import orderBy from 'lodash-es/orderBy';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import 'moment/locale/hu';
import 'moment/locale/ro';

import PageBox from 'src/common/components/PageBox';
import AppBar from 'src/common/components/styled/AppBar';
import { LanguageKeys } from 'src/common/constants/languages';
import { checkForAllWords } from 'src/helpers/search';
import {
  ECheckinFilters,
  ECheckinTimerType,
  IBulkCheckinTimer,
  ICheckin,
} from 'src/interfaces/Checkin';
import { ICompetition } from 'src/interfaces/Competition';
import { EOrder } from 'src/interfaces/Order';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { EView } from 'src/interfaces/View';
import { SER } from 'src/pages/Dashboard/Applicants/constants/applicantSortKeys';
import CompetitionFilterForm from 'src/pages/Dashboard/Competitions/forms/CompetitionFilterForm';
import CompetitionTable from 'src/pages/Dashboard/Competitions/tables/CompetitionTable';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import CheckinCard from './CheckinCard';

export interface Props {
  checkins: ICheckin[];
  competitionId: string;
  distanceId: string;
  onClickBulkTimer: (payload: Omit<IBulkCheckinTimer, 'editionId'>) => void;
  onClickTimer: (id: string, type: ECheckinTimerType, timeStamp?: Date) => void;
  onDeleteCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
  onEditCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
  onResetCounter: (distanceId: string, e?: MouseEvent<unknown>) => void;
  onSetView: (v: EView) => void;
  view: EView;
}

const CheckinDistance = ({
  checkins = [],
  competitionId,
  distanceId,
  onClickBulkTimer,
  onClickTimer,
  onDeleteCheckin,
  onEditCheckin,
  onResetCounter,
  onSetView,
  view,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { competitions, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const editStartTimerMenu = usePopupState({
    variant: 'popover',
    popupId: 'edit-start-timer-menu',
  });
  const editFinishTimerMenu = usePopupState({
    variant: 'popover',
    popupId: 'edit-stop-timer-menu',
  });

  const [isFiltering, setIsFiltering] = useState(false);
  const [timerStartedFilter, setTimerStartedFilter] = useState<string | null>(null);
  const [timerFinishedFilter, setTimerFinishedFilter] = useState<string | null>(null);
  const [query, setQuery] = useState<string>('');
  const [order, setOrder] = useState<EOrder>(EOrder.asc);
  const [orderByKey, setOrderByKey] = useState<string>(SER);
  const [sortedCheckins, setSortedCheckins] = useState<ICheckin[]>(checkins);
  const [selectedCheckinIds, setSelectedCheckinIds] = useState<string[]>(checkins.map(c => c.id));

  const competition = find(competitions, c => c.id === competitionId) as ICompetition;
  const competitionName = competition.i18n[currentLang];

  const distance = find(distances, d => d.id === distanceId) as TechnicalType;
  const distanceName = distance.i18n[currentLang];

  useEffect(() => {
    setIsFiltering(true);
    const tempSortedCheckins = orderBy(
      checkins
        .filter(
          c =>
            checkForAllWords(query, `${c.contestant.firstName}${c.contestant.lastName}`) ||
            !!checkForAllWords(query, c.contestant.email) ||
            !!checkForAllWords(query, c.contestant.phoneNumber),
        )
        .filter(c =>
          !!timerStartedFilter
            ? timerStartedFilter === 'true'
              ? !!c.startTime
              : timerStartedFilter === 'false'
              ? !c.startTime
              : true
            : true,
        )
        .filter(c =>
          !!timerFinishedFilter
            ? timerFinishedFilter === 'true'
              ? !!c.finishTime
              : timerFinishedFilter === 'false'
              ? !c.finishTime
              : true
            : true,
        ),
      orderByKey,
      order,
    );
    setSortedCheckins(tempSortedCheckins);
    setSelectedCheckinIds(tempSortedCheckins.map(c => c.id));
    setIsFiltering(false);
  }, [checkins, order, orderByKey, query, timerFinishedFilter, timerStartedFilter]);

  const onChangeFilters = (key: ECheckinFilters, value: string | null) => {
    switch (key) {
      case ECheckinFilters.timerStarted:
        setTimerStartedFilter(value);
        break;
      case ECheckinFilters.timerFinished:
        setTimerFinishedFilter(value);
        break;
      default:
        break;
    }
  };

  const handleBulkTimerClick = (type: ECheckinTimerType, timeStamp?: Date) => {
    onClickBulkTimer({
      id: competitionId,
      distanceId,
      type,
      checkinIds: selectedCheckinIds,
      timeStamp,
    });
  };

  const startTimeValue = moment();

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {`${t('checkins.header', {
                  competition: competitionName,
                  distance: distanceName,
                })} (${sortedCheckins.length})`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              {!!sortedCheckins.length ? (
                <>
                  <Tooltip title={t('timers.startAllTimers')}>
                    <IconButton
                      aria-label={t('timers.startAllTimers')}
                      onClick={() => handleBulkTimerClick(ECheckinTimerType.start)}
                      edge="end"
                    >
                      <TimerIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('timers.editAllStartTimers')}
                    {...bindTrigger(editStartTimerMenu)}
                  >
                    <IconButton aria-label={t('timers.editAllStartTimers')} edge="end">
                      <TimerTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    {...bindMenu(editStartTimerMenu)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                      <MobileDateTimePicker
                        ampm={false}
                        openTo="hours"
                        value={startTimeValue}
                        onChange={newValue => {
                          handleBulkTimerClick(
                            ECheckinTimerType.start,
                            (newValue?.toDate() as Date) || undefined,
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>

                  <Tooltip title={t('timers.stopAllTimers')}>
                    <IconButton
                      aria-label={t('timers.stopAllTimers')}
                      onClick={() => handleBulkTimerClick(ECheckinTimerType.finish)}
                      edge="end"
                    >
                      <ShutterSpeedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('timers.editAllFinishTimers')}
                    {...bindTrigger(editFinishTimerMenu)}
                  >
                    <IconButton aria-label={t('timers.editAllFinishTimers')} edge="end">
                      <ShutterSpeedTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    {...bindMenu(editFinishTimerMenu)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                      <MobileDateTimePicker
                        ampm={false}
                        openTo="hours"
                        value={startTimeValue}
                        onChange={newValue => {
                          handleBulkTimerClick(
                            ECheckinTimerType.finish,
                            (newValue?.toDate() as Date) || undefined,
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>

                  <Tooltip title={t('timers.resetAllTimers')}>
                    <IconButton
                      aria-label={t('timers.resetAllTimers')}
                      onClick={() => handleBulkTimerClick(ECheckinTimerType.clear)}
                      edge="end"
                    >
                      <TimerOffIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
              <Tooltip title={t('counters.resetAllCounters')}>
                <IconButton onClick={e => onResetCounter(distanceId, e)}>
                  <LayersClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CompetitionFilterForm
                filter={query}
                onChange={onChangeFilters}
                onSetView={onSetView}
                order={order}
                orderByKey={orderByKey}
                setFilter={setQuery}
                setOrder={setOrder}
                setOrderByKey={setOrderByKey}
                timerFinishedFilter={timerFinishedFilter}
                timerStartedFilter={timerStartedFilter}
                view={view}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isFiltering}>
        {sortedCheckins.length ? (
          <>
            {view === EView.grid ? (
              <Grid container spacing={2}>
                {map(sortedCheckins, checkin => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={checkin.id}>
                    <CheckinCard
                      checkin={checkin}
                      onClickTimer={onClickTimer}
                      onDeleteCheckin={onDeleteCheckin}
                      onEditCheckin={onEditCheckin}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {view === EView.table ? (
              <CompetitionTable
                checkins={sortedCheckins}
                distanceId={distanceId}
                onClickTimer={onClickTimer}
                onDeleteCheckin={onDeleteCheckin}
                onEditCheckin={onEditCheckin}
              />
            ) : null}
          </>
        ) : (
          <Paper
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(3px)',
              width: '100%',
              mb: 2,
              mt: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" component="div" sx={{ mr: 1 }}>
              {t('checkins.noCheckinsMessage', {
                competition: competition.i18n[currentLang],
                distance: distance.i18n[currentLang],
              })}
            </Typography>
          </Paper>
        )}
      </PageBox>
    </>
  );
};

export default CheckinDistance;
