import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import usePageContentsStore from 'src/stores/pageContentsStore';
import Home from './Home';

export interface ContactRouteParams {
  competition: string;
  year: string;
}

const ContactResolver = () => {
  const { fetchPageContents: doFetchPageContents } = usePageContentsStore(
    pageContentsStore => pageContentsStore,
  );

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchPageContents()];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Home} />;
};

export default ContactResolver;
