import { styled } from '@mui/material/styles';
import { MapContainerProps, MapContainer as RLMapContainer } from 'react-leaflet';

type Props = MapContainerProps & {
  width?: string;
  height?: string;
};

export const MapContainer = styled(RLMapContainer)<Props>(
  ({ width = '100%', height = '300px' }) => ({
    width,
    height,
  }),
);
