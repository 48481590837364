import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const StopStopwatchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M13.4,8.4c-1.3-0.6-2.5-0.4-3.7,0.4C9.6,8.9,9.5,9.1,9.6,9.3s0.4,0.2,0.5,0.1c0.9-0.7,1.9-0.7,2.9-0.3
		c1.3,0.6,2.2,2,2.3,3.4c0.1,0.8-0.2,1.9-1.6,2.8c-1.5,0.9-2.8,0.9-3.7,0c-1-1-1.5-3.4-0.4-5.3c0.1-0.3,0-0.5-0.1-0.7
		C9.4,9.3,9.2,9.4,9,9.5c-1.2,2.1-1,4.8,0.4,6.2c0.6,0.6,1.3,0.9,2.2,0.9c0.7,0,1.6-0.2,2.4-0.7c1.3-0.8,2-2,1.9-3.5
		C16,10.7,14.9,9.1,13.4,8.4z"
        fill="currentColor"
      />
      <path
        d="M11.1,7.9c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.3
		c-0.4,0-0.7,0-1.1,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.4,0.4S11,7.9,11.1,7.9z"
        fill="currentColor"
      />
      <path
        d="M12.3,9.8c0-0.2-0.1-0.4-0.4-0.4s-0.4,0.1-0.4,0.4c0.1,1.3,0.1,2.1,0,2.5c0,0.4,0,0.7,0.1,0.8c0.1,0.1,0.1,0.1,0.2,0.1
		s0.1,0,0.1,0c0.1-0.1,0.3-0.3,0.2-0.4c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0-0.3C12.4,12,12.4,11.1,12.3,9.8z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M19.5,9.3c0-0.3-0.1-0.4-2.3-2.7c-0.6-0.6-1.4-1.4-1.5-1.5c-0.2-0.5-0.7-0.5-3.3-0.4c-0.3,0-0.5,0-0.6,0
		c-0.3,0-1.2,0-2.6-0.1c-0.1,0-0.3,0-0.4,0.1c-3,3-3.6,3.4-3.7,3.5c0,0.1-0.1,0.3-0.2,0.4c-0.3,0-0.5,0.2-0.5,0.5v5.4
		c0,0.4,4.1,4.8,4.5,4.8c0,0,0.2,0,0.4,0c0.6,0,1.9-0.2,2.4-0.2c0.3,0,1.1,0,3.3,0.3l0,0c0.1,0,0.3-0.1,0.4-0.2l1-1.2
		c1.2-1.4,1.9-2.1,2-2.3c0.1-0.1,0.3-0.3,1-0.9c0.1-0.1,0.2-0.2,0.2-0.4C19.6,11.2,19.5,9.5,19.5,9.3z M17.7,15.1
		c-0.1,0.1-0.8,0.9-2,2.3l-0.9,1c-1.6-0.2-2.7-0.2-3.1-0.2c-0.5,0-1.8,0.1-2.4,0.2c-0.1,0-0.1,0-0.2,0c-0.6-0.6-3.3-3.4-3.7-4V9.2
		c0.1,0,0.2,0,0.2,0c0.2-0.1,0.5-0.3,3.8-3.6c1.3,0.1,2.1,0.1,2.4,0.1c0.1,0,0.4,0,0.7,0c0.6,0,2-0.1,2.4,0c0.2,0.2,0.6,0.6,1.6,1.7
		c0.7,0.7,1.8,1.8,2,2.1c0,0.5,0,2.1,0.1,4.9C18,14.8,17.8,15,17.7,15.1z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default StopStopwatchIcon;
