import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu, { MenuProps as MUIMenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { grey } from '@mui/material/colors';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ISubMenuItem } from 'src/interfaces/MenuItem';

interface Props {
  buttonText: string;
  children?: ReactNode;
  id: string;
  items?: ISubMenuItem[];
  MenuProps?: Partial<MUIMenuProps>;
  pageUrl: string;
}

const SubSubPagesDropdown = ({
  buttonText,
  children,
  closeParent,
  id,
  items,
  MenuProps = {},
  pageUrl,
}: Props & {
  closeParent: () => void;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subDropdownState = usePopupState({ variant: 'popover', popupId: id });

  return (
    <>
      <MenuItem
        sx={{
          borderRight: pathname.includes(pageUrl) ? `3px solid ${grey[800]}` : undefined,
          borderLeft: pathname.includes(pageUrl) ? `3px solid ${grey[800]}` : undefined,
          '&:hover': {
            backgroundColor: 'transparent',
            color: grey[800],
          },
        }}
        {...bindTrigger(subDropdownState)}
      >
        {buttonText}
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>
      <Menu
        slotProps={{
          paper: {
            sx: {
              minWidth: '150px',
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              backdropFilter: 'blur(3px)',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...MenuProps}
        {...bindMenu(subDropdownState)}
      >
        {items?.length
          ? items.map(pg => (
              <MenuItem
                key={pg.id}
                sx={{
                  borderRight: pathname.includes(pg.pageUrl) ? `3px solid ${grey[800]}` : undefined,
                  borderLeft: pathname.includes(pg.pageUrl) ? `3px solid ${grey[800]}` : undefined,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: grey[800],
                  },
                }}
                onClick={() => {
                  closeParent();
                  subDropdownState.close();
                  navigate(pg.pageUrl);
                }}
              >
                {pg.pageName ? pg.pageName : t(`drawer.${pg.id}`)}
              </MenuItem>
            ))
          : children}
      </Menu>
    </>
  );
};

const DropdownButton = ({ buttonText, children, id, items, MenuProps = {}, pageUrl }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const mainDropdownState = usePopupState({ variant: 'popover', popupId: id });

  return (
    <>
      <Button
        sx={{
          mx: 4,
          my: 2,
          color: grey[900],
          borderRadius: 0,
          borderBottom: pathname.includes(pageUrl) ? `3px solid ${grey[800]}` : undefined,
          '&:hover': {
            backgroundColor: 'transparent',
            color: grey[800],
          },
        }}
        variant="text"
        {...bindTrigger(mainDropdownState)}
        endIcon={<AddIcon />}
      >
        {buttonText}
      </Button>
      <Menu
        slotProps={{
          paper: {
            sx: {
              minWidth: '150px',
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(3px)',
            },
          },
        }}
        {...MenuProps}
        {...bindMenu(mainDropdownState)}
      >
        {items?.length
          ? items.map(pg =>
              pg.subPages ? (
                <SubSubPagesDropdown
                  key={pg.id}
                  buttonText={pg.pageName ? pg.pageName : t(`drawer.${pg.id}`)}
                  id={pg.id}
                  items={pg.subPages}
                  pageUrl={pg.pageUrl}
                  closeParent={mainDropdownState.close}
                />
              ) : (
                <MenuItem
                  key={pg.id}
                  onClick={() => {
                    mainDropdownState.close();
                    navigate(pg.pageUrl);
                  }}
                >
                  {pg.pageName ? pg.pageName : t(`drawer.${pg.id}`)}
                </MenuItem>
              ),
            )
          : children}
      </Menu>
    </>
  );
};

export default DropdownButton;
