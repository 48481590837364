import { MouseEvent } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import { map } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { IApplicant } from 'src/interfaces/Applicant';
import { EOrder } from 'src/interfaces/Order';
import { THeadCell } from 'src/interfaces/Table';
import useCheckinsStore from 'src/stores/checkinsStore';
import ApplicantTableRow from './ApplicantTableRow';

type ApplicantMainSortKeys = 'firstName' | 'phoneNumber' | 'competition.name' | 'distance.name' | 'ser';

type ApplicantSecondarySortKeys = 'email' | 'city' | 'age' | 'gender' | 'price';

interface Props {
  checkin(id?: string, e?: MouseEvent<unknown>): void;
  applicants: IApplicant[];
  deleteApplicant(id: string, e?: MouseEvent<unknown>): void;
  editApplicant(id?: string, e?: MouseEvent<unknown>): void;
  order?: EOrder;
  orderByKeys?: string;
  sort?<T>(_event: MouseEvent<unknown>, property: T): void;
}

const ApplicantsTable = ({
  checkin,
  applicants,
  deleteApplicant,
  editApplicant,
  order,
  orderByKeys,
  sort,
}: Props) => {
  const { t } = useTranslation();
  const { checkinsById } = useCheckinsStore(checkinsState => checkinsState);

  const headCells: readonly THeadCell<ApplicantMainSortKeys>[] = [
    {
      id: 'ser',
      sortable: false,
      label: t('form.ser'),
      sortProperty: 'ser',
    },
    {
      id: 'name',
      sortable: false,
      label: t('form.name'),
      sortProperty: 'firstName',
    },
    {
      id: 'phoneNumber',
      sortable: false,
      label: t('form.phoneNo'),
      sortProperty: 'phoneNumber',
    },
    {
      id: 'competition',
      sortable: false,
      label: t('form.event'),
      sortProperty: 'competition.name',
    },
    {
      id: 'distance',
      sortable: false,
      label: t('form.distance'),
      sortProperty: 'distance.name',
    },
  ];

  const collapsibleHeadCells: readonly THeadCell<ApplicantSecondarySortKeys>[] = [
    {
      id: 'email',
      sortable: false,
      label: t('form.email'),
      sortProperty: 'email',
    },
    {
      id: 'city',
      sortable: false,
      label: t('form.city'),
      sortProperty: 'city',
    },
    {
      id: 'age',
      sortable: false,
      label: t('form.age'),
      sortProperty: 'age',
    },
    {
      id: 'gender',
      sortable: false,
      label: t('form.gender'),
      sortProperty: 'gender',
    },
    {
      id: 'price',
      sortable: false,
      label: t('checkins.price'),
      sortProperty: 'price',
    },
  ];

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          stickyHeader
          sx={{ minWidth: 750 }}
          aria-label={t('applicants.applicantsTableLabel')}
          size="medium"
        >
          <TableHead<ApplicantMainSortKeys>
            headCells={headCells}
            isCollapsible
            onRequestSort={(e, k) => !!sort && sort<ApplicantMainSortKeys>(e, k)}
            optionsLabel={t('applicants.optionsLabel')}
            order={order}
            orderBy={orderByKeys}
            withOptions
          />
          <TableBody>
            {map(applicants, (applicant, index) => (
              <ApplicantTableRow<ApplicantSecondarySortKeys>
                key={applicant.id}
                applicant={applicant}
                collapsibleTableHeadProps={{
                  headCells: collapsibleHeadCells,
                  order,
                  orderBy: orderByKeys,
                  onRequestSort: (e, k) => !!sort && sort<ApplicantSecondarySortKeys>(e, k),
                }}
                optionElement={
                  <>
                    <Tooltip title={t('applicants.editApplicant')}>
                      <IconButton size="small" onClick={e => editApplicant(applicant.id, e)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('applicants.deleteApplicant')}>
                      <IconButton size="small" onClick={e => deleteApplicant(applicant.id, e)}>
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {applicant.checkedIn && checkinsById ? (
                      <Tooltip title={t('applicants.registeredApplicant')}>
                        <Badge
                          color="secondary"
                          badgeContent={checkinsById[applicant.checkedIn].ser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <TurnedInIcon color="success" />
                        </Badge>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('applicants.registerApplicant')}>
                        <IconButton onClick={e => checkin(applicant.id, e)} color="primary">
                          <TurnedInNotIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ApplicantsTable;
