import { MouseEvent } from 'react';

import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import { EView } from 'src/interfaces/View';
import { useTranslation } from 'react-i18next';

interface Props {
  onSetView: (v: EView) => void;
  view: EView;
}

const ViewSwitch = ({ onSetView, view }: Props) => {
  const { t } = useTranslation();
  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={view}
      exclusive
      onChange={(_e: MouseEvent<HTMLElement>, newView: EView) => {
        // TODO toggle
        console.log({ a: view !== newView, view, newView });
        if (newView) onSetView(newView);
      }}
      aria-label={t('viewSwitch.view')}
      sx={{
        borderRadius: 20,
      }}
    >
      <Tooltip title={t('viewSwitch.listView')}>
        <ToggleButton
          value={EView.table}
          selected={view === EView.table}
          aria-label={t('viewSwitch.list')}
          sx={{
            borderRadius: 20,
            pl: 2,
          }}
        >
          <ViewListRoundedIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t('viewSwitch.gridView')}>
        <ToggleButton
          value={EView.grid}
          selected={view === EView.grid}
          aria-label={t('viewSwitch.grid')}
          sx={{
            borderRadius: 20,
            pr: 2,
          }}
        >
          <ViewModuleRoundedIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default ViewSwitch;
