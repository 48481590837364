import { yupResolver } from '@hookform/resolvers/yup';
import SellIcon from '@mui/icons-material/Sell';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, date, number, object, string } from 'yup';

import {
  ControlledRadioField,
  RadioOption,
} from 'src/common/components/form/controlled/ControlledRadioField';
import {
  ControlledSelectField,
  SelectOption,
} from 'src/common/components/form/controlled/ControlledSelectField';
import { LanguageKeys } from 'src/common/constants/languages';
import { IApplicant } from 'src/interfaces/Applicant';
import { IBaseCheckin, ICheckin } from 'src/interfaces/Checkin';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export type IFormCheckin = IBaseCheckin & {
  competition: string;
  contestant: string;
  distance: string;
  edition: string;
};

interface CheckinFormProps {
  handleClose?: () => void;
  handleSave: (checkin: IFormCheckin, id?: string) => void;
  selectedApplicant?: IApplicant;
  selectedCheckin?: ICheckin;
}

const CheckinForm = ({
  handleClose,
  handleSave,
  selectedCheckin,
  selectedApplicant,
}: CheckinFormProps) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { activeEditionId, competitionsById, distancesById, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const checkinSchema: ObjectSchema<IFormCheckin> = object()
    .shape({
      id: string(),
      contestant: string().required(t('form.yup.genericRequired')),
      competition: string().required(t('form.yup.genericRequired')),
      distance: string().required(t('form.yup.genericRequired')),
      edition: string().required(t('form.yup.genericRequired')),
      startTime: date(),
      finishTime: date(),
      duration: number(),
    })
    .required();

  const defaultValues = selectedCheckin
    ? {
        competition: selectedCheckin.competition,
        contestant: selectedCheckin.contestant.id,
        distance: selectedCheckin.distance,
        edition: activeEditionId,
      }
    : selectedApplicant
    ? {
        competition: selectedApplicant.competition,
        contestant: selectedApplicant.id,
        distance: selectedApplicant.distance,
        edition: activeEditionId,
      }
    : {
        edition: activeEditionId,
      };

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue,
  } = useForm<IFormCheckin>({
    resolver: yupResolver(checkinSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormCheckin> = async checkin => {
    handleSave(checkin, selectedCheckin?.id);
  };

  const competition = watch('competition');

  useEffect(() => {
    if (defaultValues.competition !== competition) setValue('distance', '');
  }, [competition, defaultValues.competition, setValue]);

  if (!editionsById || !activeEditionId || !competitionsById || !distancesById) return null;

  const currentEdition = editionsById[activeEditionId];

  const systemOptions: RadioOption[] = currentEdition.competitions.map(comp => ({
    value: comp.competitionId,
    label: competitionsById[comp.competitionId].i18n[currentLang],
  }));

  const distanceOptions: SelectOption[] = [];

  currentEdition.competitions
    .find(c => c.competitionId === competition)
    ?.distances.forEach(dist =>
      distanceOptions.push({
        label: distancesById[dist].i18n[currentLang],
        value: dist,
      }),
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <ControlledRadioField<IFormCheckin>
          control={control}
          margin="dense"
          fullWidth
          label={`${t('checkins.competition')}:`}
          options={systemOptions}
          {...register('competition')}
        />

        <ControlledSelectField<IFormCheckin>
          control={control}
          disabled={!watch('competition')}
          fullWidth
          label={t('form.distance')}
          margin="normal"
          options={distanceOptions}
          withEmptyOption
          {...register('distance')}
        />

        {!!errors
          ? Object.keys(errors).map(key => (
              <FormHelperText key={key} error>
                {t('form.keysWithErrors', { key: t(`form.${key}`) })}
              </FormHelperText>
            ))
          : null}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {selectedApplicant ? (
          <Box display="flex" flexDirection="row">
            <SellIcon color="secondary" sx={{ mr: 1 }} />
            <Typography>{`${selectedApplicant.price} ${t('base.currency')}`}</Typography>
          </Box>
        ) : null}
        <Box>
          {!!handleClose && (
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              {t('form.cancel')}
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary">
            {t('form.save')}
          </Button>
        </Box>
      </DialogActions>
    </form>
  );
};

export default CheckinForm;
