import { styled } from '@mui/material/styles';
import MuiListItemButton from '@mui/material/ListItemButton';


const DrawerListItemButton = styled(MuiListItemButton)(
  ({ theme }) => ({
    '&, & .MuiListItemIcon-root': {
      color: theme.palette.grey[700],
    },

    '&&.Mui-selected': {
      backgroundColor: 'transparent',
      '&, & .MuiListItemIcon-root': {
        color: theme.palette.grey[100],
      },
    },
    '&&:hover, &&.Mui-selected:hover': {
      backgroundColor: 'transparent',
      '&, & .MuiListItemIcon-root': {
        color: theme.palette.grey[200],
      },
    },
  }),
);

export default DrawerListItemButton as typeof MuiListItemButton;
