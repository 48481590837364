import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useTitle from 'react-use/lib/useTitle';

import { SECURE_API } from 'src/api/api';
import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { IForgotPassword } from 'src/interfaces/ForgotPassword';
import ForgotPasswordForm from './forms/ForgotPasswordForm';

export default function ForgotPassword() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const navigate = useNavigate();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('account.forgotPassword.pageTitle')} - ${t('base.defaultTitle')}`);

  useEffect(() => {
    if (!!auth.user) {
      navigate('/dashboard/applicants', { replace: true });
    }
  }, [auth.user, navigate]);

  const handleSubmit = (payload: IForgotPassword) => {
    SECURE_API.post<null>('resetPassword/request', {
      ...payload,
      lang: currentLang,
    })
      .then(() => {
        enqueueSnackbar(t('account.forgotPassword.messages.sendSuccessful'));
      });
  };

  return (
    <PageBox sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Grid item xs={false} sm={4} md={7} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Typography component="h2" variant="h2" mb={4}>
              {t('account.forgotPassword.pageTitle')}
            </Typography>
            <ForgotPasswordForm handleSave={handleSubmit} />
            <Grid container>
              <Grid item xs>
                <Link color="secondary" href="/account/signin" variant="body2">
                  {t('account.forgotPassword.backToSignin')}
                </Link>
              </Grid>
              {/*
              <Grid item>
                <Link color="secondary" href="#" variant="body2">
                  {t('account.signIn.dontHaveAccount')}
                </Link>
              </Grid>
            */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
}
