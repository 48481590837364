import { TextFieldProps } from '@mui/material/TextField';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<T extends FieldValues, C> = UseControllerProps<T> &
  TextFieldProps & {
    control: Control<T, C>;
    defaultCountry?: MuiTelInputCountry;
    name: FieldPath<T>;
    preferredCountries?: MuiTelInputCountry[];
  };

export const ControlledPhoneInput = <T extends FieldValues, C extends unknown = any>({
  name,
  control,
  disabled,
  fullWidth,
  label,
  margin,
  defaultCountry,
  preferredCountries,
}: Props<T, C>) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error, invalid },
  } = useController<T>({
    name,
    control,
  });

  return (
    <MuiTelInput
      inputRef={ref}
      label={label}
      margin={margin}
      onChange={newValue => onChange(newValue as PathValue<T, FieldPath<T>>)}
      disabled={disabled}
      fullWidth={fullWidth}
      defaultCountry={defaultCountry}
      preferredCountries={preferredCountries}
      helperText={error?.message}
      error={invalid}
      onBlur={onBlur}
      value={value}
    />
  );
};

export default ControlledPhoneInput;
