import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const StopIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M20.1,9.3c0-0.3-0.1-0.4-2.3-2.7c-0.6-0.6-1.4-1.4-1.5-1.5c-0.2-0.5-0.7-0.5-3.3-0.4c-0.3,0-0.5,0-0.6,0
		c-0.3,0-1.2,0-2.6-0.1c-0.1,0-0.3,0-0.4,0.1c-3,3-3.6,3.4-3.7,3.5C5.7,8.3,5.6,8.5,5.5,8.6C5.2,8.6,5,8.8,5,9.1v5.4
		c0,0.4,4.1,4.8,4.5,4.8c0,0,0.2,0,0.4,0c0.6,0,1.9-0.2,2.4-0.2c0.3,0,1.1,0,3.3,0.3c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.2L17,18
		c1.2-1.4,1.9-2.1,2-2.3c0.1-0.1,0.3-0.3,1-0.9c0.1-0.1,0.2-0.2,0.2-0.4C20.2,11.2,20.1,9.5,20.1,9.3z M18.3,15.1
		c-0.1,0.1-0.8,0.9-2,2.3l-0.9,1c-1.6-0.2-2.7-0.2-3.1-0.2c-0.5,0-1.8,0.1-2.4,0.2c-0.1,0-0.1,0-0.2,0c-0.6-0.6-3.3-3.4-3.7-4V9.2
		c0.1,0,0.2,0,0.2,0C6.4,9.1,6.7,8.9,10,5.6c1.3,0.1,2.1,0.1,2.4,0.1c0.1,0,0.4,0,0.7,0c0.6,0,2-0.1,2.4,0c0.2,0.2,0.6,0.6,1.6,1.7
		c0.7,0.7,1.8,1.8,2,2.1c0,0.5,0,2.1,0.1,4.9C18.6,14.8,18.4,15,18.3,15.1z"
        fill="currentColor"
      />
    </g>
    <path
      d="M16.7,10.6c-0.5-0.2-1.1,0.2-1.6,0.6c0.2-1.8,0.2-3.2-0.6-3.5c-0.2-0.1-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.1-0.2,0.2
	c-0.1-0.4-0.2-0.7-0.5-0.9C13,6.9,12.7,6.9,12.5,7c-0.5,0.1-0.7,0.6-0.8,1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.5,0-0.7,0.1
	c-0.2,0.2-0.4,0.5-0.4,1C10.1,8.8,10,8.7,9.9,8.7c-0.2,0-0.5,0-0.8,0.4C8.8,9.4,8.9,10,9.1,11.4l0.1,0.8c0.1,0.8,0.1,1.7,0.2,2.5
	c0,0.7,0,1.2,0.1,1.5c0,0.2,0.2,0.4,0.5,0.4c0,0,0.1,0,0.1,0c0.3,0,0.4-0.3,0.4-0.6c-0.1-0.3-0.1-0.8-0.1-1.4c0-0.8,0-1.7-0.2-2.6
	l-0.1-0.8c-0.1-0.4-0.1-0.9-0.2-1.2c0.1,0.2,0.2,0.5,0.3,0.9c0,0.1,0.2,0.5,0.6,0.5c0.2,0,0.4-0.2,0.4-0.5c0,0,0-0.1,0-0.3
	c0-0.7-0.1-1.1,0-1.4c0,0.2,0,0.3,0.1,0.5c0.1,0.7,0.1,1.1,0.5,1.3c0.1,0,0.4,0.1,0.6-0.2c0.3-0.3,0.3-0.9,0.3-1.6
	c0-0.2,0-0.8,0-1.1c0.1,0.3,0.1,0.8,0.1,1c0,0.8,0.1,1.4,0.7,1.4c0.4,0,0.5-0.4,0.6-0.8c0-0.2,0.1-0.5,0.2-0.7c0,0.4,0,1.2-0.1,2.3
	c0,0.3-0.1,0.6-0.1,0.7c0,0.4,0.2,0.5,0.3,0.6c0.4,0.2,0.7-0.1,1.2-0.5c0.2-0.2,0.6-0.5,0.8-0.6c0,0,0,0,0,0.1
	c-0.1,0.2-0.4,0.5-0.6,0.7c-0.4,0.4-0.8,1-1.2,1.5c-0.2,0.3-0.4,0.8-0.6,1.2c-0.1,0.3-0.3,0.6-0.4,0.7c-0.2,0.2-0.1,0.5,0.1,0.7
	c0.2,0.2,0.5,0.1,0.7-0.1c0.2-0.2,0.3-0.5,0.5-1c0.2-0.4,0.4-0.8,0.5-1c0.4-0.5,0.8-1,1.2-1.5c0.7-0.8,0.9-1,0.8-1.3
	C17.2,11.1,17,10.7,16.7,10.6z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default StopIcon;
