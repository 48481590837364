import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, object, string } from 'yup';

import Box from '@mui/material/Box';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import Flag from 'src/common/components/flags/Flag';
import Icon, { Icons } from 'src/common/components/icons/Icon';
import { LanguageKeys } from 'src/common/constants/languages';
import { IBaseTechnicalType } from 'src/interfaces/TechnicalType';
import { ControlledSelectField } from '../components/form/controlled/ControlledSelectField';

export type FormTechnicalType = IBaseTechnicalType & {
  icon?: string;
};

interface TechnicalTypeEditorFormProps {
  handleClose?: () => void;
  handleSave: (editedItem: FormTechnicalType, id?: string) => void;
  item?: FormTechnicalType;
  withIcon?: boolean;
}

const TechnicalTypeEditorForm = ({
  handleSave,
  handleClose,
  item,
  withIcon,
}: TechnicalTypeEditorFormProps) => {
  const { t } = useTranslation();

  const technicalTypeSchema: ObjectSchema<FormTechnicalType> = object()
    .shape({
      id: string(),
      name: string()
        .trim()
        .matches(/^[a-z0-9-]+$/, t('form.yup.lettersNumbersDashesOnly'))
        .required(t('form.yup.genericRequired')),
      i18n: object()
        .shape({
          [LanguageKeys.en]: string().required(t('form.yup.genericRequired')),
          [LanguageKeys.ro]: string().required(t('form.yup.genericRequired')),
          [LanguageKeys.hu]: string().required(t('form.yup.genericRequired')),
        })
        .required(t('form.yup.genericRequired')),
      icon: string(),
    })
    .required();

  const defaultValues = item ? item : {};

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormTechnicalType>({
    resolver: yupResolver(technicalTypeSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormTechnicalType> = async formData => {
    handleSave(formData, item?.id);
  };

  const generateOptions = () => {
    return Object.entries(Icons).map(([k]) => (
      <MenuItem key={k} value={k}>
        <ListItemIcon sx={{ minWidth: 35 }}>
          <Icon icon={k} />
        </ListItemIcon>
        <ListItemText primary={k} />
      </MenuItem>
    ));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('technicalTypes.nameLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['name']}
            helperText={errors['name']?.message}
            {...register('name')}
          />
        </Grid>
        {withIcon ? (
          <Grid item xs={6}>
            <ControlledSelectField<FormTechnicalType>
              control={control}
              name="icon"
              fullWidth
              label={t('form.accommodation')}
              margin="dense"
              renderOptions={generateOptions}
              withEmptyOption
              renderValue={v => (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Icon icon={v as string} fontSize="small" />
                  {v as string}
                </Box>
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('technicalTypes.translatedNameLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['i18n']?.[LanguageKeys.hu]}
            helperText={errors['i18n']?.[LanguageKeys.hu]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.hu} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('i18n.hu')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('technicalTypes.translatedNameLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['i18n']?.[LanguageKeys.ro]}
            helperText={errors['i18n']?.[LanguageKeys.ro]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.ro} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('i18n.ro')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('technicalTypes.translatedNameLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['i18n']?.[LanguageKeys.en]}
            helperText={errors['i18n']?.[LanguageKeys.en]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.en} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('i18n.en')}
          />
        </Grid>

        {!!errors ? (
          <Grid item xs={12} textAlign="right">
            {Object.keys(errors).map(key => (
              <FormHelperText key={key} error>
                {t('form.keysWithErrors', { key: t(`form.${key}`) })}
              </FormHelperText>
            ))}
          </Grid>
        ) : null}

        <Grid item xs={12} textAlign="right">
          {!!handleClose && (
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              {t('form.cancel')}
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary">
            {t('form.save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TechnicalTypeEditorForm;
