import { MouseEvent } from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash-es/map';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { IEdition } from 'src/interfaces/Edition';
import { THeadCell } from 'src/interfaces/Table';
import EditionTableRow from './EditionTableRow';

export interface Props {
  activeEditionId?: string;
  editions: IEdition[];
  isLoading?: { [key: string]: boolean };
  selectedEditionId?: string;
  onDeleteEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
  onEditEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
  onSaveEdition: (editedEdition: IEdition, id?: string) => void;
  onSaveStatistics: (editionId: string, e?: MouseEvent<unknown>) => void;
  onSelectEdition: (edition?: IEdition) => void;
  onToggleActiveEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
}

const EditionsTable = ({
  activeEditionId,
  editions,
  isLoading,
  selectedEditionId,
  onDeleteEdition,
  onEditEdition,
  onSaveEdition,
  onSaveStatistics,
  onSelectEdition,
  onToggleActiveEdition,
}: Props) => {
  const { t } = useTranslation();

  const headCells: readonly THeadCell<{}>[] = [
    {
      id: 'year',
      label: t('editions.eventYear'),
      width: '120px',
    },
    {
      id: 'eventDate',
      label: t('editions.eventDate'),
      width: '180px',
    },
    {
      id: 'signupDeadline',
      label: t('editions.signupDeadline'),
      width: '200px',
    },
    {
      id: 'competitions',
      label: t('editions.competitions'),
      width: '180px',
    },
    {
      id: 'totals',
      label: t('editions.totals'),
      width: '180px',
    },
  ];

  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 2, p: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 950 }} aria-label={t('editions.editionsTableLabel')} size="medium">
          <TableHead<{}>
            headCells={headCells}
            optionsCellWidth="140px"
            optionsLabel={t('editions.optionsLabel')}
            withOptions
          />
          <TableBody>
            {map(editions, edition => {
              const isActive = edition.id === activeEditionId;
              const isSelected = edition.id === selectedEditionId;

              const handleToggleCanShowResults = () =>
                onSaveEdition(
                  {
                    ...edition,
                    canShowResults: !edition.canShowResults,
                  },
                  edition.id,
                );
              return (
                <EditionTableRow
                  key={edition.id}
                  isLoading={isLoading ? isLoading[edition.id] : false}
                  edition={edition}
                  optionElement={
                    <>
                      <Tooltip title={t('editions.deleteEdition')}>
                        <IconButton
                          size="small"
                          onClick={e => onDeleteEdition(edition.id, e)}
                          color="error"
                        >
                          <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('editions.editEdition')}>
                        <IconButton size="small" onClick={e => onEditEdition(edition.id, e)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={isSelected ? t('editions.usedEdition') : t('editions.useEdition')}
                      >
                        <IconButton
                          onClick={() => onSelectEdition(isSelected ? undefined : edition)}
                          color={isSelected ? 'primary' : 'secondary'}
                        >
                          {isSelected ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          edition.canShowResults
                            ? t('editions.hideResults')
                            : t('editions.showResults')
                        }
                      >
                        <IconButton
                          onClick={handleToggleCanShowResults}
                          color={edition.canShowResults ? 'primary' : 'secondary'}
                        >
                          {edition.canShowResults ? (
                            <AnalyticsIcon color="primary" />
                          ) : (
                            <AnalyticsOutlinedIcon color="warning" />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('editions.saveStatistics')}>
                        <IconButton
                          onClick={e => onSaveStatistics(edition.id, e)}
                          color={'primary'}
                        >
                          <AddchartIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          isActive ? t('editions.deactivateEdition') : t('editions.activateEdition')
                        }
                      >
                        <IconButton
                          onClick={e => onToggleActiveEdition(edition.id, e)}
                          color={isActive ? 'primary' : 'secondary'}
                        >
                          {isActive ? <ToggleOnIcon /> : <ToggleOffOutlinedIcon />}
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EditionsTable;
