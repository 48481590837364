import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NoteIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M20.5,14.1c0-0.1-0.1-3.4-0.2-6.7c0-1.1,0-1.9,0-2c0-0.1-0.1-0.3-0.1-0.3c-0.2-0.2-0.2-0.2-2.8-0.1c-1.5,0.1-3.3,0.1-4,0.1
	c-0.4,0-1.2,0-2.1,0C5,5.1,4.2,5.1,4,5.2C3.6,5.3,3.6,5.3,3.7,8.7c0,0.9,0,1.9,0,2.9c0,1.2,0,3.2-0.1,5.8l0,0.2
	c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5l0-0.1c0.1-2.6,0.1-4.6,0.1-5.8c0-1,0-2,0-2.9c0-1,0-2.1,0-2.6c1.1,0,4.6,0,6.5,0
	c1,0,1.8,0,2.1,0c0.8,0,2.5-0.1,4.1-0.1c0.7,0,1.5-0.1,1.9-0.1c0,0.4,0,0.9,0,1.5c0.1,2.4,0.2,6.5,0.2,6.7c0,0,0,0.7-0.4,1.5
	c-0.3,0.2-0.9,0.2-1.4,0.1c-0.5-0.1-0.9-0.4-1.3-1c-0.1-0.2-0.4-0.3-0.6-0.1c-0.2,0.1-0.3,0.4-0.2,0.6c0.2,0.5,0.3,1,0.2,1.5
	c-0.2,0.7-0.4,1-0.8,1.3c-0.2,0-0.4,0-0.6,0c-5.4-0.1-9.8,0.1-10,0.1c-0.3,0-0.5,0.3-0.4,0.5c0,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0,0
	c0.2,0,4.5-0.2,9.9-0.1c2,0.1,3.6-0.5,4.7-1.6c0.4-0.4,0.7-0.8,0.9-1.2c0.1-0.1,0.2-0.3,0.3-0.5c0-0.1,0-0.1,0-0.2
	C20.4,14.7,20.5,14.1,20.5,14.1z M16.6,16.9c0-0.2,0.1-0.4,0.1-0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.8,0.1
	c-0.5,0.5-1.2,0.9-2,1.1C16.4,17.5,16.5,17.2,16.6,16.9z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default NoteIcon;
