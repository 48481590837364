import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HashTag = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M21.2,10.6c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3l-0.2-0.3
	c-0.3-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.5-0.7-0.8-1.1l-0.5-0.7c-0.6-0.8-1-1.4-1.6-2.3c-0.1-0.2-0.2-0.3-0.3-0.5
	c-0.1-0.1-0.4-0.2-0.6-0.2c-0.1,0-1.6,0.1-2.6,0.1l-1.4,0.1c-0.3,0-0.5,0-0.8,0c-0.3,0-0.7,0-1,0C9.3,3.9,8.8,3.9,8.2,4
	C8,4,7.8,4.1,7.6,4.1L7.4,4.1c-0.1,0-0.2,0.1-0.2,0.1C7,4.3,6.9,4.3,6.8,4.5c0,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0,0,0.1
	C6.2,5.4,5.7,6.2,5.2,7C5,7.5,4.7,7.9,4.4,8.4c-0.4,0.7-0.8,1.3-1.2,2l-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.3,0,0.5
	c0.1,0.2,0.2,0.4,0.3,0.6l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.3,0.7,0.6,1.3,1,2c0.2,0.3,0.3,0.6,0.4,0.9c0.2,0.4,0.3,0.7,0.5,1.1
	c0.2,0.4,0.5,0.9,0.7,1.3l0.4,0.7c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.3l0.7,0c0.4,0,0.9,0,1.4,0.1l0.4,0
	c0.3,0,0.7,0.1,1,0.1c0.5,0,0.9,0,1.4,0.1c0.4,0,0.7,0,1.1,0.1c0.6,0,1.1,0.1,1.7,0.1c0.3,0,0.6,0,0.9,0.1c0,0,0,0,0.1,0.1
	c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2c0.3-0.5,0.6-1,0.9-1.6l0.2-0.4c0.4-0.6,0.7-1.3,1.2-2.1l0.4-0.7
	c0.3-0.5,0.6-1.1,0.8-1.6c0.4-0.7,0.7-1.5,1-2.2c0.1-0.2,0.2-0.3,0.2-0.5C21.3,10.9,21.2,10.7,21.2,10.6z M20.1,11.1
	C20.1,11.1,20.1,11.1,20.1,11.1c-0.3,0.8-0.7,1.5-1,2.3c-0.3,0.5-0.5,1.1-0.8,1.6L18,15.7c-0.4,0.8-0.8,1.5-1.1,2.1l-0.2,0.4
	c-0.2,0.4-0.5,0.8-0.7,1.2c-0.3,0-0.7,0-1-0.1c-0.6,0-1.1-0.1-1.7-0.1c-0.4,0-0.7-0.1-1.1-0.1c-0.5,0-1,0-1.4-0.1
	c-0.3,0-0.6,0-1-0.1l-0.4,0c-0.5,0-0.9-0.1-1.4-0.1l-0.4,0c0-0.1-0.1-0.2-0.1-0.2C7.2,18.4,7,18.2,6.9,18c-0.2-0.4-0.5-0.9-0.7-1.3
	c-0.2-0.3-0.3-0.7-0.5-1c-0.1-0.3-0.3-0.7-0.5-1.1c-0.3-0.6-0.6-1.2-0.9-1.9c-0.1-0.2-0.1-0.4-0.2-0.6L4,11.8
	c-0.1-0.2-0.1-0.3-0.2-0.4C3.8,11.3,3.9,11.1,4,11l0.1-0.2c0.4-0.7,0.7-1.3,1.1-1.9c0.3-0.5,0.6-1,0.9-1.5C6.5,6.7,7,5.8,7.5,5.1
	c0,0,0,0,0,0L7.8,5C8,5,8.2,5,8.3,4.9C8.9,4.9,9.4,4.8,10,4.8c0.3,0,0.7,0,1,0c0.3,0,0.5,0,0.8,0l1.4-0.1c1.5-0.1,2.2-0.1,2.5-0.1
	c0,0.1,0.1,0.1,0.1,0.2c0.6,0.8,1,1.5,1.6,2.3l0.5,0.7c0.3,0.4,0.5,0.7,0.8,1.1c0.3,0.3,0.5,0.7,0.8,1l0.2,0.3
	c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0.1,0.1c0,0,0,0,0,0C20.2,11,20.2,11.1,20.1,11.1z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default HashTag;
