import { SvgIconProps } from '@mui/material/SvgIcon';

import AgreementIcon from 'src/common/components/icons/Agreement';
import BarGraphIcon from 'src/common/components/icons/BarGraph';
import CancelIcon from 'src/common/components/icons/Cancel';
import CaretDownIcon from 'src/common/components/icons/CaretDown';
import CaretLeftIcon from 'src/common/components/icons/CaretLeft';
import CaretRightIcon from 'src/common/components/icons/CaretRight';
import CaretUpIcon from 'src/common/components/icons/CaretUp';
import CertificateIcon from 'src/common/components/icons/Certificate';
import CheckCircleIcon from 'src/common/components/icons/CheckCircle';
import CheckStopwatchIcon from 'src/common/components/icons/CheckStopwatch';
import ClearStopwatchIcon from 'src/common/components/icons/ClearStopwatch';
import ClockIcon from 'src/common/components/icons/Clock';
import CloseIcon from 'src/common/components/icons/Close';
import CompassIcon from 'src/common/components/icons/Compass';
import CyclistIcon from 'src/common/components/icons/Cyclist';
import FemaleCyclistIcon from 'src/common/components/icons/FemaleCyclist';
import FinishFlagIcon from 'src/common/components/icons/FinishFlag';
import FlagIcon from 'src/common/components/icons/Flag';
import GraphIcon from 'src/common/components/icons/Graph';
import HashTagIcon from 'src/common/components/icons/HashTag';
import HeartIcon from 'src/common/components/icons/Heart';
import HexagonIcon from 'src/common/components/icons/Hexagon';
import HikerIcon from 'src/common/components/icons/Hiker';
import JoyIcon from 'src/common/components/icons/Joy';
import KeyIcon from 'src/common/components/icons/Key';
import LaptopIcon from 'src/common/components/icons/Laptop';
import LocationIcon from 'src/common/components/icons/Location';
import LockIcon from 'src/common/components/icons/Lock';
import MapIcon from 'src/common/components/icons/Map';
import MegaphoneIcon from 'src/common/components/icons/Megaphone';
import NoteIcon from 'src/common/components/icons/Note';
import OutdoorsIcon from 'src/common/components/icons/Outdoors';
import PaperPlaneIcon from 'src/common/components/icons/PaperPlane';
import PenIcon from 'src/common/components/icons/Pen';
import PencilIcon from 'src/common/components/icons/Pencil';
import PieChartIcon from 'src/common/components/icons/PieChart';
import PuzzleIcon from 'src/common/components/icons/Puzzle';
import RunningIcon from 'src/common/components/icons/Running';
import SearchIcon from 'src/common/components/icons/Search';
import SecurityIcon from 'src/common/components/icons/Security';
import ShieldIcon from 'src/common/components/icons/Shield';
import StopIcon from 'src/common/components/icons/Stop';
import StopStopwatchIcon from 'src/common/components/icons/StopStopwatch';
import TargetIcon from 'src/common/components/icons/Target';
import TentIcon from 'src/common/components/icons/Tent';
import TrophyIcon from 'src/common/components/icons/Trophy';
import TttIcon from 'src/common/components/icons/Ttt';
import WinnerIcon from 'src/common/components/icons/Winner';
import WinnersPodiumIcon from 'src/common/components/icons/WinnersPodium';
import WrenchIcon from 'src/common/components/icons/Wrench';
import BearIcon from './Bear';
import BookIcon from './Book';
import DirectionsIcon from './Directions';
import HomeIcon from './Home';
import InfoIcon from './Info';
import SignUpIcon from './SignUp';
import TelekiIcon from './Teleki';
import ThoughtIcon from './Thought';
import TrifoldIcon from './Trifold';
import XMLIcon from './XML';
import PDFIcon from './Pdf';

export const Icons = {
  agreement: AgreementIcon,
  barGraph: BarGraphIcon,
  bear: BearIcon,
  book: BookIcon,
  cancel: CancelIcon,
  caretDown: CaretDownIcon,
  caretLeft: CaretLeftIcon,
  caretRight: CaretRightIcon,
  caretUp: CaretUpIcon,
  certificate: CertificateIcon,
  checkCircle: CheckCircleIcon,
  checkStopwatch: CheckStopwatchIcon,
  clearStopwatch: ClearStopwatchIcon,
  clock: ClockIcon,
  close: CloseIcon,
  compass: CompassIcon,
  cyclist: CyclistIcon,
  directions: DirectionsIcon,
  femaleCyclist: FemaleCyclistIcon,
  finishFlag: FinishFlagIcon,
  flag: FlagIcon,
  graph: GraphIcon,
  hashTag: HashTagIcon,
  heart: HeartIcon,
  hexagon: HexagonIcon,
  hiker: HikerIcon,
  home: HomeIcon,
  info: InfoIcon,
  joy: JoyIcon,
  key: KeyIcon,
  laptop: LaptopIcon,
  location: LocationIcon,
  lock: LockIcon,
  map: MapIcon,
  megaphone: MegaphoneIcon,
  note: NoteIcon,
  outdoors: OutdoorsIcon,
  paperPlane: PaperPlaneIcon,
  pdf: PDFIcon,
  pen: PenIcon,
  pencil: PencilIcon,
  pieChart: PieChartIcon,
  puzzle: PuzzleIcon,
  running: RunningIcon,
  search: SearchIcon,
  security: SecurityIcon,
  shield: ShieldIcon,
  signUp: SignUpIcon,
  stop: StopIcon,
  stopStopwatch: StopStopwatchIcon,
  target: TargetIcon,
  teleki: TelekiIcon,
  tent: TentIcon,
  thought: ThoughtIcon,
  trifold: TrophyIcon,
  trophy: TrifoldIcon,
  ttt: TttIcon,
  winner: WinnerIcon,
  winnersPodium: WinnersPodiumIcon,
  wrench: WrenchIcon,
  xml: XMLIcon,
};

export type IconsType = typeof Icons;

export type IconProps = SvgIconProps & {
  icon: string;
};

const Icon = ({ icon, ...props }: IconProps) => {
  const TheIcon = Icons[icon as keyof IconsType];
  return TheIcon ? <TheIcon {...props} /> : <WrenchIcon {...props} />;
};

export default Icon;
