import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import mapKeys from 'lodash-es/mapKeys';
import omit from 'lodash-es/omit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSetState from 'react-use/lib/useSetState';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import EditPageFab from 'src/common/components/EditPageFab';
import Editor from 'src/common/components/Editor';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { parseTinyMCEContent } from 'src/helpers/parseTinyMCEContent';
import { IPageContent } from 'src/interfaces/PageContent';
import usePageContentsStore from 'src/stores/pageContentsStore';

export default function Contact() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { isAdmin } = useAuth();
  const currentLang = resolvedLanguage as LanguageKeys;

  const {
    isLoading: isLoadingPageContent,
    pageContentsByName,
    savePageContent,
  } = usePageContentsStore(pageContentsStore => pageContentsStore);

  const contactPageContent: IPageContent = pageContentsByName?.contact as IPageContent;
  const contactPageContentSectionsByName = mapKeys(contactPageContent.content, 'name');

  const [content, setContent] = useSetState(contactPageContentSectionsByName.main.i18n);

  const [selectedEditorLang, setSelectedEditorLang] = useState(currentLang);
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    if (isEditMode) {
      setContent(contactPageContentSectionsByName.main.i18n);
    }
    setIsEditMode(m => !m);
  };

  const updatePageContent = async () => {
    const newContent = {
      ...omit(contactPageContent, ['createdAt', 'updatedAt']),
      content: [
        {
          ...contactPageContentSectionsByName.main,
          name: 'main',
          i18n: { ...content },
        },
      ],
    };

    await savePageContent(newContent, contactPageContent.id);

    setIsEditMode(false);
  };

  useTitle(`${t('contact.pageTitle')} - ${t('base.defaultTitle')}`);

  return (
    <PageBox sx={{ pt: 4, pb: 4 }} isLoading={isLoadingPageContent}>
      <Grid container>
        <Grid item xs={false} sm={3} md={6} />
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
            }}
          >
            <Typography component="h2" variant="h2" mb={4}>
              {t('contact.pageTitle')}
            </Typography>

            {isEditMode ? (
              <Editor
                onChange={newValue => setContent({ [selectedEditorLang]: newValue })}
                value={content[selectedEditorLang]}
              />
            ) : (
              parseTinyMCEContent(content[currentLang])
            )}
          </Box>
        </Grid>
      </Grid>

      {isAdmin ? (
        <EditPageFab
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          save={updatePageContent}
          selectedLang={selectedEditorLang}
          switchLang={setSelectedEditorLang}
          position={{ position: 'fixed', bottom: 70, right: 16 }}
        />
      ) : null}
    </PageBox>
  );
}
