import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import mapKeys from 'lodash-es/mapKeys';
import omit from 'lodash-es/omit';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSetState from 'react-use/lib/useSetState';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import EditPageFab from 'src/common/components/EditPageFab';
import Editor from 'src/common/components/Editor';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { parseTinyMCEContent } from 'src/helpers/parseTinyMCEContent';
import { IPageContent } from 'src/interfaces/PageContent';
import ApplicantEditorForm, {
  IFormApplicant,
} from 'src/pages/Dashboard/Applicants/forms/ApplicantEditorForm';
import useApplicantsStore from 'src/stores/applicantsStore';
import usePageContentsStore from 'src/stores/pageContentsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import BasicModal from 'src/pages/Dashboard/Applicants/modals/BasicModal';

export default function SignUp() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { isAdmin } = useAuth();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { saveApplicant: doSaveApplicant } = useApplicantsStore(applicantsState => applicantsState);
  const {
    isLoading: isLoadingPageContent,
    pageContentsByName,
    savePageContent,
  } = usePageContentsStore(pageContentsStore => pageContentsStore);

  const signupPageContent: IPageContent = pageContentsByName?.signup as IPageContent;
  const signupPageContentSectionsByName = mapKeys(signupPageContent.content, 'name');

  const [content, setContent] = useSetState(signupPageContentSectionsByName.main?.i18n);
  const [successMessage, setSuccessMessage] = useSetState(
    signupPageContentSectionsByName.successMessage?.i18n,
  );

  const [isSuccessModalOpen, setSuccessModalOpen] = useState<boolean>(false);

  const [selectedEditorLang, setSelectedEditorLang] = useState(currentLang);
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    if (isEditMode) {
      setContent(signupPageContentSectionsByName.main?.i18n);
      setSuccessMessage(signupPageContentSectionsByName.successMessage?.i18n);
    }
    setIsEditMode(m => !m);
  };

  const updatePageContent = async () => {
    const newContent = {
      ...omit(signupPageContent, ['createdAt', 'updatedAt']),
      content: [
        {
          ...signupPageContentSectionsByName.main,
          name: 'main',
          i18n: { ...content },
        },
        {
          ...signupPageContentSectionsByName.successMessage,
          name: 'successMessage',
          i18n: { ...successMessage },
        },
      ],
    };

    await savePageContent(newContent, signupPageContent.id);

    setIsEditMode(false);
  };

  const saveApplicant = async (editedApplicant: IFormApplicant, id?: string) => {
    await doSaveApplicant(editedApplicant, id, currentLang)
      .then(() =>
        enqueueSnackbar(t('applicants.saveSuccessful'), {
          variant: 'success',
        }),
      )
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`race.signUp.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      );
  };

  useTitle(`${t('race.signUp.pageTitle')} - ${t('base.defaultTitle')}`);

  const { activeEditionId, dateTimeFormat, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;
  const isSignupOpen = currentEdition?.isSignupOpen;
  const areWeBeforeSignupDeadline = currentEdition
    ? moment().isBefore(currentEdition.signupDeadline)
    : false;

  return (
    <PageBox sx={{ pt: 4, pb: 4 }} isLoading={isLoadingPageContent}>
      <Grid container>
        <Grid item xs={false} sm={3} md={6} />
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Typography component="h2" variant="h2" mb={4}>
              {t('race.signUp.pageTitle')}
            </Typography>

            {isEditMode ? (
              <>
                <Editor
                  onChange={newValue => setContent({ [selectedEditorLang]: newValue })}
                  value={content[selectedEditorLang]}
                />
                <Tooltip title={t('race.signUp.contentShownWhenSignupSuccessfulTooltip')} placement="bottom">
                  <Box sx={{ border: '1px dashed white', p: 1 }}>
                    <Editor
                      onChange={newValue => setSuccessMessage({ [selectedEditorLang]: newValue })}
                      value={successMessage[selectedEditorLang]}
                    />
                  </Box>
                </Tooltip>
              </>
            ) : (
              parseTinyMCEContent(content[currentLang], {
                currentEdition,
                dateFormat: dateTimeFormat,
              })
            )}

            {areWeBeforeSignupDeadline && isSignupOpen ? (
              <ApplicantEditorForm
                handleSave={saveApplicant}
                handleSuccessModalOpen={setSuccessModalOpen}
                isExternalSignup
                saveButtonText={t('form.register')}
              />
            ) : (
              <>
                <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />
                <Typography variant="body1" component="p" gutterBottom>
                  {t('race.signUp.cantSignUp')}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <BasicModal
        handleClose={() => setSuccessModalOpen(false)}
        id="signup-success"
        open={isSuccessModalOpen}
        title={t('form.registerSuccessful')}
      >
        {successMessage ? parseTinyMCEContent(successMessage[currentLang], {
          currentEdition,
          dateFormat: dateTimeFormat,
        }) : null}
      </BasicModal>

      {isAdmin ? (
        <EditPageFab
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          save={updatePageContent}
          selectedLang={selectedEditorLang}
          switchLang={setSelectedEditorLang}
          position={{ position: 'fixed', bottom: 70, right: 16 }}
        />
      ) : null}
    </PageBox>
  );
}
