import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CancelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M18.5,10c-0.2-1-0.7-2-1.4-2.8c0.4-0.4,0.7-0.8,1.1-1.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0
	c-0.3,0.4-0.7,0.8-1,1.2c-0.8-0.7-1.7-1.2-2.6-1.3C12.2,5,10,5.5,8.7,7.1C8.6,7.3,8.6,7.6,8.8,7.8s0.5,0.1,0.7-0.1
	c1-1.3,2.9-1.7,4.2-1.5c0.8,0.1,1.5,0.5,2.1,1.1c-0.7,0.9-1.5,1.7-2.2,2.6c-1.8,2.2-3.7,4.4-5.7,6.6c-0.2-0.1-0.3-0.2-0.5-0.4
	c-1.7-1.7-1.8-4.1-0.3-6.8C7.3,9,7.2,8.7,7,8.6c-0.2-0.1-0.5,0-0.7,0.2c-1.7,3.1-1.5,6,0.4,7.9c0.1,0.1,0.3,0.3,0.5,0.4
	C7,17.4,6.7,17.7,6.4,18c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.3-0.3,0.6-0.6,0.9-1
	c1.2,0.7,2.6,1.1,4.1,1.1c1.2,0,2.4-0.3,3.5-0.8C18.2,16.5,19.3,13.6,18.5,10z M15.1,17.1c-1.9,1-4.5,0.9-6.4-0.2
	c2-2.1,3.8-4.3,5.7-6.5c0.7-0.8,1.4-1.7,2.1-2.5c0.5,0.6,0.9,1.4,1.1,2.2C18.3,13.4,17.4,15.9,15.1,17.1z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CancelIcon;
