import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function Copyright() {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {t('footer.copyright')}{' '}
      <Link color="inherit" href="https://ekemvh.ro/">
        EKE
      </Link>{' '}
      <Link color="inherit" href="https://teleki.ekemvh.ro/">
        Teleki Trail
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
