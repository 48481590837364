import mapKeys from 'lodash-es/mapKeys';

export enum LanguageKeys {
  en = 'en',
  ro = 'ro',
  hu = 'hu',
}

export const LANGUAGES: {
  nativeName: string;
  code: LanguageKeys;
}[] = [
  {
    nativeName: 'English',
    code: LanguageKeys.en,
  },
  {
    nativeName: 'Română',
    code: LanguageKeys.ro,
  },
  {
    nativeName: 'Magyar',
    code: LanguageKeys.hu,
  },
];

export const LANGUAGES_BY_CODE = mapKeys(LANGUAGES, 'code');
