import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import Resolver from 'src/common/Resolver';
import { IEditionCompetition } from 'src/interfaces/Edition';
import useApplicantsStore from 'src/stores/applicantsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import Statistics from './Statistics';

export interface StatisticsRouteParams {
  competition: string;
  year: string;
}

const StatisticsResolver = () => {
  const { fetchApplicants: doFetchApplicants } = useApplicantsStore(
    applicantsState => applicantsState,
  );

  const { activeEditionId, competitionsByName, editionsByYear } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const { competition: compName, year } = useParams<keyof StatisticsRouteParams>();

  const competition = !!compName && !!competitionsByName && competitionsByName[compName];

  if (!competition || !year || !editionsByYear || !activeEditionId) return null;

  const selectedEdition = editionsByYear[year];
  const selectedCompetition = selectedEdition.competitions.find(
    c => c.competitionId === competition.id,
  ) as IEditionCompetition;

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchApplicants()];

    return Promise.all(deps);
  };

  if (!competition) return null;

  return (
    <Resolver
      resolve={loadDependencies}
      successComponent={Statistics}
      successProps={{ competition, distances: selectedCompetition.distances }}
    />
  );
};

export default StatisticsResolver;
