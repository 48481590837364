import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TentIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M21,16.3c-0.4,0-2.6,0.1-3.2,0.1c-0.3-0.7-0.9-3.6-1.2-6c0,0,0-0.1,0-0.1c0-0.9,0-1.8,0.1-2.4c0-0.3-0.2-0.5-0.5-0.5
      c-0.3,0-0.5,0.2-0.5,0.5c0,0.2,0,0.4,0,0.7c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.3,0.1,0.8,0.1,1.4c0,2.1-0.1,4.6-0.4,5.5
      c-0.2,0.7-1.9,0.6-3.4,0.4l-0.4,0c-0.3,0-0.5,0.2-0.5,0.4s0.2,0.5,0.4,0.5l0.4,0c0.7,0.1,1.4,0.1,2,0.1c1.2,0,2.1-0.2,2.4-1.2
      c0.1-0.3,0.2-0.8,0.2-1.4c0.3,1.3,0.6,2.3,0.9,2.5c0.2,0.1,0.8,0.1,1.5,0.1c1,0,2.2-0.1,2.3-0.1c0.3,0,0.4-0.3,0.4-0.5
      C21.5,16.4,21.3,16.3,21,16.3z"
        fill="currentColor"
      />
    </g>
    <path
      d="M21.3,16.1c-1.6-0.8-3.7-5-4.6-8.3c0-0.1-0.1-0.1-0.1-0.2c0-0.3,0-0.4-0.1-0.4c-0.2-0.3-0.3-0.3-1.8-0.2
    C12.9,7,9.9,7.1,7.9,6.7C7.3,6.5,7,7.1,6.1,9.6c-0.9,2.2-2.3,5.6-3.5,6.7c-0.2,0.1-0.2,0.4-0.1,0.6s0.3,0.3,0.5,0.3
    c2.7-0.4,5.6-0.2,7.3,0c0.7,0.1,1.1,0.1,1.4,0.1c0.2,0,0.4,0,0.5-0.1c0.7-0.2,1.2-1.3,2.1-3.6c0.3-0.8,0.7-1.7,1.1-2.6
    c0.3-0.7,0.6-1.3,0.8-1.8c1,3,2.9,6.9,4.8,7.8c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.3C21.6,16.5,21.5,16.2,21.3,16.1z M14.5,10.5
    c-0.4,0.9-0.8,1.8-1.1,2.6c-0.5,1.3-1.2,2.8-1.5,3c-0.2,0-0.9,0-1.5-0.1c-1-0.1-2.4-0.2-3.9-0.2c-0.8,0-1.5,0-2.3,0.1
    c1.1-1.6,2.1-4.1,2.9-6.1C7.3,9,7.7,8,7.9,7.7C10.1,8.1,13,8,14.8,7.9c0.3,0,0.6,0,0.8,0C15.4,8.5,14.8,9.7,14.5,10.5z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TentIcon;
