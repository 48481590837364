import TranslateIcon from '@mui/icons-material/Translate';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import { useAuth } from 'src/auth/useAuth';
import Flag from 'src/common/components/flags/Flag';
import Icon from 'src/common/components/icons/Icon';
import { LANGUAGES, LanguageKeys } from 'src/common/constants/languages';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import DropdownButton from './DropdownButton';
import UserDrawer from './UserDrawer';
import UserMenu from './UserMenu';
import { getMenuLayout } from './constants/menuLayout';
import { usePopupState } from 'material-ui-popup-state/hooks';

const TopMenu = () => {
  const {
    t,
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { activeEditionId, competitionsById, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const userMenu = usePopupState({ variant: 'popover', popupId: 'user-menu' });

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  const menuLayout = getMenuLayout({
    competitions: currentEdition?.competitions,
    competitionsById,
    lang: currentLang,
    year: currentEdition?.year,
  });

  const auth = useAuth();
  const { pathname } = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState<undefined | HTMLElement>(undefined);
  const languageMenuOpen = Boolean(languageMenuAnchor);

  const toggleLanguageMenu = (event: MouseEvent<HTMLElement>) => {
    setLanguageMenuAnchor(languageMenuOpen ? undefined : event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuAnchor(undefined);
  };

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="sticky"
      sx={{ backdropFilter: 'blur(5px)' }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
              {drawerOpen ? (
                <Icon icon="close" sx={{ color: theme => theme.palette.grey[900] }} />
              ) : (
                <Icon icon="caretRight" sx={{ color: theme => theme.palette.grey[900] }} />
              )}
            </IconButton>
            <UserDrawer open={drawerOpen} closeDrawer={() => setDrawerOpen(!drawerOpen)} />
          </Box>

          <Icon
            icon="ttt"
            sx={{
              fontSize: 60,
              display: 'flex',
              mr: 1,
              color: theme => theme.palette.grey[900],
            }}
          />

          <Box
            sx={{ flexGrow: 1, textTransform: 'uppercase', display: { xs: 'none', md: 'flex' } }}
          >
            {menuLayout.map(page => {
              return page.subPages?.length ? (
                <DropdownButton
                  key={page.id}
                  buttonText={page.pageName ? page.pageName : t(`drawer.${page.id}`)}
                  id={page.id}
                  items={page.subPages}
                  pageUrl={page.pageUrl}
                />
              ) : (
                <Button
                  key={page.id}
                  href={page.pageUrl}
                  sx={{
                    mx: 4,
                    my: 2,
                    color: theme => theme.palette.grey[900],
                    borderRadius: 0,
                    borderBottom: theme =>
                      pathname.includes(page.pageUrl)
                        ? `3px solid ${theme.palette.grey[800]}`
                        : undefined,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: theme => theme.palette.grey[800],
                    },
                  }}
                  variant="text"
                >
                  {page.pageName ? page.pageName : t(`drawer.${page.id}`)}
                </Button>
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              sx={{
                mx: 4,
                my: 2,
                color: grey[900],
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: grey[800],
                },
              }}
              onClick={toggleLanguageMenu}
            >
              <TranslateIcon />
            </IconButton>
            <Menu
              id="language-menu"
              open={languageMenuOpen}
              anchorEl={languageMenuAnchor}
              slotProps={{
                paper: {
                  sx: {
                    minWidth: '150px',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    backdropFilter: 'blur(3px)',
                  },
                },
              }}
              sx={{ mt: '45px' }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              {LANGUAGES.map(lng => (
                <MenuItem
                  key={lng.code}
                  onClick={async () => {
                    await changeLanguage(lng.code);
                    closeLanguageMenu();
                  }}
                  selected={currentLang === lng.code}
                  disabled={currentLang === lng.code}
                >
                  <ListItemIcon>
                    <Flag code={lng.code} />
                  </ListItemIcon>
                  <ListItemText primary={t(`base.i18n.${lng.code}`)} />
                </MenuItem>
              ))}
            </Menu>
            {!!auth.user ? (
              <>
                <Tooltip title={auth.user.name}>
                  <IconButton
                    sx={{
                      mx: 4,
                      my: 2,
                      color: grey[900],
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: grey[800],
                      },
                    }}
                    onClick={userMenu.open}
                  >
                    <Icon icon="wrench" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <UserMenu popupState={userMenu} />
              </>
            ) : (
              <Tooltip title={t('drawer.signIn')}>
                <IconButton href="/account/signin" sx={{ p: 0 }}>
                  <Icon
                    icon="key"
                    fontSize="large"
                    sx={{ color: theme => theme.palette.grey[900] }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopMenu;
