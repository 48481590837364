import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M14,10.6c0.3-0.3,0.7-0.6,1.1-0.9c0.3-0.2,0.6-0.4,0.8-0.6c1-0.8,1.8-1.4,2-1.5c0.3,0,0.4-0.2,0.4-0.5
	c0-0.2-0.1-0.3-0.3-0.4c-0.1,0-0.1,0-0.2,0c-0.3,0-0.8,0.3-2.6,1.7C15,8.6,14.7,8.8,14.4,9c-0.4,0.3-0.9,0.6-1.2,0.9
	c-0.4,0.4-0.8,0.9-1.2,1.2c-0.4-0.3-0.9-0.8-1.9-1.6c-0.3-0.3-1.3-1-2-1.6C7.8,7.6,7.5,7.4,7.4,7.3C7.1,7.1,6.9,6.9,6.7,6.8L6.6,6.7
	C6.3,6.6,6,6.7,5.9,6.9C5.8,7.1,5.8,7.4,6.1,7.5c0.2,0.2,0.4,0.4,0.7,0.6c0.1,0,0.4,0.3,0.7,0.7c0.7,0.6,1.7,1.3,2,1.6
	c1.1,1,1.6,1.5,1.9,1.7c0,0,0,0,0,0.1C11,12.5,10.5,13,10,13.5c-0.3,0.3-0.7,0.6-1.1,0.9c-0.3,0.2-0.6,0.4-0.8,0.6
	c-1,0.8-1.8,1.4-2,1.5c-0.3,0-0.4,0.2-0.4,0.5c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.1,0,0.2,0c0.3,0,0.8-0.3,2.6-1.7
	c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.9-0.6,1.2-0.9c0.4-0.4,0.8-0.9,1.2-1.2c0.4,0.3,0.9,0.8,1.9,1.6c0.3,0.3,1.3,1,2,1.6
	c0.4,0.3,0.7,0.5,0.8,0.6c0.3,0.2,0.5,0.4,0.7,0.5l0.1,0.1c0.3,0.1,0.6,0,0.7-0.2c0.1-0.2,0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.4-0.7-0.6
	c-0.1,0-0.4-0.3-0.7-0.7c-0.7-0.6-1.7-1.3-2-1.6c-1.1-1-1.6-1.5-1.9-1.7c0,0,0,0,0-0.1C13,11.5,13.5,11,14,10.6z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CloseIcon;
