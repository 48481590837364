import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import mapKeys from 'lodash-es/mapKeys';
import omit from 'lodash-es/omit';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSetState from 'react-use/lib/useSetState';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import CompetitionsList from 'src/common/components/CompetitionsList';
import EditPageFab from 'src/common/components/EditPageFab';
import Editor from 'src/common/components/Editor';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { parseTinyMCEContent } from 'src/helpers/parseTinyMCEContent';
import { IPageContent } from 'src/interfaces/PageContent';
import tttLogo from 'src/static/images/ttt-logo+shadow.png';
import usePageContentsStore from 'src/stores/pageContentsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import CalendarIcon from './components/CalendarIcon';
import CardMedia from '@mui/material/CardMedia';

export default function Home() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { isAdmin } = useAuth();
  const currentLang = resolvedLanguage as LanguageKeys;

  useTitle(`${t('base.defaultTitle')}`);

  const { activeEditionId, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const { isLoading, pageContentsByName, savePageContent } = usePageContentsStore(
    pageContentsStore => pageContentsStore,
  );

  const homePageContent: IPageContent = pageContentsByName?.home as IPageContent;
  const homePageContentSectionsByName = mapKeys(homePageContent.content, 'name');

  const [content, setContent] = useSetState(homePageContentSectionsByName.main.i18n);
  const [cta, setCTA] = useSetState(homePageContentSectionsByName.cta.i18n);

  const [selectedEditorLang, setSelectedEditorLang] = useState(currentLang);
  const [isEditMode, setIsEditMode] = useState(false);

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;
  const isSignupOpen = currentEdition?.isSignupOpen;
  const eventDate = currentEdition ? moment(currentEdition.date) : undefined;
  const areWeBeforeSignupDeadline = currentEdition
    ? moment().isBefore(currentEdition.signupDeadline)
    : false;

  const toggleEditMode = () => {
    if (isEditMode) {
      setContent(homePageContentSectionsByName.main.i18n);
      setCTA(homePageContentSectionsByName.cta.i18n);
    }
    setIsEditMode(m => !m);
  };

  const updatePageContent = async () => {
    const newContent = {
      ...omit(homePageContent, ['createdAt', 'updatedAt']),
      content: [
        {
          ...homePageContentSectionsByName.main,
          name: 'main',
          i18n: { ...content },
        },
        {
          ...homePageContentSectionsByName.cta,
          name: 'cta',
          i18n: { ...cta },
        },
      ],
    };

    await savePageContent(newContent, homePageContent.id);

    setIsEditMode(false);
  };

  return (
    <PageBox sx={{ pt: 4, pb: 4 }} isLoading={isLoading}>
      <Grid container>
        <Grid item xs={false} sm={3} md={6} />
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Grid
              container
              spacing={3}
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              marginBottom={2}
            >
              <Grid
                item
                xs={currentEdition && areWeBeforeSignupDeadline ? 8 : 12}
                display="flex"
                justifyContent="center"
              >
                <Typography component="h1" variant="h1">
                  <CardMedia
                    component="img"
                    src={tttLogo}
                    sx={{
                      height: 200,
                      objectFit: 'contain',
                    }}
                    alt={t('base.defaultTitle')}
                  />
                </Typography>
              </Grid>
              {eventDate && areWeBeforeSignupDeadline ? (
                <>
                  <Grid item xs={4} display="flex" justifyContent="center">
                    {eventDate ? <CalendarIcon eventDate={eventDate} /> : null}
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <FlipClockCountdown
                      to={eventDate.toDate()}
                      labelStyle={{
                        fontSize: '.8em',
                        color: '#000',
                      }}
                      renderMap={[true, true, false, false]}
                      labels={[
                        t('timers.flipClockLabels.days'),
                        t('timers.flipClockLabels.hours'),
                        t('timers.flipClockLabels.minutes'),
                        t('timers.flipClockLabels.seconds'),
                      ]}
                      digitBlockStyle={{
                        fontSize: '1em',
                        width: '40px',
                        height: '55px',
                      }}
                    />
                  </Grid>
                </>
              ) : null}

              {currentEdition && areWeBeforeSignupDeadline && isSignupOpen ? (
                <Grid item xs={12} textAlign="center">
                  <Button href="/race/signup" size="large" variant="contained" color="primary">
                    {t('home.signUpNow')}
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                {isEditMode ? (
                  <Editor
                    onChange={newValue => setContent({ [selectedEditorLang]: newValue })}
                    value={content[selectedEditorLang]}
                  />
                ) : (
                  parseTinyMCEContent(content[currentLang], { currentEdition })
                )}

                {isEditMode ? (
                  <Tooltip title={t('home.contentShownWhenSignupOpenTooltip')} placement="bottom">
                    <Box sx={{ border: '1px dashed white', p: 1 }}>
                      <Editor
                        onChange={newValue => setCTA({ [selectedEditorLang]: newValue })}
                        value={cta[selectedEditorLang]}
                      />
                    </Box>
                  </Tooltip>
                ) : currentEdition && areWeBeforeSignupDeadline && isSignupOpen ? (
                  parseTinyMCEContent(cta[currentLang])
                ) : null}
              </Grid>
            </Grid>
            {currentEdition ? (
              <CompetitionsList
                competitions={currentEdition.competitions}
                isSignupOpen={areWeBeforeSignupDeadline && isSignupOpen}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {isAdmin ? (
        <EditPageFab
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          save={updatePageContent}
          selectedLang={selectedEditorLang}
          switchLang={setSelectedEditorLang}
          position={{ position: 'fixed', bottom: 70, right: 16 }}
        />
      ) : null}
    </PageBox>
  );
}
