import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Icon from 'src/common/components/icons/Icon';
import { LanguageKeys } from 'src/common/constants/languages';
import { IEditionCompetition } from 'src/interfaces/Edition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export interface CompetitionsListProps {
  competitions: IEditionCompetition[];
  isSignupOpen?: boolean;
}

export default function CompetitionsList({ competitions, isSignupOpen }: CompetitionsListProps) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const navigate = useNavigate();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { palette } = useTheme();

  const { competitionsById, distancesById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  if (!distancesById || !competitionsById) return null;

  return (
    <>
      <Grid container spacing={2}>
        {competitions.map(({ competitionId, distances }) => {
          const competition = competitionsById[competitionId];
          return (
            <Grid item xs={6} key={competitionId}>
              <Card
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  backdropFilter: 'blur(3px)',
                  display: 'flex',
                  flexDirection: 'column',
                  borderTopLeftRadius: 40,
                  borderBottomLeftRadius: 40,
                  mb: 2,
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        elevation: 1,
                        background: `linear-gradient(135deg, ${palette.success.dark} 0%, ${palette.success.light} 100%)`,
                        width: 56,
                        height: 56,
                        fontSize: 40,
                      }}
                      aria-label="recipe"
                    >
                      <Icon fontSize="inherit" icon={competition.icon} />
                    </Avatar>
                  }
                  title={competition.i18n[currentLang]}
                  sx={{ p: 1 }}
                />
                <CardContent sx={{ mt: 0, p: 1 }}>
                  {distances.map(distanceId => {
                    const distance = distancesById[distanceId];
                    const distanceName = distance.i18n[currentLang];
                    return (
                      <Avatar
                        key={distance.id}
                        sx={{
                          elevation: 1,
                          background: `linear-gradient(135deg, ${palette.primary.dark} 0%, ${palette.primary.light} 100%)`,
                          mr: 1,
                          p: 1,
                          width: 50,
                          height: 50,
                          border: theme => `1px solid ${theme.palette.background.paper}`,
                          fontSize: 16,
                          textAlign: 'center',
                          display: 'inline-flex',
                          cursor: 'pointer',
                        }}
                        onClick={
                          isSignupOpen
                            ? () =>
                                navigate(
                                  `/race/signup?competition=${competition.id}&distance=${distance.id}`,
                                )
                            : undefined
                        }
                      >
                        {distanceName}
                      </Avatar>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
