import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const XMLIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d=" M 20.187 0.124 L 1.82 0.124 L 1.82 18.49 L 5.16 18.49 L 5.16 15.151 L 5.16 13.398 L 5.16 3.463 L 18.801 3.463 L 25.196 9.858 L 25.196 13.398 L 25.196 15.151 L 25.196 18.49 L 28.535 18.49 L 28.535 8.472 L 20.187 0.124 L 20.187 0.124 Z  M 7.509 21.848 L 5.144 25.117 L 2.81 21.848 L 0.184 21.848 L 3.708 26.73 L 0 31.876 L 2.667 31.876 L 5.032 28.529 L 7.41 31.876 L 10.133 31.876 L 6.448 26.787 L 10.092 21.848 L 7.509 21.848 Z  M 18.086 27.252 C 17.916 27.774 17.721 28.45 17.499 29.275 C 17.278 28.44 17.083 27.766 16.919 27.252 L 15.186 21.83 L 11.838 21.83 L 11.838 31.858 L 13.925 31.858 L 13.925 23.74 C 14.156 24.605 14.358 25.298 14.527 25.822 L 16.48 31.858 L 18.419 31.858 L 20.429 25.652 C 20.636 25.019 20.821 24.383 20.98 23.74 L 20.98 31.858 L 23.154 31.858 L 23.154 21.83 L 19.843 21.83 L 18.086 27.252 Z  M 25.433 21.848 L 25.433 31.876 L 32 31.876 L 32 30.106 L 27.692 30.106 L 27.692 21.848 L 25.433 21.848 Z "
      fill-rule="evenodd"
      fill="currentColor"
    />
  </SvgIcon>
);

export default XMLIcon;
