/**
 * Group array of objects by given keys
 * @param keys keys to be grouped by
 * @param array objects to be grouped
 * @returns an object with objects in `array` grouped by `keys`
 * @see <https://gist.github.com/mikaello/06a76bca33e5d79cdd80c162d7774e9c>
 */
export const groupByMultipleKeys =
  <T>(keys: (keyof T)[], joiner: string = '-') =>
  (array: T[]): Record<string, T[]> =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map(key => obj[key]).join(joiner);
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);

// export const groupByNestedMultipleKeys = <T>(keys: (keyof T)[]) => (array: T[]) =>
//   array.reduce((objectsByKeyValue, obj) => {
//     // Instead of creating a unique key for each grouped by values, we are now traversing (and building)
//     // the whole object structure for every array value:
//     keys.reduce((builder, key, index) => {
//       if (index !== keys.length - 1) {
//         // Building the nested grouped by structure
//         builder[obj[key]] = builder[obj[key]] || {};
//       } else {
//         // Appending the current object at the leaf node
//         builder[obj[key]] = (builder[obj[key]] || []).concat(obj);
//       }
//       return builder[obj[key]];
//     }, objectsByKeyValue);

//     return objectsByKeyValue;
//   });
