import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CompassIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M18.6,7.7c-0.7-1.4-2.3-2.5-3.9-2.8c-1.4-0.3-2.6,0-3.6,0.7C10.8,5.7,10.8,6,11,6.2c0.2,0.2,0.5,0.3,0.7,0.1
	c0.7-0.6,1.8-0.8,2.8-0.5c1.4,0.3,2.6,1.2,3.2,2.3c1,1.9,1.2,4.2,0.4,5.9c-0.7,1.6-1.7,2.8-3,3.4c-1.3,0.7-2.9,0.9-4.8,0.7
	c-0.4,0-0.8-0.2-1.3-0.3c0,0,0,0,0,0c0,0,0,0,0,0c1.9-1.6,3.7-3.4,5-4.8c0,0,0-0.1,0-0.1c0,0,0,0,0,0l0.3-0.6
	c0.6-1.4,1.6-3.4,2.1-4.9c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c-1.9,1.4-3.6,2.6-5.3,4c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.7,1.9-1.6,4.6-2.1,6.3
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.7-0.4-1.3-1-1.8-1.7c-0.9-1.2-1.2-2.5-0.9-3.7c0.6-2.7,2.1-4.6,4.1-5.2
	C9.9,6.8,10,6.5,9.9,6.3C9.9,6,9.6,5.9,9.3,6C7,6.7,5.3,8.8,4.6,11.9c-0.3,1.4,0.1,3.1,1.1,4.5c1.1,1.6,2.8,2.6,4.5,2.8
	c0.5,0,0.9,0.1,1.4,0.1c1.5,0,2.8-0.3,4-0.9c1.5-0.7,2.7-2.1,3.4-3.9C19.9,12.4,19.7,9.8,18.6,7.7z M9.8,16c0.4-1.4,1-2.9,1.4-4.2
	l1.7,1.1C12,13.8,10.9,14.9,9.8,16z M13.6,11.8l-0.1,0.3L11.8,11c1.1-0.9,2.1-1.7,3.3-2.5C14.6,9.7,14,10.9,13.6,11.8z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CompassIcon;
