import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { LanguageKeys } from 'src/common/constants/languages';

import { ITrackMap } from 'src/interfaces/TrackMap';

interface Props {
  trackMap: ITrackMap;
  optionElement?: JSX.Element;
}

const CompetitionTableRow = ({
  trackMap: { distance, id, levelDiff, name, route, time, totalDistance },
  optionElement,
}: Props) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>{distance}</TableCell>
      <TableCell>{route.i18n[currentLang]}</TableCell>
      <TableCell>{totalDistance}</TableCell>
      <TableCell>
        {levelDiff.pos}/{levelDiff.neg} m
      </TableCell>
      <TableCell>{time}</TableCell>
      {!!optionElement && <TableCell align="right">{optionElement}</TableCell>}
    </TableRow>
  );
};

export default CompetitionTableRow;
