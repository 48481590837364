import { AxiosResponse } from 'axios';
import mapKeys from 'lodash-es/mapKeys';
import { StateCreator } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { ICompetition } from 'src/interfaces/Competition';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';

export interface CompetitionSlice {
  competitions: ICompetition[];
  competitionsById?: { [key: string]: ICompetition };
  competitionsByName?: { [key: string]: ICompetition };

  isLoading: boolean;

  deleteCompetition: (id: string) => Promise<void | AxiosResponse<null>>;
  deleteAllCompetitions: () => Promise<void | AxiosResponse<null>>;
  fetchCompetitions: () => Promise<void | AxiosResponse<null>>;

  resetCompetitions: () => void;

  saveCompetition: (
    competition: FormTechnicalType,
    id?: string,
  ) => Promise<void | AxiosResponse<ICompetition[]>>;
}

export const createCompetitionsSlice: StateCreator<
  CompetitionSlice,
  [],
  [],
  CompetitionSlice
> = set => {
  const getCompetitions = async () => {
    return SECURE_API.get<ICompetition[]>('competitions/')
      .then(({ data }) => {
        set({
          competitions: data,
          competitionsById: mapKeys(data, 'id'),
          competitionsByName: mapKeys(data, 'name'),
          isLoading: false,
        });
      })
      .catch((error: string) => {
        set({
          competitions: [],
          competitionsById: undefined,
          competitionsByName: undefined,
          isLoading: false,
        });
        console.log({ error });
      });
  };

  return {
    competitions: [],

    isLoading: false,

    deleteCompetition: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`competitions/delete/${id}`)
        .finally(() => {
          getCompetitions();
        });
    },
    deleteAllCompetitions: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>('competitions/delete/bulk')
        .finally(() => {
          getCompetitions();
        });
    },
    fetchCompetitions: async () => {
      set({ isLoading: true });
      getCompetitions();
    },
    resetCompetitions: () => set({ competitions: [] }),
    saveCompetition: async (competition: FormTechnicalType, id?: string) => {
      set({ isLoading: true });
      const saveCompetitionPromise = id
        ? SECURE_API.put<ICompetition[]>(`competitions/update/${id}`, competition)
        : SECURE_API.post<ICompetition[]>('competitions/save', competition);
      return saveCompetitionPromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getCompetitions();
        });
    },
  };
};
