import { MouseEvent } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

import { EOrder } from 'src/interfaces/Order';
import { THeadCell } from 'src/interfaces/Table';

interface Props<T> {
  headCells: readonly THeadCell<T>[];
  isCollapsible?: boolean;
  onRequestSort?: (event: MouseEvent<unknown>, property: T) => void;
  optionsCellWidth?: string;
  optionsLabel?: string;
  order?: EOrder;
  orderBy?: string;
  withOptions?: boolean;
}

function TableHead<T>(props: Props<T>) {
  const {
    headCells,
    isCollapsible,
    onRequestSort,
    optionsCellWidth,
    optionsLabel,
    order,
    orderBy,
    withOptions,
  } = props;

  const { t } = useTranslation();

  const createSortHandler = (property?: T) => (event: MouseEvent<unknown>) => {
    (property && onRequestSort) && onRequestSort(event, property);
  };

  return (
    <MUITableHead>
      <MUITableRow>
        {isCollapsible  && <TableCell />}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id as string}
            align={headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.sortProperty ? order : false}
            width={headCell.width}
          >
            {headCell.label}
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : EOrder.asc}
                onClick={createSortHandler(headCell.sortProperty)}
              >
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === EOrder.desc ? t('table.orderDesc') : t('table.orderAsc')}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {withOptions && <TableCell width={optionsCellWidth} align="right">{!!optionsLabel && optionsLabel}</TableCell>}
      </MUITableRow>
    </MUITableHead>
  );
};

export default TableHead;
