import { Gender } from 'src/common/constants/genders';
import { IAnonymizedCheckin } from 'src/interfaces/Checkin';

export enum EAgeGroups {
  ten = '0-10',
  twenty = '11-20',
  thirty = '21-30',
  forty = '31-40',
  fifty = '41-50',
  sixty = '51-60',
  seventy = '61-70',
  seventyPlus = '70+',
}

export type TWinnersByGender = {
  [key in Gender]: {
    firstPlace: IAnonymizedCheckin[],
    secondPlace: IAnonymizedCheckin[],
    thirdPlace: IAnonymizedCheckin[],
  };
};

export type TDataByGender = {
  [key in Gender]: {
    ratio: number;
    totalTime: number;
    averageTime: number;
  };
};

export type TDataByGenderByAge = {
  [key in Gender]: {
    [key in EAgeGroups]: {
      bestTime: number;
      averageTime: number;
      noOfApplicants: number;
    };
  };
};

export type TStatistic = {
  dataByGender: TDataByGender;
  checkinsByGenderByAgeKeys: Gender[];
  winnersByGender: TWinnersByGender;
  dataByGenderByAge: TDataByGenderByAge;
};

export type TStatistics = {
  [key: string]: TStatistic;
};
