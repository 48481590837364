import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, object, ref, string } from 'yup';

import TextField from '@mui/material/TextField';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import { IResetPassword } from 'src/interfaces/ForgotPassword';

export type IFormResetPassword = IResetPassword & {
  repeatPassword?: string;
};

interface SignInFormProps {
  handleSave: (editedApplicant: IFormResetPassword) => void;
}

const SignInForm = ({ handleSave }: SignInFormProps) => {
  const { t } = useTranslation();

  const resetPasswordSchema: ObjectSchema<IFormResetPassword> = object()
    .shape({
      newPassword: string()
        .test('strongPassword', t('form.yup.passwordComplexity'), (value, _testContext) => {
          if (!value) return true;
          return (
            /^(?=.*[a-z])/.test(value) &&
            /^(?=.*[A-Z])/.test(value) &&
            /^(?=.*[0-9])/.test(value) &&
            /^(?=.*[^A-Za-z0-9])/.test(value)
          );
        })
        .oneOf([ref('repeatPassword')], t('form.yup.passwordsDontMatch'))
        .required(),
      repeatPassword: string()
        .oneOf([ref('newPassword')], t('form.yup.passwordsDontMatch'))
        .required(),
    })
    .required();

  const methods = useForm<IFormResetPassword>({
    resolver: yupResolver(resetPasswordSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = methods;

  const onSubmit: SubmitHandler<IFormResetPassword> = editedApplicant => {
    handleSave(editedApplicant);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, mb: 2 }}>
      <TextField
        sx={disableAutofillBg}
        autoComplete="new-password"
        defaultValue=""
        fullWidth
        id="newPassword"
        label={t('form.newPassword')}
        margin="normal"
        type="password"
        error={!!errors['newPassword']}
        helperText={errors['newPassword']?.message}
        {...register('newPassword')}
      />
      <TextField
        sx={disableAutofillBg}
        autoComplete="new-password"
        defaultValue=""
        fullWidth
        id="repeatPassword"
        label={t('form.newPasswordRepeat')}
        margin="normal"
        type="password"
        error={!!errors['repeatPassword']}
        helperText={errors['repeatPassword']?.message}
        {...register('repeatPassword')}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!!Object.keys(errors).length || !isDirty}
        sx={{ mt: 3 }}
      >
        {t('form.save')}
      </Button>
    </Box>
  );
};

export default SignInForm;
