import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import TimerIcon from '@mui/icons-material/Timer';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/hu';
import 'moment/locale/ro';

import { LanguageKeys } from 'src/common/constants/languages';
import { minutesToHours } from 'src/helpers/dateTimeFormatters';
import { ECheckinTimerType, ICheckin } from 'src/interfaces/Checkin';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

interface Props {
  checkin: ICheckin;
  optionElement?: JSX.Element;
  onClickTimer: (id: string, type: ECheckinTimerType, timeStamp?: Date) => void;
}

const CompetitionTableRow = ({
  checkin: {
    id,
    contestant: { firstName, lastName },
    ser,
    startTime: st,
    finishTime: ft,
    duration,
  },
  onClickTimer,
  optionElement,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { dateTimeFormat } = useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const [isEditStartTime, setEditStartTime] = useState(false);
  const [isEditFinishTime, setEditFinishTime] = useState(false);
  const [startTime, handleStartTimeChange] = useState<Date | undefined>(st);
  const [finishTime, handleFinishTimeChange] = useState<Date | undefined>(ft);

  const handleEditStartTime = () => {
    if (isEditStartTime && !!startTime) onClickTimer(id, ECheckinTimerType.start, startTime);
    setEditStartTime(!isEditStartTime);
  };

  const handleEditFinishTime = () => {
    if (isEditFinishTime && !!finishTime) onClickTimer(id, ECheckinTimerType.finish, finishTime);
    setEditFinishTime(!isEditFinishTime);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={id}>
      <TableCell>
        <Typography sx={{ fontSize: 20, mr: 3 }} align="center">
          {ser}
        </Typography>
      </TableCell>
      <TableCell>{`${lastName} ${firstName}`}</TableCell>
      <TableCell>
        {!!startTime ? (
          <>
            {!isEditStartTime ? (
              moment(startTime).format(dateTimeFormat)
            ) : (
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                <MobileDateTimePicker
                  ampm={false}
                  openTo="hours"
                  maxDateTime={moment(finishTime)}
                  value={moment(startTime)}
                  onChange={newValue => {
                    handleStartTimeChange((newValue?.toDate() as Date) || undefined);
                  }}
                />
              </LocalizationProvider>
            )}
            <Tooltip title={isEditStartTime ? t('timers.save') : t('timers.editTimer')}>
              <IconButton
                aria-label={isEditStartTime ? t('timers.save') : t('timers.editTimer')}
                onClick={handleEditStartTime}
                edge="end"
              >
                {isEditStartTime ? (
                  <CheckIcon sx={{ fontSize: 14 }} />
                ) : (
                  <EditIcon sx={{ fontSize: 14 }} />
                )}
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title={t('timers.startTimer')}>
            <IconButton
              aria-label={t('timers.startTimer')}
              onClick={() => onClickTimer(id, ECheckinTimerType.start)}
              edge="end"
            >
              <TimerIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {!!startTime && !finishTime ? (
          <Tooltip title={t('timers.stopTimer')}>
            <IconButton
              aria-label={t('timers.stopTimer')}
              onClick={() => onClickTimer(id, ECheckinTimerType.finish)}
              edge="end"
            >
              <ShutterSpeedIcon />
            </IconButton>
          </Tooltip>
        ) : !!finishTime ? (
          <>
            {!isEditFinishTime ? (
              moment(finishTime).format(dateTimeFormat)
            ) : (
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                <MobileDateTimePicker
                  ampm={false}
                  openTo="hours"
                  minDateTime={moment(startTime)}
                  value={moment(finishTime)}
                  onChange={newValue => {
                    handleFinishTimeChange((newValue?.toDate() as Date) || undefined);
                  }}
                />
              </LocalizationProvider>
            )}
            <Tooltip title={isEditFinishTime ? t('timers.save') : t('timers.editTimer')}>
              <IconButton
                aria-label={isEditFinishTime ? t('timers.save') : t('timers.editTimer')}
                onClick={handleEditFinishTime}
                edge="end"
              >
                {isEditFinishTime ? (
                  <CheckIcon sx={{ fontSize: 14 }} />
                ) : (
                  <EditIcon sx={{ fontSize: 14 }} />
                )}
              </IconButton>
            </Tooltip>
          </>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{duration ? minutesToHours(duration, t) : '-'}</TableCell>
      {!!optionElement && <TableCell align="right">{optionElement}</TableCell>}
    </TableRow>
  );
};

export default CompetitionTableRow;
