import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PaperPlaneIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M18.8,7.1c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.1-2.2,0.6c-0.3,0.1-0.5,0.2-0.6,0.2C14.4,8.2,9,9,6.7,9.3
	C5.5,9.5,5.5,9.5,5.4,9.6C5.2,9.8,5.2,10,5.2,10.2c0.2,0.5,2.6,1.9,3,2c0.3,0,0.5-0.2,0.6-0.4c0-0.3-0.1-0.5-0.4-0.5
	c-0.2-0.1-1-0.5-1.6-0.9c0,0,0,0,0,0C8.8,10,12.2,9.5,14.4,9c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0.2-0.9,0.5-1.6,0.8
	c-1.6,0.8-2.7,1.3-3.1,1.6c-0.4,0.3-0.2,0.7-0.2,0.9c0.5,3.6,0.7,3.7,0.9,3.7c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1
	L11,16c0.5-0.4,1-0.8,1.5-1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.8,0.6,2.2,1.6,2.4,1.9c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0
	c0.3,0,0.4-0.2,0.7-1.7c0.3-1.5,0.9-3.9,1.6-6.5c0-0.1,0.2-0.3,0.3-0.6C18.8,7.5,18.8,7.4,18.8,7.1z M12.8,10.9
	c0.7-0.3,1.3-0.6,1.6-0.8c0.2-0.1,0.4-0.2,0.6-0.4c0.3-0.2,0.6-0.3,0.8-0.5c-1,1-2.2,1.8-3.5,2.6c-0.5,0.3-1,0.6-1.5,0.9
	c-0.3,0.2-0.5,0.6-0.6,1c-0.1-0.4-0.1-0.9-0.2-1.3c0,0,0-0.1,0-0.1C10.5,12,11.8,11.4,12.8,10.9z M11.1,14.7c0-0.2,0.1-0.5,0.1-0.7
	c0.1,0.1,0.3,0.2,0.5,0.3C11.5,14.4,11.3,14.6,11.1,14.7z M15.4,15.3c0,0.2-0.1,0.3-0.1,0.5c-0.4-0.4-1.1-0.8-2.1-1.5
	c-0.5-0.4-1-0.7-1.3-0.9c0.3-0.2,0.6-0.4,1-0.6c1.4-0.9,2.8-1.7,3.9-3C16.2,12,15.7,14,15.4,15.3z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PaperPlaneIcon;
