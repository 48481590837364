import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useTitle from 'react-use/lib/useTitle';
import qs from 'qs';

import { SECURE_API } from 'src/api/api';
import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import ResetPasswordForm, { IFormResetPassword } from './forms/ResetPasswordForm';

export interface ResetPasswordRouteParams {
  token: string;
}

export default function ForgotPassword() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const navigate = useNavigate();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });

  useTitle(`${t('account.resetPassword.pageTitle')} - ${t('base.defaultTitle')}`);

  useEffect(() => {
    if (!!auth.user) {
      navigate('/dashboard/applicants', { replace: true });
    }
    if (!token) {
      navigate('/account/signin', { replace: true });
    }
  }, [auth.user, navigate, token]);

  const handleSubmit = (payload: IFormResetPassword) => {
    SECURE_API.post<null>(`resetPassword/${token}`, {
      ...payload,
      lang: currentLang,
    })
      .then(() => {
        enqueueSnackbar(t('account.resetPassword.messages.resetSuccessful'));
        if (!token) {
          navigate('/account/signin', { replace: true });
        }
      })
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`account.resetPassword.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      );
  };

  return (
    <PageBox sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Grid item xs={false} sm={4} md={7} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Typography component="h2" variant="h2" mb={4}>
              {t('account.resetPassword.pageTitle')}
            </Typography>
            <ResetPasswordForm handleSave={handleSubmit} />
            <Grid container>
              <Grid item xs>
                <Link color="secondary" href="/account/signin" variant="body2">
                  {t('account.resetPassword.backToSignin')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
}
