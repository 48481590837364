import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SignUpIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        opacity="0.5"
        d="M26.6,47.2c-0.3,0-0.6-0.1-0.8-0.2c-0.6-0.4-0.8-1.2-0.4-1.8c0.2-0.3,0.5-0.5,0.9-0.6c0.2-0.2,0.3-0.3,0.5-0.5
	l0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.6c0.9-0.7,2.2-0.6,3,0.2c0.3,0.4,0.8,0.7,1.6,1.1c0.2,0.1,0.2,0.1,0.2,0.1
	c0.3-0.3,0.6-0.5,0.9-0.7c0.4-0.2,0.9-0.3,1.4-0.2c0.2,0,0.3,0.1,0.5,0.1c0.2-0.7,0.8-1.1,1.5-0.9c0.5,0.1,1,0.5,1.2,1
	c0.2,0.6,0.1,1.1-0.2,1.6c-0.7,0.9-1.7,1.2-2.7,1c-0.2,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.6,0.5-1.6,1.1-3,0.4c-0.6-0.3-1.4-0.7-2.1-1.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3L28.5,46
	c-0.3,0.3-0.6,0.6-0.9,0.8C27.3,47.1,27,47.2,26.6,47.2z M34.4,46C34.4,46,34.4,46,34.4,46C34.4,46,34.4,46,34.4,46z M34.8,46
	C34.8,46,34.8,46,34.8,46C34.8,46,34.8,46,34.8,46z M36.4,45.2L36.4,45.2L36.4,45.2z M29.4,45.1C29.4,45.1,29.4,45.1,29.4,45.1
	C29.4,45.1,29.4,45.1,29.4,45.1z M28.9,45C28.9,45,28.9,45,28.9,45C28.9,45,28.9,45,28.9,45z M27.1,44.8
	C27.1,44.8,27.1,44.8,27.1,44.8C27.1,44.8,27.1,44.8,27.1,44.8z M27.1,44.8C27.1,44.8,27.1,44.8,27.1,44.8
	C27.1,44.8,27.1,44.8,27.1,44.8z"
        fill="currentColor"
      />
      <g>
        <path
          d="M22.3,20.2c0.5,4.1,2.8,6.7,6.1,7.2c0.5,0.1,1,0.1,1.5,0.1c5.4,0,9.3-3.9,10.4-7.9c1.2-4.6-1.1-8.7-5.8-10.6
		c-3.8-1.5-8.3-0.2-10.8,1.8c-0.5,0.4-0.6,1.3-0.2,1.8c0,0,0.1,0.1,0.1,0.1C22.4,14.9,22,17.7,22.3,20.2z M27.9,12.1
		c0.4-0.2,0.7-0.5,0.8-0.9c1.6-0.4,3.4-0.4,4.9,0.2c3.5,1.4,5.1,4.2,4.3,7.6c-0.9,3.4-4.3,6.5-9.1,5.9c-3-0.4-3.7-3.6-3.9-5
		C24.4,16.4,25.8,12.9,27.9,12.1z"
          fill="currentColor"
        />
        <path
          d="M47.5,52.6l-0.1-0.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.9-3-2.2-5.8-3.4-8.5c0.2-0.4,0.3-0.9,0.5-1.5l0.1-0.2
		c0.1-0.2,0.5-1,1-1.8c2.8-5.2,3.5-6.9,3.5-7.7c0-1.9-6.5-8.6-6.6-8.7c-0.3-0.2-0.5-0.5-0.8-0.6c0,0,0,0,0,0c0,0,0,0-0.1,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1
		C40.1,23,40,23,40,23.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-2.6,5.5-3.5,10.8c-0.6,0-1.2,0.1-1.8,0.1
		c-0.4,0-0.8,0.1-1.2,0.1l-0.2,0c-0.5,0-1.1,0.1-1.6,0.1c-1.2,0-2.4,0.1-3.6,0.1l-2.4,0.1c-0.2,0-0.3,0-0.5,0c0,0,0,0,0,0
		c0-0.2-0.5-2.3-0.7-2.9c-0.5-1.6-0.9-3.3-1.1-4.9l0-0.3c-0.2-1.1-0.4-2.2-0.9-3.3c-0.2-0.4-0.7-0.7-1.2-0.8c-0.5,0-1,0.2-1.2,0.5
		c-0.1,0.2-0.3,0.4-0.5,0.7c-0.8,1.7-1.6,3.4-2.4,5l-1.4,3c-0.4,0.7-0.3,1.6,0.1,2.3c0.2,0.3,0.4,0.7,0.6,1c0.9,1.4,1.8,2.9,2.7,4.3
		c0.1,0.1,0.3,0.4,1,1.2c-0.7,2.4-1.6,5.1-1.8,5.8c-0.5,1.5-0.8,3-1.2,4.4c-0.1,0.7-0.3,1.3-0.5,2c-0.1,0.6,0,1.2,0.3,1.6
		c0.4,0.5,0.9,0.8,1.5,0.8c0.3,0,2.7,0,7.4,0.1c6.6,0.1,16.7,0.1,17.8,0.2c0,0,0,0,0.1,0c0.7,0,1.2-0.5,1.3-1.2
		c0-0.7-0.5-1.3-1.2-1.3C42.3,53.1,33,53,25.5,52.9c-2.8,0-5.4,0-6.7-0.1c0.1-0.4,0.2-0.9,0.3-1.3c0.3-1.5,0.6-2.8,1.1-4.2
		c0.2-0.5,0.7-2.3,1.3-4.3c0.4,0.4,0.7,0.9,1.2,1.4c0.7,0.9,1.3,1.6,1.4,1.7c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0.5c0.1,0.6,0.7,1,1.3,1
		c0.1,0,0.2,0,0.2,0c0.7-0.1,1.2-0.8,1-1.5l-1.1-5.9c-0.1-0.7-0.8-1.2-1.5-1c-0.7,0.1-1.2,0.8-1,1.5l0.1,0.3
		c-0.3-0.4-0.6-0.8-0.9-1.1c0.4-1.2,0.6-2.2,0.7-2.6c0.2-0.7-0.3-1.4-1-1.5c-0.7-0.2-1.4,0.3-1.5,1c0,0.2-0.1,0.4-0.2,0.7
		c-0.8-1.3-1.6-2.5-2.4-3.8c-0.2-0.3-0.3-0.5-0.5-0.9l1.4-3c0.4-0.9,0.9-1.9,1.3-2.8l0,0.2c0.3,1.7,0.6,3.5,1.2,5.3
		c0.1,0.3,0.5,2,0.6,2.6c0.1,0.5,0.6,0.9,1.1,1c0.1,0.7,0.7,1.1,1.4,1c0.2,0,0.4,0,0.6-0.1l2.4-0.1c1.2,0,2.4-0.1,3.6-0.1
		c0.6,0,1.2-0.1,1.7-0.1l0.2,0c0.4,0,0.8-0.1,1.3-0.1c0.8-0.1,1.6-0.1,2.4-0.2l0.6,0c0.3,0,0.6,0,0.7,0c0,0.1,0.1,0.3,0.1,0.4
		c0.1,0.2,0.2,0.5,0.3,0.8l0,0.1c0,0.1,0.1,0.2,0.1,0.2c0.2,0.5,0.4,0.9,0.6,1.4c0.4,1.1,0.9,2.3,1.4,3.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0,0.4,0.1,0.7,0.4,1c0,0,0.1,0.1,0.1,0.1c1.2,2.6,2.4,5.3,3.3,8.1c0.1,0.2,0.2,0.5,0.3,0.7l0.1,0.2c0.1,0.2,0.1,0.3,0.2,0.4
		c0.2,0.4,0.7,0.6,1.1,0.6c0.2,0,0.4-0.1,0.6-0.2c0.6-0.4,0.8-1.1,0.5-1.7C47.6,52.7,47.5,52.6,47.5,52.6z M38.8,34.3
		c0.6-3.2,1.8-6.3,2.5-8.1c1.7,1.8,4.5,5.1,5,6.1c-0.3,1.1-2.3,4.8-3.2,6.4c-0.3,0.6-0.6,1.1-0.7,1.4c-0.2-0.4-0.4-0.9-0.5-1.3
		c-0.2-0.5-0.4-0.9-0.6-1.4l0-0.1c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.3-0.7C40.3,34.8,39.8,34.4,38.8,34.3z"
          fill="currentColor"
        />
      </g>
    </g>
  </SvgIcon>
);

export default SignUpIcon;
