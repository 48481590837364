import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TelekiIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M46.9,37.3c-0.5-0.5-1.3-0.5-1.8,0c-0.9,0.8-3.5,3.3-9.6,0.2c-0.9-0.6-2-0.6-3,0c-0.2,0.1-0.3,0.3-0.4,0.5
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.9-0.6-2.1-0.1-4.1,0.6c-4,1.5-7.4,2.4-8.7-0.5c-0.3-0.7-1.1-1-1.7-0.7c-0.7,0.3-1,1.1-0.7,1.7
		c1.2,2.6,3.2,3.4,5.4,3.4c2.3,0,4.8-0.9,6.6-1.6c0.6-0.2,1.4-0.5,1.9-0.6c0.2,0.2,0.5,0.3,0.9,0.3c0.5-0.1,0.8-0.4,1-0.8
		c0.4,0.4,1.1,0.5,1.6,0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c1.9,1,4.2,1.8,6.6,1.8c2.1,0,4.1-0.6,6-2.5
		C47.4,38.6,47.4,37.8,46.9,37.3z"
        fill="currentColor"
      />
      <path
        d="M38.9,42c-0.7,0.2-1.1,0.8-0.9,1.5c0.1,0.9-0.4,4-1.1,4.6c-0.2-0.3-0.3-0.8-0.4-1.1c-0.1-0.8-0.4-1.6-1.2-1.8
		c-0.5-0.1-1,0-1.4,0.4c-0.8,0.9-0.7,2.2-0.6,3.2c0.1,0.4,0.1,1.1,0,1.4c-0.2-0.1-0.6-0.4-0.7-0.5c-0.1-0.3-0.1-0.7-0.1-1.2
		c0-0.7,0-1.7-0.5-2.5c-0.3-0.4-0.8-0.7-1.3-0.6c-0.9,0.1-1.1,0.8-1.6,2.3c-0.1,0.4-0.4,1.1-0.6,1.7c0-0.5-0.1-1.3,0-2.5
		c0-0.2,0-0.4,0-0.5c0-1.1-0.8-1.4-1-1.5c-1-0.3-1.7,0.5-2.4,1.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-1-0.4-2.2-0.4-2.6
		c0.1-0.7-0.4-1.3-1-1.4c-0.7-0.1-1.4,0.3-1.5,1c-0.2,0.7,0.4,4.6,1.3,5.7c0.4,0.5,0.9,0.6,1.2,0.6c0.6,0,1.1-0.3,1.6-0.8
		c0.2,1.5,0.7,3,2.3,3.3c1.1,0.2,1.8-0.5,2.3-1.6c0.5,0.8,1.7,1.7,2.9,1.7c0.3,0,0.6-0.1,0.9-0.2c1-0.4,1.4-1.2,1.6-2
		c0.3,0.2,0.7,0.3,1.2,0.2c0.5-0.1,1.3-0.4,2-1.4c1.2-1.8,1.5-5.5,1.3-6.4C40.3,42.3,39.6,41.8,38.9,42z"
        fill="currentColor"
      />
      <path
        d="M16.8,33.3c0.5,0.3,1,0.5,1.6,0.5c0.7,0,1.4-0.2,2.1-0.7c0.6-0.4,0.7-1.2,0.3-1.8c-0.4-0.6-1.2-0.7-1.8-0.3
		c-0.1,0.1-0.5,0.3-0.6,0.2c-0.2-0.2-0.5-0.8-0.3-1.3c0.1-0.3,0.2-0.6,0.9-0.6s1.2-0.6,1.2-1.3s-0.6-1.3-1.3-1.2
		c-1.6,0-2.9,0.9-3.3,2.4C15.1,30.7,15.6,32.5,16.8,33.3z"
        fill="currentColor"
      />
      <path
        d="M43.4,28.1c0.1,0.7,0.7,1.2,1.4,1.1c0.4,0,0.5,0.1,0.5,0.2c0.2,0.3,0.1,0.9-0.1,1.1c-0.1,0.1-0.2,0.2-0.5,0.1
		c-0.7-0.2-1.4,0.1-1.6,0.8c-0.2,0.7,0.1,1.4,0.8,1.6c0.3,0.1,0.7,0.2,1,0.2c0.9,0,1.7-0.3,2.2-1.1c0.9-1,1-2.7,0.4-3.9
		c-0.6-1.1-1.7-1.6-3-1.5C43.8,26.8,43.3,27.4,43.4,28.1z"
        fill="currentColor"
      />
      <path
        d="M17.4,24.4c0.2,0,0.5-0.1,0.6-0.3c0.6-0.4,0.7-1.2,0.3-1.8c-0.6-0.8,0-4.5,1.4-6c0.5-0.5,0.8-0.4,1.1-0.2
		c0.6,0.4,1.4,0.3,1.8-0.3c0.2-0.3,0.3-0.7,0.2-1c0.1-0.1,0.2-0.3,0.2-0.5c0.7-1.8,1.9-3,3.8-3.5c4.6-1.3,11.3,1.6,12.1,2.3
		c0.1,0.1,0.2,0.2,0.3,0.2c-0.3,0-0.5,0-0.7,0h-0.1c-2.6,0.5-3.9,1.8-4.7,2.7l-0.2,0.2c-0.4,0.5-0.4,1.3,0.1,1.8
		c0.5,0.4,1.3,0.4,1.8-0.1l0.2-0.2c0.7-0.8,1.5-1.6,3.2-2c0.9,0,1.9,0.5,2.8,1.4c1.3,1.4,2.5,4.1,2,7.5c-0.1,0.7,0.4,1.4,1.1,1.5
		c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.5,1.1-0.9c0.6-3.6-0.4-7.3-2.6-9.7c-1-1.1-2.3-1.8-3.5-2.1c0.3,0,0.6-0.2,0.8-0.4
		c0.5-0.5,0.4-1.3-0.1-1.8c-1.3-1.2-8.8-4.3-14.3-2.9c-2.8,0.7-4.7,2.4-5.7,5.1c-0.9-0.1-1.8,0.3-2.6,1.1c-2,2-3.2,7.1-1.7,9.3
		C16.6,24.2,17,24.4,17.4,24.4z"
        fill="currentColor"
      />
      <path
        d="M58.7,56.5c-1.3-1.7-7.8-10.3-16-11.3c-0.7-0.1-1.3,0.4-1.4,1.1c-0.1,0.7,0.4,1.3,1.1,1.4c5.6,0.6,11.1,5.9,14.2,10.2
		c0.2,0.3,0.6,0.5,1,0.5c0.3,0,0.5,0,0.8-0.1C59,57.9,59.1,57.1,58.7,56.5z"
        fill="currentColor"
      />
      <path
        d="M19.5,45.3c-9.7,3.1-12,7.1-14.1,12.3c-0.3,0.7,0,1.4,0.7,1.7c0.2,0.1,0.3,0.1,0.5,0.1c0.5,0,0.9-0.3,1.2-0.9
		c1.7-4.2,3.2-7.9,12.5-10.8c0.6-0.2,1-0.9,0.8-1.6C20.9,45.5,20.2,45.1,19.5,45.3z"
        fill="currentColor"
      />
      <path
        d="M38.4,27.4c0.1,0,0.2,0,0.3,0c0.5,0,1.1-0.4,1.2-0.9c0.2-0.7-0.2-1.4-0.9-1.6c-0.3-0.1-0.7-0.2-1.1-0.3
		c-1.9-0.5-4.1-1.2-5.9,0.1c-0.5,0.4-0.6,1.2-0.2,1.8c0.4,0.5,1.2,0.6,1.8,0.2c0.7-0.6,2.2-0.1,3.6,0.3C37.6,27.2,38,27.3,38.4,27.4
		z"
        fill="currentColor"
      />
      <path
        d="M29.6,26.5c0.5-0.5,0.5-1.3,0-1.8c-1.8-1.7-4.4-0.7-6.3,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.7,0.2-1,0.9-0.8,1.6
		c0.2,0.5,0.7,0.8,1.2,0.8c0.2,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3c1.3-0.5,3-1.2,3.6-0.6C28.3,27,29.1,27,29.6,26.5z"
        fill="currentColor"
      />
      <path
        d="M33.8,30.9c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,0.9-0.3,1.1-0.6c0.6-1.1,0.4-2.5,0-3.2c-0.3-0.6-1.1-0.8-1.7-0.5
		c-0.6,0.3-0.8,1.1-0.5,1.7c0.1,0.1,0.1,0.6,0,0.7C33,29.8,33.2,30.5,33.8,30.9z"
        fill="currentColor"
      />
      <path
        d="M27.1,26.6c-0.7,0.1-1.3,0.7-1.2,1.4c0.1,0.6,0.1,1.3,0.1,2s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.2c0-0.8,0-1.6-0.1-2.3
		S27.8,26.5,27.1,26.6z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default TelekiIcon;
