import { MouseEvent } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash-es/map';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { THeadCell } from 'src/interfaces/Table';
import TechnicalTypeTableRow from './TechnicalTypeTableRow';
import { TechnicalType } from 'src/interfaces/TechnicalType';

export interface Props {
  items: (TechnicalType & {
    icon?: string;
  })[];
  onDelete: (id: string, e?: MouseEvent<unknown>) => void;
  onEdit: (id: string, e?: MouseEvent<unknown>) => void;
}

const TechnicalTypesTable = ({ onDelete, onEdit, items }: Props) => {
  const { t } = useTranslation();

  const headCells: readonly THeadCell<{}>[] = [
    {
      id: 'name',
      label: t('technicalTypes.nameLabel'),
      width: '220px',
    },
    {
      id: 'distance',
      label: t('technicalTypes.translatedName'),
    },
  ];

  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 2, p: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 950 }}
          aria-label={t('technicalTypes.technicalTypesTableLabel')}
          size="medium"
        >
          <TableHead<{}>
            withOptions
            optionsLabel={t('technicalTypes.optionsLabel')}
            optionsCellWidth="120px"
            headCells={headCells}
          />
          <TableBody>
            {map(items, item => (
              <TechnicalTypeTableRow
                key={item.id}
                item={item}
                optionElement={
                  <>
                    <Tooltip title={t('technicalTypes.delete')}>
                      <IconButton onClick={e => onDelete(item.id, e)} color="error">
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('technicalTypes.edit')}>
                      <IconButton size="small" onClick={e => onEdit(item.id, e)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TechnicalTypesTable;
