import { MouseEvent } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import find from 'lodash-es/find';
import { LanguageKeys } from 'src/common/constants/languages';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { ITrackMap } from 'src/interfaces/TrackMap';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

interface Props {
  trackMap: ITrackMap;
  onDeleteTrackMap: (trackMapId: string, e?: MouseEvent<unknown>) => void;
  onEditTrackMap: (trackMapId: string, e?: MouseEvent<unknown>) => void;
}

const TrackMapCard = ({
  trackMap: { distance: distanceId, fileName, id, levelDiff, name, route, time, totalDistance },
  onDeleteTrackMap,
  onEditTrackMap,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { palette } = useTheme();
  const { distances } = useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const distance = find(distances, d => d.id === distanceId) as TechnicalType;
  const distanceName = distance.i18n[currentLang];

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderTopLeftRadius: 40,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              elevation: 1,
              background: `linear-gradient(135deg, ${palette.success.dark} 0%, ${palette.success.light} 100%)`,
              width: 56,
              height: 56,
              mt: 3.5,
              mb: -3.5,
            }}
            aria-label="recipe"
          >
            {distanceName}
          </Avatar>
        }
        action={
          <>
            <Tooltip title={t('system.trackMaps.deleteTrackMap')}>
              <IconButton onClick={e => onDeleteTrackMap(id, e)} color="error">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('system.trackMaps.editTrackMap')}>
              <IconButton onClick={e => onEditTrackMap(id, e)} color="secondary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        }
        title={name}
        sx={{ pt: 1, pb: 0, borderBottom: `1px solid ${grey[600]}` }}
      />
      <CardContent sx={{ mt: 3, pt: 1, pb: 0, flexGrow: 1 }}>
        <Typography variant="body2" color={grey[600]}>
          {t('system.trackMaps.routeLabel')}:
        </Typography>
        <Typography variant="body2" component="div">
          {route.i18n[currentLang]}
        </Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('system.trackMaps.routeLengthLabel')}:
        </Typography>
        <Typography variant="body2">
          {t('system.trackMaps.totalDistance', {
            dist: totalDistance,
          })}
        </Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('system.trackMaps.levelLabel')}:
        </Typography>
        <Typography variant="body2">
          {t('system.trackMaps.level', { pos: levelDiff.pos, neg: levelDiff.neg })}
        </Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('system.trackMaps.timeLabel')}:
        </Typography>
        <Typography variant="body2">{t('timers.times.hour', { count: time })}</Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('system.trackMaps.fileName')}:
        </Typography>
        <Typography variant="body2">{fileName || '-'}</Typography>
      </CardContent>
    </Card>
  );
};

export default TrackMapCard;
