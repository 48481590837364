import MUIBox, { BoxProps } from '@mui/material/Box';

import Loader from 'src/common/components/Loader';

type Props = BoxProps & {
  isLoading?: boolean;
};

const PageBox = ({ isLoading, children, sx, ...props }: Props) => {
  return (
    <MUIBox
      sx={{
        position: 'relative',
        width: '100%',
        mb: 2,
        ...sx,
      }}
      {...props}
    >
      {isLoading ? <Loader isLoading={isLoading} height="300px" /> : children}
    </MUIBox>
  );
};

export default PageBox;
