import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { find, map } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import TechnicalTypeCard from 'src/common/components/TechnicalTypeCard';
import ViewSwitch from 'src/common/components/ViewSwitch';
import AppBar from 'src/common/components/styled/AppBar';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';
import TechnicalTypeEditorModal from 'src/common/modals/TechnicalTypeEditorModal';
import TechnicalTypesTable from 'src/common/tables/TechnicalTypesTable';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { EView } from 'src/interfaces/View';
import systemOptionsStore from 'src/stores/systemOptionsStore';

const Accommodations = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('system.accommodations.pageTitle')} - ${t('base.defaultTitle')}`);

  const {
    accommodations,
    deleteAccommodation: doDeleteAccommodation,
    deleteAllAccommodations: doDeleteAllAccommodations,
    fetchAccommodations: doFetchAccommodations,
    isLoading,
    saveAccommodation: doSaveAccommodation,
  } = systemOptionsStore(systemOptionsState => systemOptionsState);
  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<EView>((lsView as EView) || EView.grid);
  const [accommodationEditorModalOpen, setAccommodationEditorModalOpen] = useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = useState<TechnicalType | undefined>(
    undefined,
  );

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const handleOpenAccommodationEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const thisTrack = find(accommodations, { id });
    setSelectedAccommodation(thisTrack);
    setAccommodationEditorModalOpen(true);
  };

  const handleCloseAccommodationsEditorModal = () => {
    setSelectedAccommodation(undefined);
    setAccommodationEditorModalOpen(false);
  };

  const handleDeleteAllAccommodations = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.accommodations.deleteAllConfirm'),
        description: t('system.accommodations.deleteDescription'),
        confirmationText: t('system.accommodations.confirmationText'),
        cancellationText: t('system.accommodations.cancellationText'),
      });
      await doDeleteAllAccommodations()
        .then(() => {
          enqueueSnackbar(t('system.accommodations.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`accommodations.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('system.accommodations.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteAccommodation = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.accommodations.deleteConfirm'),
        description: t('system.accommodations.deleteDescription'),
        confirmationText: t('system.accommodations.confirmationText'),
        cancellationText: t('system.accommodations.cancellationText'),
      });
      await doDeleteAccommodation(id);

      enqueueSnackbar(t('system.accommodations.deleteSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('system.accommodations.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const saveAccommodation = async (accommodation: FormTechnicalType, id?: string) => {
    await doSaveAccommodation(accommodation, id);
    doFetchAccommodations();
    enqueueSnackbar(t('system.accommodations.saveAccommodationSuccess'), {
      variant: 'success',
    });
    handleCloseAccommodationsEditorModal();
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {t('system.accommodations.pageTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenAccommodationEditorModal()}
                startIcon={<AddIcon />}
              >
                {t('system.accommodations.new')}
              </Button>
              {isSuperAdmin ? (
                <Tooltip title={t('system.accommodations.deleteAllAccommodations')}>
                  <IconButton onClick={handleDeleteAllAccommodations} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ViewSwitch view={view} onSetView={setView} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoading}>
        {!!accommodations.length && (
          <>
            {view === EView.grid && (
              <Grid container spacing={2}>
                {map(accommodations, accommodation => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={accommodation.id}>
                    <TechnicalTypeCard
                      item={accommodation}
                      defaultIcon="tent"
                      onDelete={handleDeleteAccommodation}
                      onEdit={handleOpenAccommodationEditorModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === EView.table && (
              <TechnicalTypesTable
                items={accommodations}
                onDelete={handleDeleteAccommodation}
                onEdit={handleOpenAccommodationEditorModal}
              />
            )}
          </>
        )}
      </PageBox>

      {accommodationEditorModalOpen && (
        <TechnicalTypeEditorModal
          id="accommodation-editor"
          open={accommodationEditorModalOpen}
          handleClose={handleCloseAccommodationsEditorModal}
          handleSave={saveAccommodation}
          item={selectedAccommodation}
        />
      )}
    </>
  );
};

export default Accommodations;
