import { TFunction } from 'i18next';

export const minutesToHours = (
  mins: number = 0,
  translate: TFunction<'translation', undefined>,
) => {
  // if (mins < 0) {
  //   throw new RangeError('A szám pozitív kell legyen');
  // }
  const days = (mins / (60 * 24)) | 0;
  const hours = (mins / 60) | 0;
  const minutes = mins % 60 | 0;

  return `${days ? `${translate('timers.times.day', { count: days })} ` : ''}${
    hours ? `${translate('timers.times.hour', { count: hours })} ` : ''
  }${minutes ? translate('timers.times.minute', { count: minutes }) : ''}`;
};
