import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface SelectOption {
  label: string;
  value: string | number | readonly string[] | undefined;
  disabled?: boolean;
}

type Props<T extends FieldValues, C> = UseControllerProps<T> & {
  control: Control<T, C>;
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  margin?: 'dense' | 'normal' | 'none';
  multiple?: boolean;
  name: FieldPath<T>;
  options?: SelectOption[];
  renderOptions?: () => JSX.Element[];
  renderValue?: (value: PathValue<T, FieldPath<T>>) => React.ReactNode;
  withEmptyOption?: boolean;
};

// Use: <SelectField<TFieldValues, TContext> />
export const ControlledSelectField = <T extends FieldValues, C extends unknown = any>({
  control,
  disabled,
  fullWidth,
  label,
  margin,
  multiple,
  name,
  options,
  renderOptions,
  renderValue,
  withEmptyOption,
}: Props<T, C>) => {
  const { t } = useTranslation();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error, invalid },
  } = useController<T>({
    name,
    control,
  });

  const generateOptions = (o: SelectOption[]) => {
    return o.map(singleOption => (
      <MenuItem
        key={JSON.stringify(singleOption.value)}
        disabled={singleOption.disabled}
        value={singleOption.value}
      >
        {singleOption.label}
      </MenuItem>
    ));
  };

  if (!options && !renderOptions) return null;

  return (
    <FormControl disabled={disabled} margin={margin} error={invalid} fullWidth={fullWidth}>
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        inputRef={ref}
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        label={label}
        onChange={newValue => onChange(newValue as PathValue<T, FieldPath<T>>)}
        onBlur={onBlur}
        value={value}
        multiple={multiple}
        renderValue={renderValue}
      >
        {withEmptyOption && (
          <MenuItem value="" disabled>
            <em>{t('form.pleaseSelect')}</em>
          </MenuItem>
        )}
        {options ? generateOptions(options) : renderOptions ? renderOptions() : null}
      </Select>
      {invalid && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
};
