import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PDFIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      d=" M 8.53 22.528 C 7.578 21.947 6.454 21.715 5.35 21.872 L 2.541 21.872 L 2.541 31.966 L 4.778 31.966 L 4.778 28.113 L 5.737 28.113 C 6.731 28.215 7.729 27.972 8.564 27.424 C 9.272 26.816 9.663 25.917 9.624 24.984 C 9.676 24.037 9.269 23.123 8.53 22.528 L 8.53 22.528 Z  M 6.73 26.212 C 6.307 26.398 5.842 26.468 5.384 26.414 L 4.728 26.414 L 4.728 23.554 L 5.384 23.554 C 5.86 23.503 6.341 23.597 6.763 23.823 C 7.157 24.097 7.38 24.556 7.352 25.035 C 7.403 25.517 7.157 25.983 6.73 26.212 L 6.73 26.212 Z  M 14.435 21.872 L 11.643 21.872 L 11.643 31.966 L 14.318 31.966 C 15.681 32.1 17.054 31.837 18.271 31.209 C 19.611 30.211 20.327 28.581 20.156 26.919 C 20.256 25.495 19.749 24.095 18.759 23.066 C 17.537 22.103 15.979 21.672 14.435 21.872 L 14.435 21.872 Z  M 16.656 29.678 C 15.955 30.072 15.153 30.248 14.351 30.183 L 13.914 30.183 L 13.914 23.655 L 14.318 23.655 C 15.731 23.655 16.286 23.79 16.841 24.294 C 17.515 24.996 17.863 25.948 17.8 26.919 C 17.887 27.969 17.461 28.997 16.656 29.678 L 16.656 29.678 Z  M 22.612 31.966 L 24.883 31.966 L 24.883 27.743 L 29.459 27.743 L 29.459 25.96 L 24.883 25.96 L 24.883 23.655 L 29.459 23.655 L 29.459 21.872 L 22.612 21.872 L 22.612 31.966 L 22.612 31.966 Z  M 21.047 0 L 2.541 0 L 2.541 18.507 L 5.905 18.507 L 5.905 15.142 L 5.905 13.375 L 5.905 3.365 L 19.651 3.365 L 26.095 9.809 L 26.095 13.375 L 26.095 15.142 L 26.095 18.507 L 29.459 18.507 L 29.459 8.412 L 21.047 0 L 21.047 0 Z "
      fill-rule="evenodd"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PDFIcon;
