import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TargetIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M13.6,11.2c0,0-1-1.1-1.9-2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7c0.3,0.2,0.6,0.5,0.8,0.8
	C10.4,10.1,9,9.5,7.6,8.9c-1.1-0.5-2.3-1-3.4-1.5c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2,0,0.5,0.2,0.6c1.1,0.5,2.3,1,3.4,1.5
	c1.4,0.6,2.8,1.2,4.2,1.8c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.2-0.4,0.5c0,0.3,0.2,0.4,0.5,0.4c0,0,0,0,0,0c1.5-0.1,2.3-0.7,2.3-0.7
	c0.1-0.1,0.2-0.2,0.2-0.3C13.7,11.5,13.7,11.3,13.6,11.2z"
      fill="currentColor"
    />
    <g opacity="0.5">
      <path
        d="M14.5,12.8C14.5,12.8,14.5,12.8,14.5,12.8c-0.4,0-0.7-0.3-0.8-0.8c-0.1-0.2,0-0.5,0.2-0.6c0-0.2,0.1-0.4,0.3-0.5
		c0.5-0.2,0.8,0,0.9,0.1c0.3,0.3,0.3,0.9,0.1,1.2C15,12.7,14.8,12.8,14.5,12.8z"
        fill="currentColor"
      />
      <path
        d="M14.7,15.5c-0.2,0-0.5,0-0.7-0.1c-1.2-0.3-2.4-1.5-2.2-3.3c0-0.3,0.3-0.5,0.5-0.4c0.3,0,0.5,0.3,0.4,0.5
		c-0.1,1.3,0.7,2.1,1.5,2.3c0.7,0.2,1.7,0,2.2-1.2c0.9-2,0.2-3.8-1-4.4c-1.1-0.5-2.1,0.1-2.8,1.6c-0.1,0.2-0.4,0.3-0.6,0.2
		c-0.2-0.1-0.3-0.4-0.2-0.6c1.1-2.4,2.9-2.6,4.1-2.1c1.6,0.8,2.7,3.1,1.5,5.6C16.8,14.9,15.8,15.5,14.7,15.5z"
        fill="currentColor"
      />
      <path
        d="M15.2,18.7c-1.2,0-2.5-0.4-3.6-1.2c-2-1.3-3.6-3.8-2.8-6.4c0.1-0.3,0.4-0.4,0.6-0.3c0.3,0.1,0.4,0.4,0.3,0.6
		c-0.7,2.1,0.7,4.2,2.4,5.3c1.5,1,3.9,1.6,5.6,0.1c1.4-1.2,2-3.4,1.8-5c-0.2-3.6-2.2-5.2-4-5.5c-1.8-0.3-4.1,0.8-5.2,3.8
		c-0.1,0.2-0.4,0.4-0.6,0.3c-0.2-0.1-0.4-0.4-0.3-0.6c1.3-3.5,4.1-4.8,6.3-4.5c2.7,0.4,4.6,2.9,4.8,6.3c0.1,1.8-0.5,4.4-2.2,5.8
		C17.4,18.3,16.4,18.7,15.2,18.7z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default TargetIcon;
