import CircularProgress from '@mui/material/CircularProgress';

import { LoaderBackdrop } from './styled/LoaderBackdrop';

export interface Props {
  blur?: string;
  height?: string;
  isLoading?: boolean;
}

const Loader = ({ blur, height, isLoading }: Props) => (
  <LoaderBackdrop $isLoading={isLoading} blur={blur} height={height}>
    <CircularProgress color="info" />
  </LoaderBackdrop>
);

export default Loader;
