import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { LanguageKeys } from 'src/common/constants/languages';

const renderFlag = (code: LanguageKeys) => {
  switch (code) {
    case LanguageKeys.hu:
      return (
        <g fillRule="evenodd">
          <path fill="#fff" d="M512 512H0V0h512z" />
          <path fill="#388d00" d="M512 512H0V341.3h512z" />
          <path fill="#d43516" d="M512 170.8H0V.1h512z" />
        </g>
      );
    case LanguageKeys.ro:
      return (
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#00319c" d="M0 0h170.7v512H0z" />
          <path fill="#ffde00" d="M170.7 0h170.6v512H170.7z" />
          <path fill="#de2110" d="M341.3 0H512v512H341.3z" />
        </g>
      );
    case LanguageKeys.en:
      return (
        <>
          <path fill="#bd3d44" d="M0 0h512v512H0" />
          <path
            stroke="#fff"
            strokeWidth="40"
            d="M0 58h512M0 137h512M0 216h512M0 295h512M0 374h512M0 453h512"
          />
          <path fill="#192f5d" d="M0 0h390v275H0z" />
          <marker id="a" markerHeight="30" markerWidth="30">
            <path fill="#fff" d="m15 0 9.3 28.6L0 11h30L5.7 28.6" />
          </marker>
          <path
            fill="none"
            d="m0 0 18 11h65 65 65 65 66L51 39h65 65 65 65L18 66h65 65 65 65 66L51 94h65 65 65 65L18 121h65 65 65 65 66L51 149h65 65 65 65L18 177h65 65 65 65 66L51 205h65 65 65 65L18 232h65 65 65 65 66L0 0"
          />
        </>
      );

    default:
      break;
  }
};

interface Props {
  code: LanguageKeys;
  viewBox?: string;
  svgProps?: SvgIconProps;
  width?: number | string;
  height?: number | string;
}

const Flag = ({
  code,
  viewBox = '0 0 512 512',
  svgProps = {},
  width = '1em',
  height = '1em',
}: Props) => (
  <SvgIcon {...svgProps} viewBox={viewBox} sx={{
    width: width,
    height: height,
  }}>
    {renderFlag(code)}
  </SvgIcon>
);

export default Flag;
