import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import { find, map, omit, orderBy } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';
import * as XLSX from 'xlsx';

import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import Icon from 'src/common/components/icons/Icon';
import AppBar from 'src/common/components/styled/AppBar';
import { LanguageKeys } from 'src/common/constants/languages';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { minutesToHours } from 'src/helpers/dateTimeFormatters';
import { checkForAllWords } from 'src/helpers/search';
import { EApplicantFilters, IApplicant } from 'src/interfaces/Applicant';
import { ICheckin } from 'src/interfaces/Checkin';
import { EOrder } from 'src/interfaces/Order';
import { EView } from 'src/interfaces/View';
import ApplicantCard from 'src/pages/Dashboard/Applicants/components/ApplicantCard';
import useApplicantsStore from 'src/stores/applicantsStore';
import useCheckinsStore from 'src/stores/checkinsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import { NAME } from './constants/applicantSortKeys';
import { IFormApplicant } from './forms/ApplicantEditorForm';
import ApplicantFilterForm from './forms/ApplicantFilterForm';
import { IFormCheckin } from './forms/CheckinForm';
import ApplicantEditorModal from './modals/ApplicantEditorModal';
import CheckinModal from './modals/CheckinModal';
import CheckinSerialModal from './modals/CheckinSerialModal';
import ApplicantsTable from './tables/ApplicantsTable';

const Applicants = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('applicants.pageTitle')} - ${t('base.defaultTitle')}`);

  const { accommodationsById, competitionsById, dateTimeFormat, distancesById, editionsById } =
    useSystemOptionsStore(systemOptionsState => systemOptionsState);
  const {
    checkinsById,
    fetchCheckins: doFetchCheckins,
    isLoadingCheckins,
    saveCheckin: doSaveCheckin,
  } = useCheckinsStore(checkinsState => checkinsState);
  const {
    applicants,
    deleteApplicant: doDeleteApplicant,
    deleteAllApplicants: doDeleteAllApplicants,
    fetchApplicants: doFetchApplicants,
    isLoadingApplicants,
    saveApplicant: doSaveApplicant,
  } = useApplicantsStore(applicantsState => applicantsState);
  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<EView>((lsView as EView) || EView.grid);
  const [newCheckin, setNewCheckin] = useState<ICheckin | undefined>();
  const [contestantEditorModalOpen, setApplicantEditorModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState<'no' | 'yes' | 'finished'>('no');
  const [checkinModalOpen, setCheckinModalOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<IApplicant | undefined>(undefined);
  const [isFiltering, setIsFiltering] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [competitionFilter, setCompetitionFilter] = useState<string | null>(null);
  const [distanceFilter, setDistanceFilter] = useState<string | null>(null);
  const [checkedInFilter, setCheckedInFilter] = useState<string | null>(null);
  const [order, setOrder] = useState<EOrder>(EOrder.asc);
  const [orderByKey, setOrderByKey] = useState<string>(NAME);
  const [sortedApplicants, setSortedApplicants] = useState<IApplicant[]>(applicants);

  useEffect(() => {
    setIsFiltering(true);
    setSortedApplicants(
      orderBy(
        applicants
          .filter(c =>
            !!query
              ? checkForAllWords(query, `${c.firstName}${c.lastName}`) ||
                !!checkForAllWords(query, c.email) ||
                !!checkForAllWords(query, c.phoneNumber)
              : true,
          )
          .filter(c => (!!competitionFilter ? c.competition === competitionFilter : true))
          .filter(c => (!!distanceFilter ? c.distance === distanceFilter : true))
          .filter(c =>
            !!checkedInFilter
              ? checkedInFilter === 'true'
                ? !!c.checkedIn
                : checkedInFilter === 'false'
                ? !c.checkedIn
                : true
              : true,
          ),
        orderByKey,
        order,
      ),
    );
    setIsFiltering(false);
  }, [applicants, query, checkedInFilter, competitionFilter, distanceFilter, order, orderByKey]);

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const onChangeFilters = (key: EApplicantFilters, value: string | null) => {
    switch (key) {
      case EApplicantFilters.query:
        setQuery(value || '');
        break;
      case EApplicantFilters.competition:
        setCompetitionFilter(value);
        break;
      case EApplicantFilters.distance:
        setDistanceFilter(value);
        break;
      case EApplicantFilters.checkedIn:
        setCheckedInFilter(value);
        break;
      default:
        break;
    }
  };

  const handleOpenApplicantEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const contestant = find(applicants, { id });
    setSelectedApplicant(contestant);
    setApplicantEditorModalOpen(true);
  };

  const handleCloseApplicantEditorModal = () => {
    setSelectedApplicant(undefined);
    setApplicantEditorModalOpen(false);
  };

  const saveApplicant = async (editedApplicant: IFormApplicant, id?: string) => {
    await doSaveApplicant(editedApplicant, id)
      .then(() =>
        enqueueSnackbar(t('applicants.saveSuccessful'), {
          variant: 'success',
        }),
      )
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`race.signUp.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      );
    handleCloseApplicantEditorModal();
  };

  const handleDeleteApplicant = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('applicants.deleteConfirm'),
        description: t('applicants.deleteDescription'),
        confirmationText: t('applicants.confirmationText'),
        cancellationText: t('applicants.cancellationText'),
      });
      await doDeleteApplicant(id)
        .then(() => {
          enqueueSnackbar(t('applicants.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`applicants.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('applicants.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteAllApplicants = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('applicants.deleteAllConfirm'),
        description: t('applicants.deleteDescription'),
        confirmationText: t('applicants.confirmationText'),
        cancellationText: t('applicants.cancellationText'),
      });
      await doDeleteAllApplicants()
        .then(() => {
          enqueueSnackbar(t('applicants.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`applicants.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('applicants.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleOpenCheckinModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const contestant = find(applicants, { id });
    setSelectedApplicant(contestant);
    setCheckinModalOpen(true);
  };

  const handleCloseCheckinModal = () => {
    setSelectedApplicant(undefined);
    setCheckinModalOpen(false);
  };

  const saveCheckin = async (checkin: IFormCheckin, id?: string) => {
    await doSaveCheckin(checkin, id)
      .then(newCheckin => {
        enqueueSnackbar(t('checkins.saveCheckinSuccess'), {
          variant: 'success',
        });
        setNewCheckin(newCheckin || undefined);
      })
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`checkins.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      )
      .finally(async () => {
        await Promise.all([doFetchApplicants(), doFetchCheckins()]);
        handleCloseCheckinModal();
      });
  };

  const exportToExcel = () => {
    setIsDownloading('yes');
    const data = applicants.map(c => {
      const updatedObject = {
        createdAt: moment().toDate(),
        updatedAt: moment().toDate(),
        ...omit(
          c,
          'discounted',
          'sportsGroupMember',
          'competition',
          'distance',
          'accommodation',
          'checkedIn',
          'onlineSignup',
        ),
        discounted: c.discounted ? '✔️' : '✖️',
        sportsGroupMember: c.sportsGroupMember ? '✔️' : '✖️',
        competition: competitionsById
          ? competitionsById[c.competition].i18n[currentLang]
          : c.competition,
        distance: distancesById ? distancesById[c.distance].i18n[currentLang] : c.distance,
        accommodation: accommodationsById
          ? accommodationsById[c.accommodation].i18n[currentLang]
          : c.accommodation,
        ...(c.checkedIn && checkinsById && editionsById
          ? {
              ...omit(
                checkinsById[c.checkedIn],
                'competition',
                'contestant',
                'createdAt',
                'distance',
                'id',
                'updatedAt',
              ),
              edition: editionsById[checkinsById[c.checkedIn].edition].year,
            }
          : {
              edition: undefined,
              ser: undefined,
              duration: undefined,
              startTime: undefined,
              finishTime: undefined,
            }),
        onlineSignup: c.onlineSignup ? '✔️' : '✖️',
      };
      const sortedObject = {
        ser: updatedObject.ser,
        lastName: updatedObject.lastName,
        firstName: updatedObject.firstName,
        email: updatedObject.email,
        phoneNumber: updatedObject.phoneNumber,
        country: updatedObject.country
          ? t(`base.countries.${updatedObject.country}`)
          : updatedObject.country,
        city: updatedObject.city,

        age: updatedObject.age,
        gender: updatedObject.gender
          ? t(`base.gender.${updatedObject.gender}`)
          : updatedObject.gender,

        discounted: updatedObject.discounted,
        sportsGroupMember: updatedObject.sportsGroupMember,
        sportsGroupName: updatedObject.sportsGroupName,
        price: t('base.currencyValue', { count: updatedObject.price }),
        onlineSignup: updatedObject.onlineSignup,

        createdAt: moment(updatedObject.createdAt).format(dateTimeFormat),
        updatedAt: moment(updatedObject.updatedAt).format(dateTimeFormat),

        competition: updatedObject.competition,
        distance: updatedObject.distance,
        accommodation: updatedObject.accommodation,

        edition: updatedObject.edition,
        duration: minutesToHours(updatedObject.duration, t),
        startTime: moment(updatedObject.startTime).format(dateTimeFormat),
        finishTime: moment(updatedObject.finishTime).format(dateTimeFormat),
      };
      return sortedObject;
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, t('drawer.applicants'));
    XLSX.utils.sheet_add_aoa(
      ws,
      [[...Object.keys(data.find(i => !!i.edition) || []).map(k => t(`export.${k}`))]],
      {
        origin: 'A1',
      },
    );
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const s2ab = (s: string) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${t('drawer.applicants')}.xlsx`,
    );
    setIsDownloading('finished');
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {`${t('applicants.pageTitle')} (${sortedApplicants.length})`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenApplicantEditorModal()}
                startIcon={<AddIcon />}
              >
                {t('applicants.new')}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={exportToExcel}
                startIcon={
                  isDownloading === 'yes' ? (
                    <DownloadingIcon />
                  ) : isDownloading === 'finished' ? (
                    <DownloadDoneIcon />
                  ) : (
                    <Icon icon="xml" />
                  )
                }
                sx={{ ml: 1 }}
              >
                {t('export.export')}
              </Button>
              {isSuperAdmin ? (
                <Tooltip title={t('applicants.deleteAllApplicants')}>
                  <IconButton onClick={handleDeleteAllApplicants} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ApplicantFilterForm
                checkedInFilter={checkedInFilter}
                competitionFilter={competitionFilter}
                distanceFilter={distanceFilter}
                onChange={onChangeFilters}
                onSetView={setView}
                query={query}
                view={view}
                order={order}
                setOrder={setOrder}
                orderByKey={orderByKey}
                setOrderByKey={setOrderByKey}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoadingApplicants || isLoadingCheckins || isFiltering}>
        {!!sortedApplicants.length && (
          <>
            {view === EView.grid && (
              <Grid container spacing={2}>
                {map(sortedApplicants, (applicant, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={applicant.id}>
                    <ApplicantCard
                      applicant={applicant}
                      checkin={handleOpenCheckinModal}
                      deleteApplicant={handleDeleteApplicant}
                      editApplicant={handleOpenApplicantEditorModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === EView.table && (
              <ApplicantsTable
                applicants={sortedApplicants}
                checkin={handleOpenCheckinModal}
                deleteApplicant={handleDeleteApplicant}
                editApplicant={handleOpenApplicantEditorModal}
              />
            )}
          </>
        )}
      </PageBox>

      <CheckinSerialModal
        open={!!newCheckin}
        handleClose={() => {
          setNewCheckin(undefined);
        }}
        serialNo={newCheckin?.ser}
      />

      {contestantEditorModalOpen && (
        <ApplicantEditorModal
          id="contestant-editor"
          open={contestantEditorModalOpen}
          handleClose={handleCloseApplicantEditorModal}
          handleSave={saveApplicant}
          selectedApplicant={selectedApplicant}
        />
      )}

      {checkinModalOpen && !!selectedApplicant && (
        <CheckinModal
          id="checkin"
          isLoading={isLoadingCheckins}
          open={checkinModalOpen}
          handleClose={handleCloseCheckinModal}
          handleSave={saveCheckin}
          selectedApplicant={selectedApplicant}
        />
      )}
    </>
  );
};

export default Applicants;
