import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { find, map } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import TechnicalTypeCard from 'src/common/components/TechnicalTypeCard';
import ViewSwitch from 'src/common/components/ViewSwitch';
import AppBar from 'src/common/components/styled/AppBar';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';
import TechnicalTypeEditorModal from 'src/common/modals/TechnicalTypeEditorModal';
import TechnicalTypesTable from 'src/common/tables/TechnicalTypesTable';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { EView } from 'src/interfaces/View';
import systemOptionsStore from 'src/stores/systemOptionsStore';

const Distances = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('system.distances.pageTitle')} - ${t('base.defaultTitle')}`);

  const {
    distances,
    deleteDistance: doDeleteDistance,
    deleteAllDistances: doDeleteAllDistances,
    fetchDistances: doFetchDistances,
    isLoading,
    saveDistance: doSaveDistance,
  } = systemOptionsStore(systemOptionsState => systemOptionsState);
  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<EView>((lsView as EView) || EView.grid);
  const [distanceEditorModalOpen, setDistanceEditorModalOpen] = useState(false);
  const [selectedDistance, setSelectedDistance] = useState<TechnicalType | undefined>(undefined);

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const handleOpenDistanceEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const thisTrack = find(distances, { id });
    setSelectedDistance(thisTrack);
    setDistanceEditorModalOpen(true);
  };

  const handleCloseDistancesEditorModal = () => {
    setSelectedDistance(undefined);
    setDistanceEditorModalOpen(false);
  };

  const handleDeleteAllDistances = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.distances.deleteAllConfirm'),
        description: t('system.distances.deleteDescription'),
        confirmationText: t('system.distances.confirmationText'),
        cancellationText: t('system.distances.cancellationText'),
      });
      await doDeleteAllDistances()
        .then(() => {
          enqueueSnackbar(t('system.distances.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`distances.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('system.distances.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteDistance = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.distances.deleteConfirm'),
        description: t('system.distances.deleteDescription'),
        confirmationText: t('system.distances.confirmationText'),
        cancellationText: t('system.distances.cancellationText'),
      });
      await doDeleteDistance(id);

      enqueueSnackbar(t('system.distances.deleteSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('system.distances.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const saveDistance = async (distance: FormTechnicalType, id?: string) => {
    await doSaveDistance(distance, id);
    doFetchDistances();
    enqueueSnackbar(t('system.distances.saveDistanceSuccess'), {
      variant: 'success',
    });
    handleCloseDistancesEditorModal();
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {t('system.distances.pageTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenDistanceEditorModal()}
                startIcon={<AddIcon />}
              >
                {t('system.distances.new')}
              </Button>
              {isSuperAdmin ? (
                <Tooltip title={t('system.distances.deleteAllDistances')}>
                  <IconButton onClick={handleDeleteAllDistances} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ViewSwitch view={view} onSetView={setView} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoading}>
        {!!distances.length && (
          <>
            {view === EView.grid && (
              <Grid container spacing={2}>
                {map(distances, distance => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={distance.id}>
                    <TechnicalTypeCard
                      item={distance}
                      defaultIcon="location"
                      onDelete={handleDeleteDistance}
                      onEdit={handleOpenDistanceEditorModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === EView.table && (
              <TechnicalTypesTable
                items={distances}
                onDelete={handleDeleteDistance}
                onEdit={handleOpenDistanceEditorModal}
              />
            )}
          </>
        )}
      </PageBox>

      {distanceEditorModalOpen && (
        <TechnicalTypeEditorModal
          id="distance-editor"
          open={distanceEditorModalOpen}
          handleClose={handleCloseDistancesEditorModal}
          handleSave={saveDistance}
          item={selectedDistance}
        />
      )}
    </>
  );
};

export default Distances;
