import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, boolean, number, object, string } from 'yup';

import { useSearchParams } from 'react-router-dom';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import CountrySelect from 'src/common/components/form/CountrySelect';
import ControlledPhoneInput from 'src/common/components/form/controlled/ControlledPhoneInput';
import {
  ControlledRadioField,
  RadioOption,
} from 'src/common/components/form/controlled/ControlledRadioField';
import {
  ControlledSelectField,
  SelectOption,
} from 'src/common/components/form/controlled/ControlledSelectField';
import { GENDERS } from 'src/common/constants/genders';
import { LanguageKeys } from 'src/common/constants/languages';
import { IApplicant, IBaseApplicant } from 'src/interfaces/Applicant';
import { IEdition } from 'src/interfaces/Edition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export type IFormApplicant = IBaseApplicant & {
  competition: string;
  distance: string;
  accommodation: string;
};

interface ApplicantEditorFormProps {
  handleClose?: () => void;
  handleSave: (editedApplicant: IFormApplicant, id?: string) => void;
  handleSuccessModalOpen?: (isOpen: boolean) => void;
  isExternalSignup?: boolean;
  saveButtonText?: string;
  selectedApplicant?: IApplicant;
}

const ApplicantEditorForm = (props: ApplicantEditorFormProps) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  let [searchParams] = useSearchParams();
  const currentLang = resolvedLanguage as LanguageKeys;

  const {
    handleClose,
    handleSave,
    handleSuccessModalOpen,
    isExternalSignup = false,
    saveButtonText = t('form.save'),
    selectedApplicant,
  } = props;

  const { accommodations, activeEditionId, competitionsById, distancesById, editionsById } =
    useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const contestantSchema: ObjectSchema<IFormApplicant> = object()
    .shape({
      email: string().email().required(t('form.yup.genericRequired')),
      lastName: string().required(t('form.yup.genericRequired')),
      firstName: string().required(t('form.yup.genericRequired')),
      country: string().required(t('form.yup.genericRequired')),
      city: string().required(t('form.yup.genericRequired')),
      phoneNumber: string().required(t('form.yup.genericRequired')),
      gender: string().required(t('form.yup.genericRequired')),
      age: number().min(1).max(100).required(t('form.yup.genericRequired')),
      sportsGroupMember: boolean(),
      discounted: boolean(),
      sportsGroupName: string().when('sportsGroupMember', {
        is: (value: boolean | undefined) => !!value,
        then: schema => schema.required(t('form.yup.genericRequired')),
        otherwise: schema => schema.notRequired(),
      }),
      competition: string().required(t('form.yup.genericRequired')),
      distance: string().required(t('form.yup.genericRequired')),
      accommodation: string().required(t('form.yup.genericRequired')),
      price: number().required(t('form.yup.genericRequired')),
      onlineSignup: boolean(),
      checkedIn: string(),
    })
    .required();

  let currentEdition: IEdition | undefined = undefined;

  if (editionsById && activeEditionId && competitionsById && distancesById) {
    currentEdition = editionsById[activeEditionId];
  }

  const defaultValues: Partial<IFormApplicant> = selectedApplicant
    ? {
        ...selectedApplicant,
        competition: selectedApplicant.competition,
        distance: selectedApplicant.distance,
        accommodation: selectedApplicant.accommodation,
      }
    : {
        price: currentEdition?.fullPrice,
        gender: '',
        competition: searchParams.get('competition') || '',
        distance: searchParams.get('distance') || '',
        accommodation: '',
        country: '',
        onlineSignup: isExternalSignup,
      };

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
  } = useForm<IFormApplicant>({
    resolver: yupResolver(contestantSchema),
    defaultValues,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      !!handleSuccessModalOpen && handleSuccessModalOpen(isSubmitSuccessful);
    }
  }, [handleSuccessModalOpen, isSubmitSuccessful, reset]);

  const onSubmit: SubmitHandler<IFormApplicant> = async editedApplicant => {
    handleSave(editedApplicant, selectedApplicant?.id);
  };

  const country = watch('country');
  const age = watch('age');
  const sportsGroupMember = watch('sportsGroupMember');
  const discounted = watch('discounted');
  const competition = watch('competition');

  useEffect(() => {
    if (
      currentEdition &&
      (age !== defaultValues.age ||
        sportsGroupMember !== defaultValues.sportsGroupMember ||
        discounted !== defaultValues.discounted)
    ) {
      const price = age
        ? age <= 7
          ? currentEdition.childPrice
          : age < 18
          ? currentEdition.youngPrice
          : sportsGroupMember || discounted
          ? currentEdition.discountedPrice
          : currentEdition.fullPrice
        : currentEdition.fullPrice;
      setValue('price', price);
    }
  }, [
    activeEditionId,
    age,
    competitionsById,
    currentEdition,
    defaultValues.age,
    defaultValues.discounted,
    defaultValues.sportsGroupMember,
    discounted,
    distancesById,
    editionsById,
    setValue,
    sportsGroupMember,
  ]);

  useEffect(() => {
    if (defaultValues.competition !== competition) setValue('distance', '');
  }, [competition, defaultValues.competition, setValue]);

  const setSportsGroupMember = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('sportsGroupMember', event.target.checked);
  };

  const setOnlineSignup = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('onlineSignup', event.target.checked);
  };

  const setPensioner = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('discounted', event.target.checked);
  };

  const genderOptions: RadioOption[] = GENDERS.map(g => ({
    value: g.name,
    label: t(`base.gender.${g.name}`),
  }));

  if (!currentEdition || !competitionsById || !distancesById) return null;
  const systemOptions: RadioOption[] = currentEdition.competitions.map(comp => ({
    value: comp.competitionId,
    label: competitionsById[comp.competitionId].i18n[currentLang],
  }));

  const distanceOptions: SelectOption[] = [];

  currentEdition.competitions
    .find(c => c.competitionId === competition)
    ?.distances.forEach(dist =>
      distanceOptions.push({
        label: distancesById[dist].i18n[currentLang],
        value: dist,
      }),
    );

  const accommodationOptions: SelectOption[] = accommodations.map(acc => ({
    label: acc.i18n[currentLang],
    value: acc.id,
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.lastName')}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['lastName']}
              helperText={errors['lastName']?.message}
              {...register('lastName')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.firstName')}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['firstName']}
              helperText={errors['firstName']?.message}
              {...register('firstName')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.email')}
              variant="outlined"
              type="email"
              fullWidth
              margin="dense"
              error={!!errors['email']}
              helperText={errors['email']?.message}
              {...register('email')}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledPhoneInput
              control={control}
              name="phoneNumber"
              label={t('form.phoneNo')}
              fullWidth
              margin="dense"
              defaultCountry="RO"
              preferredCountries={['RO', 'HU']}
            />
          </Grid>
          <Grid item xs={6}>
            <CountrySelect
              textFieldProps={{
                label: t('form.country'),
                variant: 'outlined',
                margin: 'dense',
                error: !!errors['country'],
                helperText: errors['country']?.message,
              }}
              fullWidth
              preferredCountries={['RO', 'HU']}
              value={country || null}
              onChange={(e, v) => setValue('country', v || '')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.city')}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['city']}
              helperText={errors['city']?.message}
              {...register('city')}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledRadioField<IFormApplicant>
              control={control}
              name="gender"
              margin="dense"
              fullWidth
              label={t('form.gender')}
              row
              options={genderOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.age')}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['age']}
              helperText={errors['age']?.message}
              {...register('age')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ display: 'block' }}
              control={
                <Switch
                  checked={watch('sportsGroupMember')}
                  onChange={setSportsGroupMember}
                  inputProps={{ 'aria-label': t('form.sportsGroupMember') }}
                />
              }
              label={t('form.sportsGroupMember')}
            />
            <FormControlLabel
              sx={{ display: 'block' }}
              control={
                <Switch
                  checked={watch('discounted')}
                  onChange={setPensioner}
                  inputProps={{ 'aria-label': t('form.discounted') }}
                />
              }
              label={t('form.discounted')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.sportsGroupName')}
              variant="outlined"
              fullWidth
              margin="dense"
              disabled={!watch('sportsGroupMember')}
              error={!!errors['sportsGroupName']}
              helperText={errors['sportsGroupName']?.message}
              {...register('sportsGroupName')}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledRadioField<IFormApplicant>
              control={control}
              name="competition"
              margin="dense"
              fullWidth
              label={t('form.competition')}
              options={systemOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField<IFormApplicant>
              control={control}
              disabled={!watch('competition')}
              fullWidth
              label={t('form.distance')}
              margin="normal"
              options={distanceOptions}
              withEmptyOption
              name="distance"
            />
            <ControlledSelectField<IFormApplicant>
              control={control}
              name="accommodation"
              fullWidth
              label={t('form.accommodation')}
              margin="normal"
              options={accommodationOptions}
              withEmptyOption
            />
            {!isExternalSignup ? (
              <>
                <TextField
                  sx={disableAutofillBg}
                  label={t('form.fee')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors['price']}
                  helperText={errors['price']?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t('base.currency')}</InputAdornment>
                    ),
                  }}
                  {...register('price')}
                />
                <FormControlLabel
                  sx={{ display: 'block' }}
                  control={
                    <Switch
                      checked={watch('onlineSignup')}
                      onChange={setOnlineSignup}
                      inputProps={{ 'aria-label': t('form.onlineSignup') }}
                    />
                  }
                  label={t('form.onlineSignup')}
                />
              </>
            ) : (
              <List sx={{ width: '100%', pt: 1, pb: 0, flexGrow: 1 }}>
                <ListItem sx={{ pl: 0 }}>
                  <ListItemText
                    primary={t('form.fee')}
                    secondary={t('base.currencyValue', { count: Number(watch('price')) })}
                  />
                </ListItem>
              </List>
            )}
          </Grid>

          {!!errors ? (
            <Grid item xs={12} textAlign="right">
              {Object.keys(errors).map(key => (
                <FormHelperText key={key} error>
                  {t('form.keysWithErrors', { key: t(`form.${key}`) })}
                </FormHelperText>
              ))}
            </Grid>
          ) : null}

          <Grid item xs={12} textAlign="right">
            {!!handleClose && (
              <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
                {t('form.cancel')}
              </Button>
            )}
            <Button type="submit" variant="contained" color="primary" sx={{ mr: 2 }}>
              {saveButtonText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ApplicantEditorForm;
