export interface IBaseApplicant {
  age: number;
  checkedIn?: string;
  city: string;
  country: string;
  discounted?: boolean;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  phoneNumber: string;
  price: number;
  sportsGroupMember?: boolean;
  sportsGroupName?: string;
  onlineSignup?: boolean;
}

export interface IApplicant extends IBaseApplicant {
  accommodation: string;
  competition: string;
  distance: string;
  id: string;
}

export enum EApplicantFilters {
  query = 'query',
  competition = 'competition',
  distance = 'distance',
  checkedIn = 'checkedIn',
}
