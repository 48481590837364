import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FlagIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M47.1,28.2C47,24.8,47,21.4,47.2,18c0-0.4-0.1-0.7-0.4-1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c-0.3-0.2-0.7-0.3-1.1-0.1
	c-1.3,0.5-2.5,1.1-3.6,1.6c-2.6,1.3-4.9,2.4-7.7,2c-1.3-0.2-2.7-1.1-4.2-2c-2.9-1.9-6.8-4.3-10.9,0c-0.2-0.1-0.4-0.2-0.6-0.2
	c-0.5-0.1-1.2,0.1-1.5,1c-0.2,0.5,0,0.9,0.3,1.3c0.1,0.8,0.1,2.1,0.1,2.6c0,0.3,0,0.5,0,0.7l0.2,2.8c0.3,4.4,0.6,8.9,0.6,13.4
	c0,1.7,0,3.3,0.2,5c0,0.3,0.1,0.9,0.1,1.5c0.1,1,0.1,2.1,1.3,2.1c0,0,0,0,0.1,0c0.1,0,1.3,0,1.3-1.4c0-0.3,0-0.5,0-0.6l0-0.1
	l0.1-0.6c0.1-0.4,0-0.8-0.3-1.1c-0.1-1.5-0.1-3.1-0.1-4.7c0-1,0-2,0-3c4.5-3.4,6.6-1.9,9.4,0c1.8,1.2,3.8,2.6,6.5,2.6
	c2.2,0,4.9-0.9,8.2-3.7c0.4-0.3,0.5-0.8,0.4-1.3c0.2,0.2,0.5,0.3,0.8,0.3c0.7,0,1.3-0.6,1.3-1.3C47.2,31.7,47.2,29.9,47.1,28.2z
	 M43,33.6c-6,4.9-8.6,3-11.7,1c-2.7-1.8-5.9-4-11-0.9c-0.1-2.5-0.3-5-0.4-7.5l-0.2-2.8c0-0.1,0-0.3,0-0.6c0-0.6,0-1.1,0-1.5
	c3.2-4.4,5.4-3,8.8-0.9c1.7,1.1,3.4,2.1,5.3,2.4c0.5,0.1,0.9,0.1,1.4,0.1c3,0,5.4-1.2,7.8-2.3c0.5-0.3,1.1-0.5,1.7-0.8
	c-0.1,2.8,0,5.7,0,8.5c0,1.7,0.1,3.4,0.1,5.1c0,0.1,0,0.2,0.1,0.3C44.2,33.3,43.5,33.2,43,33.6z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default FlagIcon;
