import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M18.5,9.8c-0.5-1.8-1.7-3-3.4-3.4c-1.8-0.4-3.7,0.1-4.9,1.3c-1,1.1-1.3,2.6-0.7,4.2c0.1,0.3,0.4,0.4,0.6,0.3
	c0.3-0.1,0.4-0.4,0.3-0.6c-0.5-1.6,0-2.7,0.5-3.3c0.9-1,2.5-1.4,3.9-1.1c0.8,0.2,2.2,0.8,2.7,2.8c0.5,1.9-0.9,3.7-2.6,4.3
	c-1.3,0.5-3.1,0.3-4.1-1.8c-0.1-0.2-0.4-0.3-0.6-0.2c-0.2,0.1-0.3,0.4-0.2,0.6c0.3,0.7,0.7,1.2,1.1,1.6c-2.1,2.2-3.9,3.3-4.7,2.8
	c-0.2-0.1-0.2-0.2-0.2-0.3c0-0.7,1.5-1.9,2.5-2.8c0.3-0.2,0.6-0.5,0.8-0.7c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0
	c-0.2,0.2-0.5,0.4-0.8,0.7c-1.5,1.2-2.9,2.4-2.9,3.6c0,0.3,0.1,0.7,0.6,1c0.3,0.2,0.7,0.4,1.1,0.4c1.6,0,3.7-1.9,5-3.3
	c0.6,0.3,1.2,0.4,1.8,0.4c0.5,0,1.1-0.1,1.5-0.3C17.4,14.6,19.2,12.3,18.5,9.8z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default SearchIcon;
