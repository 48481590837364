import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import TechnicalTypeEditorForm, { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';

interface Props extends DialogProps {
  handleClose: () => void;
  handleSave: (editedTechnicalType: FormTechnicalType, id?: string) => void;
  item?: FormTechnicalType;
  withIcon?: boolean;
}

const TechnicalTypeEditorModal = ({
  id,
  open,
  handleClose,
  handleSave,
  item,
  withIcon,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} aria-labelledby={`${id}-modal-title`}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={t('base.close')}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" id={`${id}-modal-title`}>
            {!!item
              ? t('technicalTypes.editModalTitle', {
                  name: `${item.name}`,
                })
              : t('technicalTypes.addModalTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TechnicalTypeEditorForm
          handleSave={handleSave}
          handleClose={handleClose}
          item={item}
          withIcon={withIcon}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TechnicalTypeEditorModal;
