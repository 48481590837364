import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, object, ref, string } from 'yup';

import { useAuth } from 'src/auth/useAuth';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import { IBaseUser } from 'src/interfaces/User';

export type IFormUser = IBaseUser & {
  newPassword?: string;
  repeatPassword?: string;
};

const ProfileEditorForm = () => {
  const { t } = useTranslation();
  const { user, update } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const profileSchema: ObjectSchema<IFormUser> = object()
    .shape({
      id: string(),
      name: string().required(t('form.yup.genericRequired')),
      email: string().email().required(t('form.yup.genericRequired')),
      dateFormat: string().required(t('form.yup.genericRequired')),
      timeFormat: string().required(t('form.yup.genericRequired')),
      password: string()
        .when('repeatPassword', (repeatPassword, schema) => {
          if (!!repeatPassword[0]) return schema.required(t('form.yup.currentPasswordRequired'));
          return schema;
        })
        .when('newPassword', (newPassword, schema) => {
          if (!!newPassword[0]) return schema.required(t('form.yup.currentPasswordRequired'));
          return schema;
        }),
      newPassword: string()
        .test('strongPassword', t('form.yup.passwordComplexity'), (value, _testContext) => {
          if (!value) return true;
          return (
            /^(?=.*[a-z])/.test(value) &&
            /^(?=.*[A-Z])/.test(value) &&
            /^(?=.*[0-9])/.test(value) &&
            /^(?=.*[^A-Za-z0-9])/.test(value)
          );
        })
        .oneOf([ref('repeatPassword')], t('form.yup.passwordsDontMatch')),
      repeatPassword: string().oneOf([ref('newPassword')], t('form.yup.passwordsDontMatch')),
    })
    .required();

  const defaultValues = user || {
    dateFormat: 'YYYY.MM.DD.',
    timeFormat: 'HH:mm',
  };

  const {
    resetField,
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<IFormUser>({
    resolver: yupResolver(profileSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormUser> = async u => {
    update(u)
      .then(() => {
        resetField('password');
        resetField('newPassword');
        resetField('repeatPassword');
        enqueueSnackbar(t('profile.saveSuccessful'), {
          variant: 'success',
        });
      })
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`profile.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      );
  };

  return (
    <Paper
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(3px)',
        width: '100%',
        mb: 2,
        mt: 2,
        p: 2,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.name')}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['name']}
              helperText={errors['name']?.message}
              {...register('name')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.email')}
              variant="outlined"
              type="email"
              fullWidth
              margin="dense"
              error={!!errors['email']}
              helperText={errors['email']?.message}
              {...register('email')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.dateFormat')}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors['dateFormat']}
              placeholder="YYYY.MM.DD."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/"
                      leaveDelay={1000}
                    >
                      <InfoIcon color="info" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              helperText={errors['dateFormat']?.message}
              {...register('dateFormat')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              label={t('form.timeFormat')}
              variant="outlined"
              type="timeFormat"
              fullWidth
              margin="dense"
              error={!!errors['timeFormat']}
              placeholder="HH:mm"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/"
                      leaveDelay={1000}
                    >
                      <InfoIcon color="info" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              helperText={errors['timeFormat']?.message}
              {...register('timeFormat')}
            />
          </Grid>
          <Grid item xs={12} mt={4} />
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              autoComplete="current-password"
              defaultValue=""
              fullWidth
              id="password"
              label={t('form.password')}
              margin="normal"
              type="password"
              error={!!errors['password']}
              helperText={errors['password']?.message}
              {...register('password')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={disableAutofillBg}
              autoComplete="new-password"
              defaultValue=""
              fullWidth
              id="newPassword"
              label={t('form.newPassword')}
              margin="normal"
              type="password"
              error={!!errors['newPassword']}
              helperText={errors['newPassword']?.message}
              {...register('newPassword')}
            />
            <TextField
              sx={disableAutofillBg}
              autoComplete="new-password"
              defaultValue=""
              fullWidth
              id="repeatPassword"
              label={t('form.newPasswordRepeat')}
              margin="normal"
              type="password"
              error={!!errors['repeatPassword']}
              helperText={errors['repeatPassword']?.message}
              {...register('repeatPassword')}
            />
          </Grid>

          {!!errors ? (
            <Grid item xs={12} textAlign="right">
              {Object.keys(errors).map(key => (
                <FormHelperText key={key} error>
                  {t('form.keysWithErrors', { key: t(`form.${key}`) })}
                </FormHelperText>
              ))}
            </Grid>
          ) : null}

          <Grid item xs={12} textAlign="right">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!Object.keys(errors).length || !isDirty}
            >
              {t('form.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ProfileEditorForm;
