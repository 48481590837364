import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HeartIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M11.8,17.7c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7c0.7-0.6,2.3-2.1,3.3-3c2.2-2,2.6-4.7,1.6-6
	c-0.7-0.9-1.9-1-3.2-0.1c-0.5,0.3-0.8,0.7-0.9,1.2c0,0.2-0.2,0.3-0.4,0.4c-0.2,0-0.4,0-0.5-0.2C10.8,8.3,10.4,8,10.2,8
	C9.4,7.7,8.2,8.1,7.5,9.1c-0.9,1.4-0.3,3.4,1.7,5.4c0.2,0.2,0.3,0.3,0.5,0.5c0.5,0.5,0.7,0.7,1.4,1.2c0.2,0.1,0.3,0.4,0.2,0.7
	c-0.1,0.2-0.4,0.3-0.7,0.2c-0.8-0.5-1.1-0.8-1.6-1.3c-0.1-0.1-0.3-0.3-0.4-0.5c-3.3-3.4-2.4-5.8-1.8-6.6c0.8-1.3,2.6-2,3.9-1.5
	c0.4,0.1,0.7,0.4,1.1,0.8c0.2-0.4,0.6-0.7,1-1c2.2-1.3,3.7-0.7,4.5,0.4c1.2,1.7,0.9,4.9-1.7,7.3c-1,0.9-2.6,2.4-3.3,3
	C12,17.7,11.9,17.7,11.8,17.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default HeartIcon;
