import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useTitle from 'react-use/lib/useTitle';

import PageBox from 'src/common/components/PageBox';
import Icon, { Icons } from 'src/common/components/icons/Icon';

export default function IconList() {
  useTitle('just a page to show all icons');

  return (
    <PageBox sx={{ pt: 4, pb: 4 }}>
      <Grid container sx={{ minHeight: 'calc(100vh - 65px - 85px)' }} spacing={2}>
        {Object.keys(Icons).map(icon => (
          <Grid item xs={3} sm={2} textAlign="center">
            <Paper
              sx={{
                py: 2,
                px: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                backdropFilter: 'blur(3px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              elevation={4}
            >
              <Icon
                icon={icon}
                sx={{ color: theme => theme.palette.grey[900] }}
                fontSize="large"
              />
              {icon}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </PageBox>
  );
}
