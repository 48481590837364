import styled, { css } from 'styled-components';

export const CalendarIconWrapper = styled.div<{ reversed?: boolean }>`
  text-align: center;
  display: flex;
  flex-direction: column;

  ${({ reversed }) =>
    reversed
      ? css`
          flex-direction: column-reverse;
        `
      : css`
        `};
`;

export const DateIconDay = styled.span`
  display: block;
  font-size: 60px;
  line-height: 60px;
  color: #9e9e9e;
  margin: 0;
`;

export const DateIconMonth = styled.span`
  background: #d10000;
  background: linear-gradient(top, #d10000 0%, #7a0909 60%);
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  display: block;
  padding: 6px 0 6px 0;
`;

export const DateIcon = styled.div<{ reversed?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ reversed }) =>
    reversed
      ? css`
          flex-direction: column-reverse;

          ${DateIconMonth} {
            border-bottom: 3px solid #a13838;
          }
        `
      : css`
          ${DateIconMonth} {
            border-top: 3px solid #a13838;
          }
        `};

  width: 80px;
  background: #fcfcfc;
  background: linear-gradient(top, #fcfcfc 0%, #dad8d8 100%);
  background: -moz-linear-gradient(top, #fcfcfc 0%, #dad8d8 100%);
  background: -webkit-linear-gradient(top, #fcfcfc 0%, #dad8d8 100%);
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
`;
