import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from 'src/common/components/constants/drawer';

const Drawer = styled(MuiDrawer)(
  ({ theme, $open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!$open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: CLOSED_DRAWER_WIDTH,
        [theme.breakpoints.up('sm')]: {
          width: CLOSED_DRAWER_WIDTH,
        },
      }),
    },
  }),
);

export default Drawer;
