import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LaptopIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M19.8,17.8c-0.3-1.1-1.2-2.1-2-3.1c-0.4-0.5-0.8-1-1.2-1.5c0,0,0,0-0.1-0.1c0.3-1.3,1-6.9,0.3-7.7c-0.8-0.9-5.3-1-5.8-0.9
		c-2.5,0-4,0.3-4.4,0.8C6,6.1,6.2,8.1,6.6,10.8c0.1,0.8,0.2,1.6,0.2,2.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.3,0.1,0.5,0.4,0.6
		c1.6,0.3,3.2,0.3,4.7,0.2c1.2,0,2.5,0,3.7,0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0.1,0,0.1c0.4,0.5,0.8,1,1.2,1.5
		c0.8,0.9,1.5,1.8,1.8,2.7c0,0,0,0,0,0c-2,0-4,0.1-5.9,0.1c-2.6,0.1-5.2,0.1-7.8,0.1c0.5-0.8,0.9-1.7,1.2-2.5c0.2-0.5,0.5-1,0.7-1.5
		c0.1-0.2,0-0.5-0.2-0.6c-0.2-0.1-0.5,0-0.6,0.2c-0.3,0.5-0.5,1-0.7,1.6c-0.4,0.9-0.8,1.7-1.3,2.5c-0.1,0.2-0.1,0.4,0.1,0.6
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.1c2.4-0.1,5.3,0,8.1,0c1.6,0,3.2,0,4.8,0
		c0.4,0,0.8,0,1.2,0c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3C19.8,18.2,19.9,18,19.8,17.8z
		 M11.8,12.8c-1.4,0-2.8,0-4.1-0.2c0-0.5-0.1-1.2-0.2-2C7.3,9.1,7,6.5,7.4,6c0.7-0.7,7.9-0.5,8.7,0.1c0.3,0.6-0.1,5.4-0.4,6.8
		c0,0-0.1,0-0.1,0C14.4,12.7,13.1,12.8,11.8,12.8z "
      fill="currentColor"
    />
    <path
      d="M11.3,17.6c0.3,0,0.5,0,0.8,0c0.2,0,0.4,0,0.6,0c0.3,0,0.5-0.2,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4c-0.2,0-0.4,0-0.5,0
		c-0.3,0-0.6,0-0.9,0.1c-0.3,0-0.5,0.3-0.4,0.5C10.8,17.5,11,17.7,11.3,17.6C11.2,17.7,11.2,17.7,11.3,17.6z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LaptopIcon;
