import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CaretRightIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M9,17.4c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.3,0.1-0.5,0.4-0.5c0.2-0.1,1-0.7,2-1.5
		c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.8-0.6,1.1-0.9c0.6-0.5,1.1-1,1.4-1.4c-0.2-0.2-0.8-0.7-1.9-1.7c-0.3-0.3-1.3-1-2-1.6
		C10,8.4,9.7,8.1,9.6,8.1C9.3,7.9,9.1,7.7,8.9,7.5C8.6,7.4,8.6,7.1,8.7,6.9s0.4-0.3,0.7-0.2c0,0,0.1,0.1,0.1,0.1
		c0.2,0.1,0.4,0.3,0.7,0.5c0.1,0.1,0.4,0.3,0.8,0.6c0.7,0.6,1.7,1.3,2,1.6c2.5,2.1,2.5,2.2,2.5,2.5c0,0.1,0,0.2-0.1,0.3
		c-0.5,0.6-1.1,1.2-1.8,1.9c-0.3,0.3-0.8,0.6-1.2,0.9c-0.3,0.2-0.6,0.4-0.8,0.6C9.8,17.1,9.3,17.4,9,17.4z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CaretRightIcon;
