import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, object, string } from 'yup';

import TextField from '@mui/material/TextField';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import { IForgotPassword } from 'src/interfaces/ForgotPassword';

export type IFormUser = IForgotPassword & {
  repeatPassword?: string;
};

const forgotPasswordSchema: ObjectSchema<IForgotPassword> = object()
  .shape({
    email: string().email().required(),
  })
  .required();

interface ForgotPasswordFormProps {
  handleSave: (editedApplicant: IForgotPassword) => void;
}

const ForgotPasswordForm = ({ handleSave }: ForgotPasswordFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<IForgotPassword>({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const { handleSubmit, register } = methods;

  const onSubmit: SubmitHandler<IForgotPassword> = payload => {
    handleSave(payload);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, mb: 2, }}>
      <TextField
        sx={disableAutofillBg}
        autoComplete="email"
        autoFocus
        defaultValue=""
        fullWidth
        id="email"
        label={t('form.email')}
        margin="normal"
        required
        {...register('email')}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
        {t('form.send')}
      </Button>
    </Box>
  );
};

export default ForgotPasswordForm;
