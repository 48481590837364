import { MouseEvent } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import TagIcon from '@mui/icons-material/Tag';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash-es/map';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { CheckinSortKeys, ECheckinTimerType, ICheckin } from 'src/interfaces/Checkin';
import { THeadCell } from 'src/interfaces/Table';
import CompetitionTableRow from 'src/pages/Dashboard/Competitions/tables/CompetitionTableRow';

const headCells: readonly THeadCell<CheckinSortKeys>[] = [
  {
    id: 'ser',
    sortable: true,
    label: <TagIcon sx={{ verticalAlign: 'middle' }} />,
    width: '85px',
  },
  {
    id: 'contestantName',
    sortable: true,
    label: 'Név',
  },
  {
    id: 'startTime',
    sortable: true,
    label: 'Indulás',
    width: '220px',
  },
  {
    id: 'finishTime',
    sortable: true,
    label: 'Érkezés',
    width: '220px',
  },
  {
    id: 'duration',
    sortable: true,
    label: 'Idő',
    width: '120px',
  },
];

export interface Props {
  checkins: ICheckin[];
  distanceId: string;
  onClickTimer: (id: string, type: ECheckinTimerType, timeStamp?: Date) => void;
  onDeleteCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
  onEditCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
}

const CompetitionTable = ({
  checkins,
  distanceId,
  onClickTimer,
  onDeleteCheckin,
  onEditCheckin,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Paper key={distanceId} sx={{ width: '100%', mb: 2, mt: 2, p: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 950 }}
          aria-labelledby={t('applicants.competitionsTableLabel')}
          size="medium"
        >
          <TableHead<CheckinSortKeys>
            withOptions
            optionsLabel={t('competitions.optionsLabel')}
            optionsCellWidth="120px"
            headCells={headCells}
          />
          <TableBody>
            {map(checkins, checkin => (
              <CompetitionTableRow
                key={checkin.id}
                checkin={checkin}
                onClickTimer={onClickTimer}
                optionElement={
                  <>
                    <Tooltip title={t('checkins.deleteCheckin')}>
                      <IconButton size="small" onClick={e => onDeleteCheckin(checkin.id, e)}>
                        <UnpublishedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('checkins.editCheckin')}>
                      <IconButton size="small" onClick={e => onEditCheckin(checkin.id, e)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('timers.resetTimer')}>
                      <IconButton
                        aria-label={t('timers.resetTimer')}
                        onClick={() => onClickTimer(checkin.id, ECheckinTimerType.clear)}
                        edge="end"
                      >
                        <TimerOffIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CompetitionTable;
