import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  ChartDataset,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar, Pie, PolarArea } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { GENDERS_BY_NAME } from 'src/common/constants/genders';
import { minutesToHours } from 'src/helpers/dateTimeFormatters';
import { EAgeGroups, TStatistic } from 'src/interfaces/Statistics';
import { THeadCell } from 'src/interfaces/Table';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Colors,
  Legend,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
);

interface Props {
  statistic: TStatistic;
}

const StatisticsComponent = ({ statistic }: Props) => {
  const { t } = useTranslation();

  const winnerTableHeadCells: readonly THeadCell<{}>[] = [
    {
      id: 'place',
      label: t('statistics.place'),
      width: '20%',
    },
    {
      id: 'name',
      label: t('statistics.name'),
      width: '40%',
    },
    {
      id: 'time',
      label: t('statistics.time'),
      width: '40%',
    },
  ];

  const { checkinsByGenderByAgeKeys, dataByGender, dataByGenderByAge, winnersByGender } = statistic;

  const allAgeGroupLabels = Object.values(EAgeGroups).map(key =>
    t('statistics.keyAgeGroup', { key }),
  );

  const datasetsByGenderByAge = Object.entries(dataByGenderByAge).map(([genderK, byAge]) => {
    const gender = t(`base.gender.${GENDERS_BY_NAME[genderK].name}`);

    const bestTime: number[] = [];
    const noOfApplicants: number[] = [];
    const averageTime: number[] = [];

    Object.values(EAgeGroups).forEach(key => {
      bestTime.push(byAge[key].bestTime);
      averageTime.push(byAge[key].averageTime);
      noOfApplicants.push(byAge[key].noOfApplicants);
    });

    return {
      bestTime: {
        label: t('statistics.bestTimeLabel', { gender }),
        data: bestTime,
      } as ChartDataset<'bar', number[]>,
      averageTime: {
        label: t('statistics.averageTimeLabel', { gender }),
        data: averageTime,
      } as ChartDataset<'bar', number[]>,
      noOfApplicants: {
        label: t('statistics.noOfApplicantsLabel', { gender }),
        data: noOfApplicants,
      } as ChartDataset<'polarArea', number[]>,
    };
  });

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          {t('statistics.results')}
        </Typography>
      </Grid>
      {checkinsByGenderByAgeKeys.map((k, i) => {
        const gender = t(`base.gender.${GENDERS_BY_NAME[k].name}`);
        return (
          <Grid key={k} item xs={12} sm={6}>
            <Typography variant="subtitle1" component="h3">
              {t('statistics.winners', { gender })}
            </Typography>
            <MUITableContainer
              component={Card}
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
            >
              <MUITable stickyHeader aria-labelledby="tableTitle" size="medium">
                <TableHead<{}> headCells={winnerTableHeadCells} />
                <MUITableBody>
                  {winnersByGender[k].firstPlace.length ? (
                    <MUITableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>1</TableCell>
                      <TableCell>
                        {winnersByGender[k].firstPlace.map(
                          (w, i) =>
                            `${w.name}${i + 1 < winnersByGender[k].firstPlace.length ? ', ' : ''}`,
                        )}
                      </TableCell>
                      <TableCell>
                        {minutesToHours(winnersByGender[k].firstPlace[0].duration, t)}
                      </TableCell>
                    </MUITableRow>
                  ) : null}
                  {winnersByGender[k].secondPlace.length ? (
                    <MUITableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>2</TableCell>
                      <TableCell>
                        {winnersByGender[k].secondPlace.map(
                          (w, i) =>
                            `${w.name}${i + 1 < winnersByGender[k].secondPlace.length ? ', ' : ''}`,
                        )}
                      </TableCell>
                      <TableCell>
                        {minutesToHours(winnersByGender[k].secondPlace[0].duration, t)}
                      </TableCell>
                    </MUITableRow>
                  ) : null}
                  {winnersByGender[k].thirdPlace.length ? (
                    <MUITableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>3</TableCell>
                      <TableCell>
                        {winnersByGender[k].thirdPlace.map(
                          (w, i) =>
                            `${w.name}${i + 1 < winnersByGender[k].thirdPlace.length ? ', ' : ''}`,
                        )}
                      </TableCell>
                      <TableCell>
                        {minutesToHours(winnersByGender[k].thirdPlace[0].duration, t)}
                      </TableCell>
                    </MUITableRow>
                  ) : null}
                </MUITableBody>
              </MUITable>
            </MUITableContainer>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          {t('statistics.statistics')}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Typography variant="subtitle1" component="h3">
          {t('statistics.fmRatio')}
        </Typography>
        <Pie
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: ({ dataset: { label }, datasetIndex, raw }) =>
                    `${label ? `${label}: ` : ''}${
                      datasetIndex > 0 ? minutesToHours(raw as number, t) : raw
                    }`,
                },
              },
            },
          }}
          data={{
            labels: checkinsByGenderByAgeKeys.map(g => {
              const gender = t(`base.gender.${GENDERS_BY_NAME[g].name}`);
              return t('statistics.noOfApplicantsByGender', { gender });
            }),
            datasets: [
              {
                label: t('statistics.ratio'),
                data: checkinsByGenderByAgeKeys.map(k => dataByGender[k].ratio),
              },
              {
                label: t('statistics.totalTime'),
                data: checkinsByGenderByAgeKeys.map(k => dataByGender[k].totalTime),
              },
              {
                label: t('statistics.averageTime'),
                data: checkinsByGenderByAgeKeys.map(k => dataByGender[k].averageTime),
              },
            ],
          }}
        />
      </Grid>
      {checkinsByGenderByAgeKeys.map((k, i) => {
        const sortedAgeGroupLabels: string[] = [];
        const sortedParticipantsPerAgeGroup: number[] = [];
        Object.entries(dataByGenderByAge[k]).forEach(([key, data]) => {
          sortedAgeGroupLabels.push(t('statistics.keyAgeGroup', { key }));
          sortedParticipantsPerAgeGroup.push(data.noOfApplicants);
        });
        const gender = t(`base.gender.${GENDERS_BY_NAME[k].name}`);
        return (
          <Grid key={k} item xs={4} sm={2}>
            <Typography variant="subtitle1" component="h2">
              {t('statistics.noOfApplicants', { gender })}
            </Typography>
            <PolarArea
              options={{
                scales: {
                  r: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
              data={{
                labels: allAgeGroupLabels,
                datasets: [datasetsByGenderByAge[i].noOfApplicants],
              }}
            />
          </Grid>
        );
      })}
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" component="h3">
          {t('statistics.results')}
        </Typography>
        <Bar
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: ({ dataset: { label }, raw }) =>
                    `${label ? `${label}: ` : ''}${minutesToHours(raw as number, t)}`,
                },
              },
            },
          }}
          data={{
            labels: allAgeGroupLabels,
            datasets: [
              ...datasetsByGenderByAge.map(ds => ds.bestTime),
              ...datasetsByGenderByAge.map(ds => ds.averageTime),
            ],
          }}
        />
      </Grid>
    </>
  );
};

export default StatisticsComponent;
