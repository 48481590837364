import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PuzzleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M21.7,11c-0.4-0.1-0.8,0.1-1.1,0.2c0-0.2,0.1-0.3,0.1-0.4c0.3-0.9,0.4-1.4,0.2-1.7c-0.4-0.5-1.2-0.4-2-0.3
	c-0.3,0-0.6,0.1-0.8,0.1c0-0.1,0.1-0.4,0.1-0.5c0.1-0.6,0.3-1.2-0.1-1.7c-0.2-0.3-0.5-0.4-1-0.4c-1-0.1-1.7,0.1-2,0.5
	c-0.4,0.5-0.3,1-0.2,1.5C15,8.5,15,8.8,15,8.9c0,0-0.3,0.3-1.7,0.1c-0.1,0-0.3,0-0.3,0.1C12.5,8.9,12,8.9,11.8,9c-0.2,0-0.5,0-0.8,0
	C10.5,8.9,10,8.8,9.5,9c-0.4,0.2-0.4,0.7-0.4,1.4c0,0.4,0.1,1.1-0.1,1.3c0,0-0.1,0-0.3,0C8,11.4,8,11.1,8.1,10.4
	C8.2,9.9,8.3,9.3,7.8,9C7.5,8.7,4.4,8.7,4.1,9.1c-0.4,0.5-0.3,1-0.2,1.4c0,0.3,0.1,0.5,0,0.6c0,0.1,0,0.1,0,0.2
	c-1.1-0.2-2,0.1-2.5,0.9c-0.4,0.7-0.4,1.5,0.1,2.1c0.5,0.6,1.4,0.7,2.1,0.7c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3
	c-0.2,1.5-0.1,1.8,0.1,2c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.6-0.1,1.1-0.3c0.1,0,0.2-0.1,0.2-0.1c0.4-0.1,0.7-0.1,1.1-0.1
	c0.4,0,0.8,0,1.2-0.1C8.1,17,8,16.7,8,16.5c0-0.2-0.1-0.8-0.1-1c0-0.4,0.4-0.7,0.8-0.8c0.8-0.1,0.9,0.2,1,0.3c0.2,0.4,0,0.9-0.1,1.2
	c-0.3,0.1-0.4,0.2-0.5,0.3C9,16.6,9,16.8,9.2,17c0.2,0.2,0.6,0.3,0.9,0.1c0,0,0.1,0,0.1-0.1c0.8-0.1,2.3-0.2,2.7-0.1c0,0,0,0,0,0
	c0,0,0,0,0.1,0c0.2,0,0.5,0,0.7,0c0.9,0,1.9,0.1,2.5-0.1c0.5-0.2,0.6-0.7,0.6-1.2c0-0.3,0.1-0.8,0.2-0.9c0.1,0,0.2,0,0.3,0
	c0.2,0,0.2,0.4,0.1,1.1c-0.1,0.6-0.1,1.2,0.3,1.6c0.1,0.1,0.2,0.2,0.7,0.2c0.3,0,0.7,0,1.2-0.2c1.4-0.3,1.5-0.6,1.5-0.7
	c0.2-0.4,0-0.9-0.2-1.4c0-0.1-0.1-0.2-0.1-0.3c0.3,0,0.7,0,1.2-0.3c0.6-0.3,0.9-1,0.8-1.9C22.9,12.2,22.5,11.2,21.7,11z M10.7,15.9
	c0.1-0.4,0.1-0.9-0.1-1.3c-0.1-0.4-0.6-1.2-2-1c-0.8,0.1-1.5,0.8-1.6,1.6c0,0.2,0,0.6,0,1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.9,0-1.4,0.1
	c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0-0.5,0.1-1.1c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0,0,0.1,0,0.1,0
	c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.2-0.4-0.6-0.4c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.1-1.4,0.1-1.7-0.3c-0.2-0.3-0.2-0.7,0-1
	c0.3-0.5,0.9-0.6,1.8-0.4c0.3,0.1,0.5-0.1,0.6-0.4c0-0.1,0-0.1,0-0.2c0.1,0,0.2-0.1,0.3-0.3c0.2-0.4,0.1-0.8,0-1.2
	c0-0.3-0.1-0.5,0-0.6c0.5-0.1,2-0.1,2.5-0.1c0,0.1,0,0.3-0.1,0.5c-0.1,0.7-0.3,1.9,1.3,2.3c0.5,0.1,0.8,0.1,1.1-0.2
	c0.6-0.5,0.5-1.3,0.5-2.1c0-0.2,0-0.4,0-0.5c0.2,0,0.6,0,0.9,0c0.4,0,0.7,0.1,1,0c0.2,0,0.3,0,0.3,0c0,0.1,0,0.4-0.1,0.5
	c-0.1,0.5-0.2,1,0.1,1.5c0.2,0.3,0.7,0.3,1.2,0.3c0.4,0,1,0,1.1,0.2c0,0,0.1,0.2,0,0.6c-0.1,0.4-0.4,0.5-1,0.5
	c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.2-0.5,0.7-0.4,2.2C11.9,15.9,11.3,15.9,10.7,15.9z M21.7,13.9c-0.5,0.2-0.9,0.2-1.1,0.1
	c-0.1,0-0.4-0.1-0.6,0.1c-0.4,0.4-0.2,0.9,0,1.5c0.1,0.2,0.2,0.5,0.2,0.6c-0.4,0.2-1.5,0.4-1.9,0.4c0-0.2,0-0.5,0-0.8
	c0.1-0.7,0.2-1.8-0.8-2.1c-0.4-0.1-0.8-0.1-1.1,0.1c-0.5,0.3-0.6,1-0.7,1.5c0,0.1,0,0.3-0.1,0.4c-0.5,0.1-1.4,0.1-2.1,0.1
	c-0.1,0-0.2,0-0.3,0c0-0.3-0.1-1,0-1.4c0.1,0,0.3,0,0.4-0.1c0.6-0.1,1.4-0.2,1.8-1.2c0.2-0.6,0.2-1.1-0.1-1.4
	c-0.4-0.6-1.2-0.6-1.9-0.6c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.5,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6c1.3,0.1,2,0,2.4-0.4
	c0.4-0.5,0.3-1,0.2-1.5c-0.1-0.3-0.1-0.6,0-0.7c0,0,0.2-0.2,1.2-0.2c0.2,0,0.2,0,0.2,0.1c0.1,0.1,0,0.6-0.1,0.9
	c-0.1,0.5-0.2,1,0,1.4c0.3,0.4,0.9,0.3,1.7,0.2c0.3,0,0.8-0.1,1-0.1c0,0.2-0.2,0.6-0.2,0.8c-0.3,0.9-0.6,1.7,0.1,1.9
	c0.3,0.1,0.7-0.1,1-0.3c0.1-0.1,0.4-0.3,0.5-0.2C21.7,12,22,12.5,22,13C22,13.4,21.9,13.8,21.7,13.9z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PuzzleIcon;
