import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PencilIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M15.6,8.2c-0.8,0.8-2.7,3.6-4.2,5.6c-0.5,0.7-1,1.4-1.3,1.8c-0.2-0.3-0.4-0.5-0.7-0.7c0.6-1,1.6-2.4,2.6-3.7
	c1-1.4,1.9-2.6,2.5-3.6c0.1,0,0.4,0.1,0.5,0.3c0.2,0.2,0.5,0.3,0.7,0.1c0.2-0.2,0.3-0.5,0.1-0.7C15.5,7,15.2,6.8,15,6.7
	c0.1-0.2,0.2-0.3,0.3-0.3c0,0,0.1,0,0.5,0.3c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7c-0.3-0.3-0.7-0.6-1.2-0.5
	c-0.5,0.1-0.8,0.4-1.1,0.9l-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3C13.7,6.9,13.6,7,13.6,7c-0.6,1-1.5,2.3-2.4,3.6
	c-1.1,1.6-2.3,3.2-2.9,4.3c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0.1c-0.1,0.5-0.2,1-0.4,1.5c-0.3,1-0.3,1.2-0.2,1.4
	c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0,0c0.2,0,0.6-0.3,1.1-0.6c0.1,0,0.1-0.1,0.2-0.1c0.6-0.5,1.2-1.1,1.2-1.1c0.2-0.2,0.7-1,1.7-2.4
	c1.4-2,3.4-4.7,4.1-5.5c0.2-0.2,0.2-0.5,0-0.7C16,8,15.7,8,15.6,8.2z M8.8,16.9C8.8,16.9,8.8,16.9,8.8,16.9c0-0.4,0.1-0.7,0.2-1
	c0.1,0.1,0.2,0.2,0.3,0.4c0,0,0.1,0.1,0.1,0.1C9.3,16.5,9,16.7,8.8,16.9z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PencilIcon;
