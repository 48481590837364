import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GraphIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M5.5,5.2c0.3,0.2,0.7,0.7,0.8,0.9C6.4,6.4,6.7,6.5,7,6.4C7.2,6.3,7.3,6,7.2,5.8C7,5.3,6,4.1,5.4,4.1l0,0
        C5.2,4.1,5,4.3,4.9,4.5c0,0.1-0.2,0.2-0.3,0.4C4.1,5.4,3.8,5.8,3.7,6.2c0,0.2,0.1,0.4,0.4,0.5h0.1c0.2,0,0.4-0.1,0.5-0.4
        c0-0.2,0.4-0.6,0.6-0.9C5.4,5.4,5.5,5.3,5.5,5.2z"
        fill="currentColor"
      />
      <path
        d="M19.9,17.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.5-0.9-0.8-1.3-0.8c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6
        c0.2,0,0.6,0.4,0.9,0.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.3-0.7,0.7-0.9,0.8c-0.2,0.1-0.4,0.4-0.3,0.6s0.3,0.3,0.4,0.3s0.1,0,0.2,0
        c0.5-0.2,1.7-1.2,1.7-1.8C20.2,17.8,20.1,17.6,19.9,17.5z"
        fill="currentColor"
      />
      <path
        d="M18.6,18.1c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.5,0-1,0c-1,0-2.7,0.1-4.7,0.1c-1.9,0-3.8,0-5.7-0.1c-0.3,0-0.5,0.2-0.5,0.5
        s0.2,0.5,0.5,0.5c1.9,0.1,3.8,0.1,5.7,0.1c2,0,3.7-0.1,4.7-0.1c0.5,0,0.8,0,1,0C18.4,18.6,18.6,18.4,18.6,18.1z"
        fill="currentColor"
      />
      <path
        d="M5.9,6.2c0-0.3-0.2-0.5-0.5-0.5S5,6,5,6.2s0,0.5,0,1c0,1,0,2.7,0,4.7c0,1.9,0,3.8-0.1,5.7c0,0.3,0.2,0.5,0.5,0.5l0,0
        c0.3,0,0.5-0.2,0.5-0.5C6,15.7,6,13.8,6,11.9c0-2,0-3.6,0-4.7C5.9,6.8,5.9,6.4,5.9,6.2z"
        fill="currentColor"
      />
    </g>
    <path
      d="M6.9,16.3c0.1,0.2,0.3,0.3,0.4,0.3s0.1,0,0.2,0L9,16c0.1,0,0.2-0.1,0.2-0.2c0.4-0.8,0.7-1.4,0.9-1.8
      c0.1-0.3,0.3-0.6,0.4-0.9l1,0.5c0.2,0.1,0.5,0,0.6-0.2l1-1.8l1,1.5c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2l1.5-1.9l0.1-0.1
      l1.4-3.3c0.1-0.2,0-0.5-0.3-0.6c-0.2-0.1-0.5,0-0.6,0.3l-1.3,3.3l-1.1,1.3l-1.1-1.6c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0.1-0.4,0.2
      l-1.2,2.1l-1-0.5c-0.1-0.1-0.3-0.1-0.4,0C10,11.9,9.9,12,9.8,12.2c-0.2,0.5-0.4,0.9-0.5,1.4c-0.2,0.4-0.4,0.9-0.8,1.6l-1.3,0.5
      C6.9,15.8,6.8,16.1,6.9,16.3z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default GraphIcon;
