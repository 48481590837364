import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import useTrackMapsStore from 'src/stores/trackMapsStore';
import TrackMaps from './TrackMaps';

const TrackMapsResolver = () => {
  const { fetchTrackMaps: doFetchTrackMaps } = useTrackMapsStore(trackMapsState => trackMapsState);

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchTrackMaps()];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={TrackMaps} />;
};

export default TrackMapsResolver;
